import { Component } from '@angular/core';
import { TextElementComponent } from './../text-element/text-element.component';

@Component({
  selector: 'sd-email-element',
  templateUrl: './email-element.component.html',
  styleUrls: ['./email-element.component.scss']
})
export class EmailElementComponent extends TextElementComponent {



}

import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, ErrorHandler } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { ConfirmDeactivateGuard } from "./smartdesigner-app/guards/confirm-deactivate.guard";

import { NotificationService } from "./services/notification.service";
import { PageNotFoundComponent } from "./page-not-found-component/page-not-found.component";
import { FroalaComponent } from "./froala/froala.component";
import { SdErrorHandler } from "./shared/sd-error-handler";

import { SmartdesignerModule } from "./smartdesigner-app/smartdesigner.module";
import { FroalaModule } from "./froala/froala.module";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FroalaModule,
    SmartdesignerModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    NotificationService,
    ConfirmDeactivateGuard,
    { provide: ErrorHandler, useClass: SdErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { Permission } from './permission.type';

export class ExtraDataPermission implements Permission {
  permission: any;

  constructor(permission: any) {
    this.permission = permission;
  }

  /**
   * Can an action be exuted based on the permission
   *
   * @return {boolean}
   */
  public canChange(): boolean {
    return true;
  }

  /**
   * Get permission value
   *
   * @return {any} Returns the permission value
   */
  public Permission(): any {
    return this.permission;
  }

  public getExtraDataFieldName(): string {
    return this.Permission().fieldName;
  }
}

export class Configuration {

    constructor(
      public type: string,
      public id: string,
      public clientId: string,
      public secret: string,
      public scope: string,
      public userId: string,
      public returnUrl: string,
      public productCode: string,
      public sessionId: string,
      public logo: string,
      public theme: string = 'sd-theme'
    ) {
    }

    /**
     * Is current session an implementation of a tempale
     * a.k.a are we making a new product.
     *
     * @return {boolean}
     */
    public isTemplate(): boolean{
      if (this.type == 'template') {
        return true;
      }

      return false;
    }
}

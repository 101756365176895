import { HtmlElement } from './../../html-element.type';

export class ContentAdapter
{
    /**
     * Adapter that converts Himalaya<http://andrejewski.github.io/himalaya> transformed Json to
     * Content Json that SmartDesigner API expects.
     *
     * @param {any} data Data from Himalaya
     * @param {number} formScale Optional. If form scale used, all font sizes are scaled down with the value.
     * @return {any} Returns content JSON in SD format
     */
    public static convert(data: any, formScale?: number): any {
      let root:HtmlElement = <any>{};
      root.tag = 'html';
      root.type = 'element';
      root.children = [];
      let body:HtmlElement = <any>{};
      body.tag = 'body';
      body.type = 'element';
      body.children = [];
      root.children.push(body);
      for (let index in data) {
        let row = data[index];
        let childRow = this.parseRow(row, body, formScale);
      }

      return root;
    }

    /**
     * Parse data row to content data
     *
     * @param {any} row Data row
     * @param {any} parent Data row parent
     * @param {number} formScale Optional. If form scale used, all font sizes are scaled down with the value.
     * @return {any} Returns content data
     */
    private static parseRow(row: any, parent?: HtmlElement, formScale?: number): any {
      if (row.type !== 'element' && row.type !== 'text') {
        return undefined;
      }

      let rowData:HtmlElement = <any>{};
      rowData.tag = row.tagName;
      rowData.type = row.type;
      if (row.type === 'text') {
        rowData.html = row.content;
      }
      rowData.style = this.parseStyles(row, formScale);

      rowData.children = [];
      for (let childIndex in row.children) {
        let child = row.children[childIndex];
        let childData = this.parseRow(child, rowData, formScale);
      }

      if (parent !== undefined && rowData !== undefined && Object.keys(rowData).length > 0) {
        parent.children.push(rowData);
      }

      return rowData;
    }

    /**
     * Parse element styles from row attributes
     *
     * @param {any} row Data row
     * @param {number} formScale Optional. If form scale used, all font sizes are scaled down with the value.
     * @return {any} Returns the styles
     */
    private static parseStyles(row: any, formScale?: number) {
      let styles = {};
      if (! row.attributes || row.attributes.length === 0) {
        return styles;
      }
      for (let attribute of row.attributes) {
        if (attribute.key !== 'style') {
          continue;
        }
        let values = attribute.value.split(';');
        for (let property of values) {
          let propertyValues = property.split(':');
          if (propertyValues.length == 2) {
            let key = propertyValues[0].trim().replace(/(-.)/g,function(x){return x[1].toUpperCase()});
            styles[key] = propertyValues[1].trim();
            if (formScale && ['fontSize', 'letterSpacing'].includes(key)) {
              styles[key] = (parseFloat(styles[key]) / formScale) + 'px';
            }
          }
        }
      }

      return styles;
    }
}

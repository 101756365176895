import { Injectable, EventEmitter } from '@angular/core';
import { ProductLayerInterface } from './../models/product/product-layer.type';

@Injectable()
export class LayerService {
  layerSelected$: EventEmitter<ProductLayerInterface>;
  private selectedLayer: ProductLayerInterface;
  private currentEvent: any;

  constructor() {
      this.layerSelected$ = new EventEmitter();
  }

  getSelectedLayer(): ProductLayerInterface {
      return this.selectedLayer;
  }

  getCurrentEvent(): any {
      return this.currentEvent;
  }

  select(event: any, layer: ProductLayerInterface): void {
      this.currentEvent = event;
      this.layerSelected$.emit(layer);
      this.selectedLayer = layer;
  }

  /**
   * Resolves the layer elements offset from top in pixels
   *
   * @return {number}
   */
  getLayersPageTop(zElement:HTMLElement)
  {
      let topOffset  = zElement.offsetTop;
      while (zElement = <HTMLElement>zElement.offsetParent)
      {
          topOffset  += zElement.offsetTop;
      }

      return topOffset;
  }
}

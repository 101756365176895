import { Template } from './../template/template';
import { ProductLayoutInterface } from './product-layout.type';
import { ProductInterface } from './product.type';
import { ProductOut } from './product-out.type';
import { Permittable } from './../common/permittable.type';
import { Formable } from './../common/formable.type';
import { FormableType } from './../common/formable-type';
import { Validatable } from './../common/validatable.type';
import { Abstract } from './abstract';

export class Product extends Abstract implements ProductInterface {
    selectedPage: number = 1;

    constructor(
      public template: Template,
      public id: string,
      public key: string,
      public clientId: string,
      public layouts: Array<ProductLayoutInterface> = [],
      public creationDate?: number,
      public modificationDate?: number,
      public published: boolean = false
    ) {
      super(template, key);
    }

    /**
     * Set product layouts
     *
     * @param {Array<ProductLayoutInterface>} layouts Layouts in an array
     * @return void
     */
    setLayouts(layouts: Array<ProductLayoutInterface>): void {
        this.layouts = layouts;
    }

    /**
     * Set product layouts
     *
     * @param {ProductLayoutInterface} layout Layout
     * @return void
     */
    setLayout(layout: ProductLayoutInterface): void {
        this.layouts.push(layout);
    }

    /**
     * Get product layouts
     *
     * @return {Array<ProductLayoutInterface>} Returns product layouts
     */
    getLayouts(): Array<ProductLayoutInterface> {
        return (this.layouts) ? this.layouts : [];
    }

    /**
     * Returns children of the current validatable
     *
     * @return {Array<Validatable>}
     */
    public Children(): Array<Validatable> {
      return this.getLayouts();
    }

    /**
     * Returns the plan (template counter-part) of this implementation
     *
     * @return {Permittable}
     */
    public Plan(): Permittable {
      return this.template;
    }

    /**
     * Get product template bleed width
     *
     * @return {number} Returns the bleed width
     */
    Bleed(): number {
        return this.template.Bleed();
    }

    /**
     * Get product template mediabox width (one side)
     *
     * @return {number} Returns the mediabox width
     */
    Media(): number {
        return this.template.Media();
    }

    /**
     * Does the product have any errors in its content
     *
     * @return {boolean}
     */
    public hasErrors(): boolean {
      let errors: boolean = false;
      for (let layout of this.getLayouts()) {
        errors = layout.hasErrors(true);
        if (errors) {
          return errors;
        }
      }

      return errors;
    }

    /**
     * Does the product have any warnings in its content
     *
     * @return {boolean}
     */
    public hasWarnings(): boolean {
      let warnings: boolean = false;
      for (let layout of this.getLayouts()) {
        warnings = layout.hasWarnings(true);
        if (warnings) {
          return warnings;
        }
      }

      return warnings;
    }

    /**
     * Find a formable from product structure by its type and key
     *
     * @param {string} key Formable key
     * @param {FormableType} type Formable type
     * @return {Formable} Return the fetched formable
     */
    findFormableByKey(key: string, type: FormableType): Formable {
        for (let layout of this.getLayouts()) {
          if (type === FormableType.layout) {
            if (layout.key == key) {
              return layout;
            }
          } else {
            let item = layout.findFormableByKey(key, type);
            if (item !== undefined) {
              return item;
            }
          }
        }

        return undefined;
    }

    /**
     * Get the selected layout
     *
     * @return {ProductLayoutInterface} Layout that is selected
     */
    getSelectedLayout(): ProductLayoutInterface {
        let selectedLayout = this.layouts[this.selectedPage - 1];

        return selectedLayout;
    }

    /**
     * Set layout as the selected layout
     *
     * @param {ProductLayoutInterface} layout Layout that is selected
     * @return void
     */
    setSelectedLayout(layout: ProductLayoutInterface): void {
        let pageNumber = 1;
        for (let l of this.layouts) {
            if (l === layout) {
                this.selectedPage = pageNumber;
                return;
            }
            pageNumber++;
        }
    }

    /**
     * Get product data.
     * This data is used for saving this object to the API.
     *
     * @return {ProductOut} Returns the product data for the API
     */
    getData(): ProductOut {
      let data:ProductOut = <any>{};
      data.id = this.id;
      data.key = this.key;
      data.template = this.template.id;
      data.client = this.clientId;
      data.modificationDate = this.modificationDate;
      data.published = this.published;
      if (this.layouts.length > 0) {
        data.layouts = [];
        for (let layout of this.layouts) {
          let layoutData = layout.getData();
          data.layouts.push(layoutData);
        }
      }

      return data;
    }

    /**
     * Get product data in JSON.
     * This data is used for saving this object to the API.
     *
     * @return {string} Returns the product data for the API in JSON
     */
    getJson(): string {
      return JSON.stringify(this.getData());
    }

    /**
     * Get element human readable name
     *
     * @return {string}
     */
    ElementName(): string {
      return this.key;
    }
}

import { Component, Input, Output, ElementRef, EventEmitter, NgZone, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ProductService } from './../../../services/product.service';
import { ImageService } from './../../../services/image.service';
import { ConfigurationService } from './../../../services/configuration.service';
import { NotificationService } from './../../../../services/notification.service';
import { environment } from './../../../../../environments/environment';
import { StyleObject } from './../../../models/common/style-object.type';
import { ProductLayout } from './../../../models/product/product-layout';
import { ProductLayoutBuilder } from './../../../models/product/builder/product-layout-builder';
import { ClientApiPermission } from './../../../models/common/client-api-permission';
import { Image } from './../../../models/common/image.type';
import { ValidatorMessage } from './../../../models/common/validator/validator.type';
import { ClientImage } from './../../../models/common/client-image.type';
import { Unit } from './../../../../shared/unit';

@Component({
  selector: 'sd-background-form',
  templateUrl: './background-form.component.html',
  styleUrls: ['./background-form.component.scss']
})
export class BackgroundFormComponent {
  @Input() layout: ProductLayout;
  @Input() disabled: boolean = false;
  @Input() focus: boolean = false;

  @Output() content: EventEmitter<Image> = new EventEmitter<Image>();
  @Output() changed: EventEmitter<ProductLayout> = new EventEmitter<ProductLayout>();
  @Output() onFocus: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private elRef: ElementRef,
    private zone: NgZone,
    private productService: ProductService,
    private imageService: ImageService,
    private notificationService: NotificationService,
    private configurationService: ConfigurationService,
    private changeDetectionRef: ChangeDetectorRef
  ) {}

  /**
   * Returns parameters that should be passed with the image upload POST request
   *
   * @return {any} Returns parameters for POST request
   */
  UploadParameters(): any {
    return {
      'template': this.productService.getTemplate().id,
      'resource': (this.layout.id) ? this.layout.id : '',
      'default': (this.layout.layout) ? this.layout.layout.id : '',
      'width': this.layout.width,
      'height': this.layout.height,
    };
  }

  /**
   * Returns parameters that should be passed with the image upload POST request
   *
   * @return {any} Returns parameters for POST request
   */
  ClientAPIParameters(): any {
    return { 'resource': this.layout.layout.id };
  }

  /**
   * On successful upload -handler
   * Updates the layout background image
   *
   * @param {any} event Data from file upload
   * @return {void}
   */
  onSuccessItem(event: any) {
    let layoutBuilder = new ProductLayoutBuilder();
    event.layout = this.layout.layout;
    layoutBuilder.populate(event);
    let layout = layoutBuilder.build();
    if (layout instanceof ProductLayout) {
      this.updateImage(layout.backgroundImage);
    }
  }

  /**
   * On failed upload -handler
   * Pushes the error to the notification service
   *
   * @param {any} event Error data
   * @return {void}
   */
  onErrorItem(event: any) {
    let errorObj = {
      'title': event.message,
      'code': event.code,
      'messages': [],
    }
    for (let exception of event.errors) {
      errorObj.messages.push({'code': exception.code, 'title': exception.field, 'message': exception.message});
    }
    this.notificationService.error(errorObj);
  }

  /**
   * On focus change handler
   *
   * @param {boolean} event Focus change event data
   */
  onFocusChange(event: boolean) {
    this.focus = event;

    this.onFocus.emit(this.focus);
  }

  /**
   * Returns the upload URI for an element file
   *
   * @return {string} Returns the upload URI
   */
  public UploadUri(): string {
    return this.imageService.getUploadUri(this.getUpdateImageId(), this.configurationService.getConfiguration());
  }

  /**
   * Returns the update/upload Id for the image
   *
   * @return {number}
   */
  getUpdateImageId(): number {
    let updateImageId = undefined;
    // If product image is the same as the template layout's image, create a new image instead of updating the default image
    if (this.layout.backgroundImage !== undefined && this.layout.backgroundImage.id !== undefined) {
        updateImageId = (this.layout.layout && this.layout.backgroundImage.id === this.layout.layout.backgroundImage.id) ? undefined : this.layout.backgroundImage.id;
        updateImageId = (updateImageId == null) ? undefined : updateImageId;
    }

    return updateImageId;
  }

  /**
   * Is client service API image selectiong shown
   *
   * @return {boolean} Returns true if upload from client API is allowed
   */
  showClientServiceSelection(): boolean {
    let permission: ClientApiPermission = this.getClientApiPermission();

    return permission.canChange();
  }

  /**
   * Is client service API image selectiong shown
   *
   * @return {boolean} Returns true if upload from client API is allowed
   */
  onlyClientServiceSelection(): boolean {
    if (! this.showClientServiceSelection()) {
      return false;
    }

    let permission: ClientApiPermission = this.getClientApiPermission();

    return permission.onlyApi();
  }

  /**
   * Clear image handler
   *
   * @param {Event} event
   */
  onClearImage(event: Event) {
    this.layout.clearBackgroundImage();
    // Emit changed Event
    this.changed.emit(this.layout);
  }

  /**
   * Scale handler that is called on scale change
   *
   * @param {any} event Slider event
   */
  onScaleChange(event: any) {
    this.layout.scaledX = event.value;
    // Setting scale on Y-axis to the same as X-axis does not work as expected.
    // Todo: calculate correct scale value for Y-axis
    this.layout.scaledY = event.value;
    // Emit changed Event
    this.changed.emit(this.layout);
  }

  /**
   * On backround image position change handler
   *
   * @param {any} event Position event params
   * @return {void}
   */
  onPositionChange(event:any) {
      this.layout.xPos = event.x;
      this.layout.yPos = event.y;
      // Emit changed Event
      this.changed.emit(this.layout);
  }

  /**
   * Handler that is envoked on client image selection.
   *
   * @param {ClientImage} event Selected client image
   * @return {void}
   */
  onClientImageSelection(event:ClientImage) {
    let uploadParams = this.UploadParameters();
    this.imageService.makeClientImageSelection(
      this.getUpdateImageId(),
      event,
      uploadParams.resource,
      uploadParams.template,
      uploadParams.default,
      uploadParams.width,
      uploadParams.height,
      this.configurationService.getConfiguration()
    ).subscribe(
      response => {
        // refresh the element
        this.onSuccessItem(response);
      }
    );
  }

  /**
   * Update background image content
   *
   * @param {ProductImageElement} image Background image
   */
  updateImage(image: Image) {
      this.zone.run(()=> {
        this.content.emit(image);
      });
      // For view, that there are changes
      this.changeDetectionRef.detectChanges();
  }

  /**
   * Can user modify the background image
   *
   * @return {boolean}
   */
  isEditable(): boolean {
    return this.layout.can('backgroundImage');
  }

  /**
   * Can user resize the background image
   *
   * @return {boolean}
   */
  canScale(): boolean {
    return this.layout.canScale();
  }

  /**
   * Can user reposition the background image
   *
   * @return {boolean}
   */
  canReposition(): boolean {
    return this.layout.canReposition();
  }

  /**
   * Returns the maximum scale percentage for the background image
   *
   * @return {boolean}
   */
  getMaxScale(): number | undefined {
    let scaleMax = undefined;
    if (! this.layout.backgroundImage) {
      return scaleMax;
    }

    let lowestDPI = 72;
    let resolution = this.layout.OriginalDpi();
    scaleMax = ((resolution / lowestDPI) < 2) ? 2 : (resolution / lowestDPI);

    return scaleMax;
  }

  /**
   * Returns the minimum scale percentage for the image
   *
   * @return {boolean}
   */
  getMinScale(): number {
    return 0.01;
  }

  /**
   * Get element errors
   *
   * @return {Array<ValidatorMessage>}
   */
  getErrors(): Array<ValidatorMessage> {
    return this.layout.getErrors();
  }

  /**
   * Get element warnings
   *
   * @return {Array<ValidatorMessage>}
   */
  getWarnings(): Array<any> {
    return this.layout.getWarnings();
  }

  /**
   * Returns layout page bleed width
   *
   * @return {number} Bleed width
   */
  Bleed(): number {
      return this.productService.getProduct().Bleed() || 0;
  }

  /**
   * Returns the client api permission object if is set
   *
   * return {ClientApiPermission}
   */
  protected getClientApiPermission(): ClientApiPermission
  {
    let permission: ClientApiPermission = (this.layout.layout) ? <ClientApiPermission>this.layout.layout.Permission('clientApi') : new ClientApiPermission({});

    return permission;
  }
}

import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {ProductService} from '../../../services/product.service';
import {environment} from '../../../../../environments/environment';
import {ProductImageElement} from '../../../models/product/element/product-image-element';
import {Image} from '../../../models/common/image.type';
import {ConfigurationService} from '../../../services/configuration.service';
import {ApiService} from '../../../services/api.service';
import {ProductElementBuilder} from '../../../models/product/builder/product-element-builder';

declare var $: any;
declare var himalaya: any;

@Component({
  selector: 'sd-qrcode-form',
  templateUrl: './qrcode-form.component.html',
  styleUrls: ['./qrcode-form.component.scss']
})
export class QrcodeFormComponent implements AfterContentInit, OnChanges {
  @Input() element: ProductImageElement;
  @Input() disabled = false;
  @Input() focus = false;
  private editor: any;
  private rawContent: string;

  @Output() content: EventEmitter<Image> = new EventEmitter<Image>();
  @Output() onFocus: EventEmitter<boolean> = new EventEmitter<boolean>();

  public options: Object;
  private pendingQrcodeContentChanges = false;
  private qrCodeGenerationInProgress = false;

  constructor(
    private elRef: ElementRef,
    private zone: NgZone,
    private productService: ProductService,
    private configurationService: ConfigurationService,
    private apiService: ApiService,
  ) {
  }

  ngAfterContentInit() {
    this.options = {
      key: environment.froalaLicence,
      theme: 'froala_smart',
      toolbarInline: true,
      toolbarButtons: [],
      multiLine: false,
      pastePlain: true,
      placeholderText: '',
      typingTimer: 1500,
      language: this.productService.getLanguage(),
      pluginsEnabled: [],
      events: {
        // "blur" means lost focus
        'froalaEditor.contentChanged': (e, editor) => {
          const content = editor.html.get();
          if (content !== this.rawContent) {
            this.updateContent(content);
          }
        },
        'froalaEditor.focus': (e, editor) => {
          if (!this.focus) {
            this.focus = true;
            this.onFocus.emit(true);
          }
        }
      }
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.focus) {
      this.focus = changes.focus.currentValue;
      if (!changes.focus.currentValue || !this.editor) {
        return;
      }
      this.editor('events.focus', true);
    }

    if (!changes.disabled) {
      return;
    }

    this.toggleEditorDisabled(changes.disabled.currentValue);
  }

  /**
   * Initialize Froala WYSIWYG editor
   *
   * @param {any} e Initialization event data
   * @return {void}
   */
  initializeFroala(e: any) {
    this.zone.runOutsideAngular(() => {
      e.initialize();
      this.editor = e.getEditor();
      const content = this.getContent();
      e.getEditor()('html.set', content);
      this.rawContent = e.getEditor()('html.get');
      if (this.disabled) {
        this.toggleEditorDisabled(this.disabled);
      } else if (this.focus) {
        this.editor('events.focus');
      }
    });
  }

  /**
   * Focus on editor event handler
   *
   * @param {any} event Click event data
   * @return {void}
   */
  focusOnEditor(event: any) {
    if (!this.editor) {
      return;
    }

    this.editor('events.focus', true);
  }

  /**
   * Toggle editor disabledness on/off
   *
   * @param {boolean} disabled Is disabled
   * @return {void}
   */
  toggleEditorDisabled(disabled: boolean) {
    if (!this.editor) {
      return;
    }
    const command = disabled ? 'edit.off' : 'edit.on';
    this.editor(command);
  }

  /**
   * Returns the element content as HTML
   *
   * @return {string} Returns element content in HTML
   */
  getContent(): string {
    return this.element.attributeInterpreter.qrcodeUrl(this.element.attributes) || '';
  }

  /**
   * Is content required?
   *
   * @return {boolean}
   */
  isRequired(): boolean {
    return this.element.isRequired();
  }

  /**
   * Get element errors
   *
   * @return {Array<any>}
   */
  getErrors(): Array<any> {
    return this.element.getErrors();
  }

  /**
   * Is there any errors that should be shown
   *
   * @return {boolean}
   */
  hasErrors(): boolean {
    return this.getErrors().length > 0;
  }

  /**
   * Get element warnings
   *
   * @return {Array<any>}
   */
  getWarnings(): Array<any> {
    return this.element.getWarnings();
  }

  /**
   * Is there any warnings that should be shown
   *
   * @return {boolean}
   */
  hasWarnings(): boolean {
    return this.getWarnings().length > 0;
  }

  /**
   * Updates QR code if QR code content was changed since last generating it
   * @param lastContent
   */
  checkUpdate(lastContent: string) {
    if (lastContent !== this.rawContent) {
      this.updateContent(this.rawContent);
    }
  }

  /**
   * Update qr element content with the new one
   *
   * @param {string} content Text element content
   * @return {void}
   */
  updateContent(content: string) {
    this.element.setError('content', 'VALIDATION.ERROR.GENERATING_QR', this.element.getLabel());
    this.pendingQrcodeContentChanges = true;
    this.rawContent = content;
    this.zone.run(() => {
      const cleanContent = himalaya.parse(content);
      try {
        const qrContent = cleanContent[0].children[0].content;
        // Don't start generating new qr code before previous one is finished
        if (this.qrCodeGenerationInProgress) {
          return;
        }
        const configuration = this.configurationService.getConfiguration();
        this.qrCodeGenerationInProgress = true;
        this.apiService.uploadQrcode(this.element.image.id, this.element.id, qrContent, configuration)
          .subscribe(
            product => {
              const element = ProductElementBuilder.build(product);
              if (element instanceof ProductImageElement) {
                this.element.attributeInterpreter.setQrcodeUrl(this.element.attributes, qrContent);
                this.content.emit(element);
                return element;
              }
            },
            undefined,
            () => {
              this.qrCodeGenerationInProgress = false;
              this.checkUpdate(content);
            }
          )

      } catch (e) {
        return;
      }
    });
  }

  /**
   * Get element help text if present
   *
   * @return {string}
   */
  getHelpText(): string {
    return this.element.getHelpText();
  }
}

import { Layout } from './../layout';
import { LayoutBuilderInterface } from './layout-builder.type';
import { Layer } from './../layer';
import { LayerBuilder } from './../builder/layer-builder';
import { Color } from './../../common/color.type';
import { Image } from './../../common/image.type';

export class LayoutBuilder implements LayoutBuilderInterface {
  public id: number;
  public key: string;
  public pageNumber: number;
  public width: number;
  public height: number;
  public renderBackgroundImage: boolean;
  public published: boolean;
  public creationDate: number;
  public modificationDate: number;
  public backgroundColor: Color;
  public backgroundImage: Image;
  public xPos: number = 0;
  public yPos: number = 0;
  public scaled: number = 1;
  public permissions: any;
  public layers: Array<Layer>;
  private attributes: any;

  constructor() {}

  /**
   * Get Id
   *
   * @return {number} Returns the layout Id
   */
  get Id() {
    return this.id;
  }

  /**
   * Set Id
   *
   * @param {number} id Layout Id
   * @return {LayoutBuilder}
   */
  setId(id: number) {
    this.id = id;
    return this;
  }

  /**
   * Get key
   *
   * @return {string} Returns the layout key
   */
  get Key() {
    return this.key;
  }

  /**
   * Set key
   *
   * @param {string} key Layout key
   * @return {LayoutBuilder}
   */
  setKey(key: string) {
    this.key = key;
    return this;
  }

  /**
   * Get page number
   *
   * @return {number} Returns the layout page number
   */
  get PageNumber() {
    return this.pageNumber;
  }

  /**
   * Set page number
   *
   * @param {number} number Layout page number
   * @return {LayoutBuilder}
   */
  setPageNumber(number: number) {
    this.pageNumber = number;
    return this;
  }

  /**
   * Get width
   *
   * @return {number} Returns the product layout width
   */
  get Width() {
    return this.width;
  }

  /**
   * Set width
   *
   * @param {number} width Product layout width
   * @return {LayoutBuilder}
   */
  setWidth(width: number) {
    this.width = width;
    return this;
  }

  /**
   * Get height
   *
   * @return {number} Returns the product layout height
   */
  get Height() {
    return this.height;
  }

  /**
   * Set height
   *
   * @param {number} height Height
   * @return {LayoutBuilder}
   */
  setHeight(height: number) {
    this.height = height;
    return this;
  }

  /**
   * Render background image
   *
   * @return {boolean} Returns renderBackgroundImage value
   */
  get RenderBackgroundImage() {
    return this.renderBackgroundImage;
  }

  /**
   * Set renderBackgroundImage value
   *
   * @param {boolean} value renderBackgroundImage value
   * @return {LayoutBuilder}
   */
  setRenderBackgroundImage(value: boolean) {
    this.renderBackgroundImage = value;
    return this;
  }

  /**
   * Is Published
   *
   * @return {boolean} Returns Published value
   */
  get Published() {
    return this.published;
  }

  /**
   * Set Published
   *
   * @param {boolean} published Published value
   * @return {LayoutBuilder}
   */
  setPublished(published: boolean) {
    this.published = published;
    return this;
  }

  /**
   * Get Creation Date
   *
   * @return {number} Returns the Creation date timestamp
   */
  get CreationDate() {
    return this.creationDate;
  }

  /**
   * Set Creation Date
   *
   * @param {number} date CreationDate
   * @return {LayoutBuilder}
   */
  setCreationDate(date: number) {
    this.creationDate = date;
    return this;
  }

  /**
   * Get modification date
   *
   * @return {number} Returns the modification date timestamp
   */
  get ModificationDate() {
    return this.modificationDate;
  }

  /**
   * Set Modification date
   *
   * @param {number} date Modification date
   * @return {LayoutBuilder}
   */
  setModificationDate(date: number) {
    this.modificationDate = date;
    return this;
  }

  /**
   * Get layers
   *
   * @return {Array<Layer>} Returns the layout layers
   */
  get Layers() {
    return this.layers;
  }

  /**
   * Set layers
   *
   * @param {Array<any>} layers Layers
   * @return {LayoutBuilder}
   */
  setLayers(layers: Array<any>) {
    let templateLayers = [];
    let builder = new LayerBuilder();
    for (let layer of layers) {
      if (layer instanceof Layer) {
        templateLayers.push(layer);
      } else {
        templateLayers.push(builder.populate(layer).build());
      }
    }
    this.layers = templateLayers;
    return this;
  }

  /**
   * Get background color
   *
   * @return {Color} Returns the layout background color
   */
  get BackgroundColor() {
    return this.backgroundColor;
  }

  /**
   * Set background color
   *
   * @param {Color} color Layout background color
   * @return {LayoutBuilder}
   */
  setBackgroundColor(color: Color) {
    this.backgroundColor = color;
    return this;
  }

  /**
   * Get background image
   *
   * @return {Image} Returns the layout background image
   */
  get BackgroundImage() {
    return this.backgroundImage;
  }

  /**
   * Set background image
   *
   * @param {Image} image Layout background image
   * @return {LayoutBuilder}
   */
  setBackgroundImage(image: Image) {
    this.backgroundImage = image;
    return this;
  }

  /**
   * Get background X-position
   *
   * @return {number} Returns the product layout background X-position
   */
  get XPos() {
    return this.xPos;
  }

  /**
   * Set background X-position
   *
   * @param {number} value The product layout background X-position
   * @return {LayoutBuilder}
   */
  setXPos(value: number) {
    this.xPos = value;

    return this;
  }

  /**
   * Get background Y-position
   *
   * @return {number} Returns the product layout background Y-position
   */
  get YPos() {
    return this.yPos;
  }

  /**
   * Set background Y-position
   *
   * @param {number} value The product layout background Y-position
   * @return {LayoutBuilder}
   */
  setYPos(value: number) {
    this.yPos = value;

    return this;
  }

  /**
   * Get background scale value
   *
   * @return {number} Returns the product layout background scale value
   */
  get Scaled() {
    return this.scaled;
  }

  /**
   * Set background scale value
   *
   * @param {number} value The product layout background scale value
   * @return {LayoutBuilder}
   */
  setScaled(value: number) {
    this.scaled = value;

    return this;
  }

  /**
   * Get Permissions
   *
   * @return {any} Returns the Permissions
   */
  get Permissions() {
    return this.permissions;
  }

  /**
   * Set Permissions
   *
   * @param {any} permissions Permissions
   * @return {LayoutBuilder}
   */
  setPermissions(permissions: any) {
    this.permissions = permissions;
    return this;
  }

  /**
   * Get attributes
   *
   * @return {any} Returns the attributes
   */
  get Attributes() {
    return this.attributes;
  }

  /**
   * Set attributes
   *
   * @param {any} attributes Attributes
   * @return {LayoutBuilder}
   */
  setAttributes(attributes: any) {
    this.attributes = attributes;

    return this;
  }

  /**
   * Build layout from class data
   *
   * @return {Layout} Returns layout object
   */
  build(): Layout {
    return new Layout(
      this.Id,
      this.Key,
      this.PageNumber,
      this.Width,
      this.Height,
      this.RenderBackgroundImage,
      this.BackgroundColor,
      this.BackgroundImage,
      this.XPos,
      this.YPos,
      this.Scaled,
      this.Permissions,
      this.Layers,
      this.Attributes
    );
  }

  /**
   * Populate class data from data input
   *
   * @param {any} data Data object
   * @return {LayoutBuilderInterface} Returns self
   */
  populate(data: any): LayoutBuilderInterface {
    this.setId(data.id);
    this.setKey(data.key);
    this.setPageNumber(data.pageNumber);
    this.setWidth(data.width);
    this.setHeight(data.height);
    this.setRenderBackgroundImage(data.renderBackgroundImage);
    this.setBackgroundColor(data.backgroundColor);
    this.setBackgroundImage(data.backgroundImage);
    this.setXPos(data.xPos);
    this.setYPos(data.yPos);
    this.setScaled(data.scaled);
    this.setPermissions(data.permissions);
    this.setLayers(data.layers);
    this.setAttributes(data.attributes);

    return this;
  }
}

import {
  Component,
  OnChanges,
  Input,
  Output,
  SimpleChanges,
  ElementRef,
  NgZone,
  AfterContentInit,
  EventEmitter
} from "@angular/core";
import { ProductTextElement } from "./../../../models/product/element/product-text-element";
import { ProductService } from "./../../../services/product.service";
import { environment } from "./../../../../../environments/environment";
import { FontSizePermission } from "./../../../models/common/font-size-permission";
import { LetterSpacingPermission } from "./../../../models/common/letter-spacing-permission";
import { FontColorPermission } from "./../../../models/common/font-color-permission";
import { HtmlElement } from "./../../../models/html-element.type";
import { ContentAdapter } from "./../../../models/product/element/content-adapter";

declare var $: any;
declare var himalaya: any;

@Component({
  selector: "sd-text-form",
  templateUrl: "./text-form.component.html",
  styleUrls: ["./text-form.component.scss"]
})
export class TextFormComponent implements AfterContentInit, OnChanges {
  @Input() element: ProductTextElement;
  @Input() disabled: boolean = false;
  @Input() focus: boolean = false;
  private editor: any;
  private rawContent: string;

  @Output() content: EventEmitter<string> = new EventEmitter<string>();
  @Output() onFocus: EventEmitter<boolean> = new EventEmitter<boolean>();

  public options: Object;

  constructor(
    private elRef: ElementRef,
    private zone: NgZone,
    private productService: ProductService
  ) {}

  ngAfterContentInit() {
    let defaultFont = this.getDefaultFontName();
    this.options = {
      key: environment.froalaLicence,
      theme: "froala_smart",
      charCounterCount: false,
      toolbarSticky: true,
      toolbarInline: true,
      toolbarButtons: this.getToolbarOptions(),
      fontFamily: this.getFontFamilies(),
      fontFamilyDefaultSelection: !defaultFont ? false : defaultFont,
      fontFamilySelection: true,
      toolbarVisibleWithoutSelection: true,
      alignSelection: true,
      fontSize: this.getFontSizes(),
      fontSizeDefaultSelection: this.getDefaultFontSize(),
      fontSizeSelection: true,
      letterSpacings: this.getLetterSpacings(),
      letterSpacingDefaultSelection: this.getDefaultLetterSpacingValue(),
      letterSpacingSelection: false,
      letterSpacingUnit: this.getLetterSpacingUnit(),
      fontScale: this.element.FormScale(),
      multiLine: this.isMultiline(),
      placeholderText: this.getPlaceHolderText(),
      language: this.productService.getLanguage(),
      sdStyle: this.getParagraphFormats(),
      styleDefaultSelection: this.getDefaultParagraphFormat(),
      colorsHEXInput: false,
      colorsText: this.getFontColors(),
      colorsBackground: this.getFontBackgroundColors(),
      lineHeights: this.getLineHeights(),
      pastePlain: true,
      pluginsEnabled: [
        "link",
        "align",
        "charCounter",
        "colors",
        "fontFamily",
        "sdFontSize",
        "letterSpacing",
        "inlineStyle",
        "lineBreaker",
        "lists",
        "sdStyle",
        "underline",
        "strikeThrough",
        "lists",
        "undo",
        "redo",
        "lineHeight"
      ],
      events: {
        "froalaEditor.contentChanged": (e, editor) => {
          let content = editor.html.get();
          content = this.setDefaultLineHeight(content);
          if (content !== this.rawContent) {
            this.updateContent(content);
          }
        },
        "froalaEditor.focus": (e, editor) => {
          if (!this.focus) {
            this.focus = true;
            this.onFocus.emit(true);
          }
        }
      }
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.focus) {
      this.focus = changes.focus.currentValue;
      if (!changes.focus.currentValue || !this.editor) {
        return;
      }
      this.editor("events.focus", true);
    }

    if (!changes.disabled) {
      return;
    }

    this.toggleEditorDisabled(changes.disabled.currentValue);
  }

  /**
   * Initialize Froala WYSIWYG editor
   *
   * @param {any} e Initialization event data
   * @return {void}
   */
  initializeFroala(e: any) {
    this.zone.runOutsideAngular(() => {
      e.initialize();
      this.editor = e.getEditor();
      let content = this.getContent();
      e.getEditor()("html.set", content);
      this.rawContent = content;
      if (this.disabled) {
        this.toggleEditorDisabled(this.disabled);
      } else if (this.focus) {
        this.editor("events.focus");
      }
    });
  }

  /**
   * Focus on editor event handler
   *
   * @param {any} event Click event data
   * @return {void}
   */
  focusOnEditor(event: any) {
    if (!this.editor) {
      return;
    }

    this.editor("events.focus", true);
  }
  /**
   * Toggle editor disabledness on/off
   *
   * @param {boolean} disabled Is disabled
   * @return {void}
   */
  toggleEditorDisabled(disabled: boolean) {
    if (!this.editor) {
      return;
    }
    let command = disabled ? "edit.off" : "edit.on";
    this.editor(command);
  }

  /**
   * Returns the default styles for this text element
   *
   * @return {any}
   */
  getDefaultStyles(): any {
    let styles = {
      "fontSize.px": this.getDefaultFontSize(true),
      fontFamily: this.getDefaultFontKey(),
      textAlign: this.getDefaultTextAlign(),
      color: this.getDefaultFontColor(),
      letterSpacing: this.getDefaultLetterSpacing(true)
    };

    return styles;
  }

  /**
   * Returns the element content as HTML
   *
   * @return {string} Returns element content in HTML
   */
  getContent(): string {
    return this.element.getContentHtml(true);
  }

  /**
   * Returns default font for the text element.
   *
   * @return {any} Returns an object with font name and value
   */
  getDefaultFont(): any {
    return this.element.getDefaultFont();
  }

  /**
   * Get default line height
   *
   * @return {number} Returns the default line height
   */
  getDefaultLineHeight() {
    return this.element.getDefaultLineHeight();
  }

  /**
   * Get available line heights
   *
   * @return {any}
   */
  getLineHeights() {
    return {
      Default: this.getDefaultLineHeight(),
      "0.1": "0.1",
      "0.5": "0.5",
      "1": "1",
      "1.2": "1.2",
      "1.5": "1.5",
      "2": "2",
      "2.5": "2.5",
      "3.0": "3.0"
    };
  }

  /**
   * Returns the element's default font name.
   *
   * @return {string} Returns the default font name
   */
  getDefaultFontName(): string {
    let font = this.element.getDefaultFont();
    if (!font) {
      return undefined;
    }

    return font.name;
  }

  /**
   * Returns the element's default font family key.
   *
   * @return {string} Returns the default font family key
   */
  getDefaultFontKey(): string {
    let font = this.element.getDefaultFont();
    if (!font) {
      return undefined;
    }

    return font.key;
  }

  /**
   * Returns the text element paragraph formats.
   *
   * @return {any} Returns an object with paragraph format key and name
   */
  getParagraphFormats(): any {
    let paragraphFormats = this.element.getParagraphFormats();
    let values = [];
    for (let format of paragraphFormats) {
      values[format.name] = {
        title: format.name,
        fontFamily: format.font.key,
        fontSize: format.fontSize,
        color: format.fontColors.rgb,
        backgroundColor: format.fontBackgroundColors.rgb,
        leading: format.leading,
        letterSpacing: format.tracking
      };
    }

    return values;
  }

  /**
   * Returns the text element default paragraph format.
   *
   * @param {boolean} tag Get tag instead of name. Default false
   * @return {string}
   */
  getDefaultParagraphFormat(tag: boolean = false): string {
    let paragraphFormats = this.getParagraphFormats();
    for (var prop in paragraphFormats) {
      return tag ? prop : paragraphFormats[prop].name;
    }

    return "Default";
  }

  /**
   * Is content required?
   *
   * @return {boolean}
   */
  isRequired(): boolean {
    return this.element.isRequired();
  }

  /**
   * Get element errors
   *
   * @return {Array<any>}
   */
  getErrors(): Array<any> {
    return this.element.getErrors();
  }

  /**
   * Is there any errors that should be shown
   *
   * @return {boolean}
   */
  hasErrors(): boolean {
    return this.getErrors().length > 0;
  }

  /**
   * Get element warnings
   *
   * @return {Array<any>}
   */
  getWarnings(): Array<any> {
    return this.element.getWarnings();
  }

  /**
   * Is there any warnings that should be shown
   *
   * @return {boolean}
   */
  hasWarnings(): boolean {
    return this.getWarnings().length > 0;
  }

  /**
   * Is the font family changable
   *
   * @return {boolean}
   */
  canChangeFontFamily(): boolean {
    return this.element.can("fontFamily");
  }

  /**
   * Is the font style changable to bold
   *
   * @return {boolean}
   */
  canChangeFontBold(): boolean {
    return this.element.can("fontBold");
  }

  /**
   * Is the font color changable
   *
   * @return {boolean}
   */
  canChangeFontColor(): boolean {
    return this.element.can("fontColor");
  }

  /**
   * Is the font background color changable
   *
   * @return {boolean}
   */
  canChangeFontBackgroundColor(): boolean {
    return this.element.can("fontBgColor");
  }

  /**
   * Is the font style changable to italic
   *
   * @return {boolean}
   */
  canChangeFontItalic(): boolean {
    return this.element.can("fontItalic");
  }

  /**
   * Is the font style changable to underlined
   *
   * @return {boolean}
   */
  canChangeFontUnderline(): boolean {
    return this.element.can("fontUnderline");
  }

  /**
   * Is the font style changable to striked
   *
   * @return {boolean}
   */
  canChangeStrike(): boolean {
    return this.element.can("strike");
  }

  /**
   * Is the font size changable
   *
   * @return {boolean}
   */
  canChangeFontSize(): boolean {
    return this.element.can("fontSize");
  }

  /**
   * Is the letter spacing changable
   *
   * @return {boolean}
   */
  canChangeLetterSpacing(): boolean {
    return this.element.can("letterSpacing");
  }

  /**
   * Is the text align changable
   *
   * @return {boolean}
   */
  canChangeAlign(): boolean {
    return this.element.can("align");
  }

  /**
   * Is the paragraph format changable
   *
   * @return {boolean}
   */
  canChangeParagraphFormat(): boolean {
    return this.element.can("paragraphFormat");
  }

  /**
   * Is lists addable
   *
   * @return {boolean}
   */
  canList(): boolean {
    return this.element.can("lists");
  }

  /**
   * Is the line height changable
   *
   * @return {boolean}
   */
  canChangeLineHeight(): boolean {
    return this.element.can("lineHeight");
  }

  /**
   * Is form multiline.
   *
   * @return {any}
   */
  isMultiline(): boolean {
    return this.element.isMultiline();
  }

  /**
   * Update text element content with the new one
   *
   * @param {string} content Text element content
   * @return {void}
   */
  updateContent(content: string) {
    this.zone.run(() => {
      let cleanContent = himalaya.parse(content);
      // Using an adapater to convert Himalaya transformed Json to SD format
      let sdContent: HtmlElement = ContentAdapter.convert(
        cleanContent,
        this.element.FormScale()
      );
      this.content.emit(JSON.stringify(sdContent));
      this.rawContent = content;
    });
  }

  /**
   * Set editor toolbar by element permissions
   *
   * @return {Array<any>} Returns the optons for the toolbar
   */
  getToolbarOptions(): Array<any> {
    let buttons = [];
    if (this.canChangeFontBold()) {
      buttons.push("bold");
    }
    if (this.canChangeFontItalic()) {
      buttons.push("italic");
    }
    if (this.canChangeFontUnderline()) {
      buttons.push("underline");
    }
    if (this.canChangeStrike()) {
      buttons.push("strikeThrough");
    }
    if (this.canChangeFontFamily()) {
      buttons.push("fontFamily");
    }
    if (this.canChangeFontSize()) {
      buttons.push("sdFontSize");
    }
    if (this.canChangeFontColor() || this.canChangeFontBackgroundColor()) {
      buttons.push("color");
    }
    if (this.canChangeAlign()) {
      buttons.push("align");
    }
    if (this.canList()) {
      buttons.push("formatOL");
      buttons.push("formatUL");
    }
    if (this.canChangeParagraphFormat()) {
      buttons.push("sdStyle");
    }
    if (this.canChangeLineHeight()) {
      buttons.push("lineHeight");
    }
    if (this.canChangeLetterSpacing()) {
      buttons.push("letterSpacing");
    }

    buttons.push("undo");
    buttons.push("redo");

    return buttons;
  }

  /**
   * Get font families allowed for the element
   *
   * @return {any} Retunrs the font families in an object
   */
  getFontFamilies(): any {
    let fontFamilies = {};
    for (let font of this.element.getFonts()) {
      fontFamilies[font.key] = font.name;
    }
    return fontFamilies;
  }

  /**
   * Get font sizes allowed for the element
   *
   * @return {any} Returns the font sizes in an object
   */
  getFontSizes(): any {
    let permission = <FontSizePermission>this.element.Permission("fontSize");
    if (!permission.canChange()) {
      return [this.element.getDefaultFontSize()];
    }
    let min = permission.getMin();
    let max = permission.getMax();
    let fontSizes = [];
    let i = min;
    while (i <= max) {
      fontSizes.push(i);
      i++;
    }

    return fontSizes;
  }

  /**
   * Get letter spacings allowed for the element
   *
   * @return {any} Returns the letter spacings in an object
   */
  getLetterSpacings(): any {
    let permission = <LetterSpacingPermission>(
      this.element.Permission("letterSpacing")
    );
    if (!permission.canChange()) {
      return [];
    }
    let min = permission.getMin();
    let max = permission.getMax();
    let values = [];
    let i = min;
    let scale =
      this.getLetterSpacingUnit() === "em" ? (max - min > 50 ? 10 : 5) : 1;
    while (i <= max) {
      values.push(i);
      i = i + scale;
    }

    return values;
  }

  /**
   * Return the default font size for the content
   *
   * @param {boolean} useScaled Optional. Is default font size scaled with the font scale value. Default false
   * @return {string}
   */
  getDefaultFontSize(useScaled: boolean = false): number {
    return useScaled
      ? this.element.getDefaultFontSize() * this.element.FormScale()
      : this.element.getDefaultFontSize();
  }

  /**
   * Return the default letter spacing for the content
   *
   * @param {boolean} useScaled Optional. Is default letter spacing scaled with the font scale value. Default false
   * @return {number}
   */
  getDefaultLetterSpacingValue(useScaled: boolean = false): number {
    return useScaled
      ? this.element.letterSpacing() * this.element.FormScale()
      : this.element.letterSpacing();
  }

  /**
   * Return the letter spacing unit for the content
   *
   * @return {string}
   */
  getLetterSpacingUnit(): string {
    return this.element.letterSpacingUnit();
  }

  /**
   * Returns the used default letter spacing
   *
   * @param {boolean} useScaled Optional. Is default letter spacing scaled with the font scale value. Default false
   * @return {string} Return default letter spacing
   */
  getDefaultLetterSpacing(useScaled: boolean = false): string {
    let value = this.getDefaultLetterSpacingValue(useScaled);
    let unit = this.getLetterSpacingUnit();
    if (unit === "em") {
      value = value / 1000;
    }

    return value + unit;
  }

  /**
   * Return the default text align value for the content
   *
   * @return {string}
   */
  getDefaultTextAlign(): string {
    return this.element.getDefaultAlign();
  }

  /**
   * Get allowed font colors from element permissions
   *
   * @return {Array<string>} Returns an array of RGB colors
   */
  getFontColors(): Array<string> {
    if (!this.canChangeFontColor()) {
      return [];
    }
    let permission = <FontColorPermission>this.element.Permission("fontColor");
    if (!permission) {
      return [];
    }
    let colors = [];
    for (let color of permission.getColors("rgb")) {
      colors.push(color);
    }

    return colors;
  }

  /**
   * Returns default font color for the text element.
   *
   * @return {string} Returns the default font color
   */
  getDefaultFontColor(): string {
    return this.element.getDefaultFontColor();
  }

  /**
   * Get allowed font colors from element permissions
   *
   * @return {Array<string>} Returns an array of RGB colors
   */
  getFontBackgroundColors(): Array<string> {
    if (!this.canChangeFontBackgroundColor()) {
      return [];
    }
    let permission = <FontColorPermission>(
      this.element.Permission("fontBgColor")
    );
    if (!permission) {
      return [];
    }
    let colors = [];
    for (let color of permission.getColors("rgb")) {
      colors.push(color);
    }

    return colors;
  }

  /**
   * Get element help text if present
   *
   * @return {string}
   */
  getPlaceHolderText(): string {
    return this.element.getPlaceHolder();
  }

  /**
   * Get element help text if present
   *
   * @return {string}
   */
  getHelpText(): string {
    return this.element.getHelpText();
  }

  /**
   * Strips tags from parent tag without removing the text content.
   *
   * @param {string} html Raw HTML content
   * @param {string} parentTag Tag which content is stripped
   * @return {string} Returns the stripped content
   */
  stripTagsFromTagContent(html: string, parentTag: string): string {
    let tmp = document.createElement("div");
    tmp.innerHTML = html;
    // Find each parent tag from the HTML
    $(tmp)
      .find(parentTag)
      .each(function() {
        // Replace parent tag HTML with plain content
        $(this).html($(this).text());
        $(this).removeAttr("style");
      });

    return tmp.innerHTML;
  }

  /**
   * Set default line height to P-tags if needed
   *
   * @param {string} content Element content
   * @return {string} Returns the formatted content
   */
  setDefaultLineHeight(content: string): string {
    if (this.element.leading() !== undefined) {
      let container = document.createElement("template");
      container.innerHTML = content;
      let elements: NodeListOf<ChildNode> = container.content.childNodes;
      if (!elements) {
        return content;
      }
      for (let node of elements as any) {
        if (node.nodeName === "P" && node.style.lineHeight == "") {
          node.style.lineHeight = this.element.leading();
        }
      }
      content = container.innerHTML;
    }

    return content;
  }
}

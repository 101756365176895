export class Unit {
  /**
   * Convert Pixels to millimeters
   *
   * @param {number} px Pixels
   * @param {number} dpi DPI
   * @return {number} Return in millimeters
   */
  static pxToMm(px: number, dpi: number = 96): number {
    return (px * 25.4) / dpi;
  }

  /**
   * Convert millimeters to Pixels
   *
   * @param {number} mm millimeters
   * @param {number} dpi DPI
   * @return {number} Return in Pixels
   */
  static mmToPx(mm: number, dpi: number = 96): number {
    return (mm * dpi) / 25.4;
  }
}

import { Directive, HostListener } from '@angular/core';
import { ProductService } from './../services/product.service';
import { ConfigurationService } from './../services/configuration.service';

@Directive({
  selector: '[save-product]'
})
export class SaveProductDirective {

  constructor(private productService: ProductService, private configurationService: ConfigurationService) { }

  @HostListener('click', ['$event'])
  onClick(event: any): void {
    this.productService.saveProduct(this.configurationService.getConfiguration());
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Template } from './../models/template/template';
import { Layout } from './../models/template/layout';
import { Layer } from './../models/template/layer';
import { Element } from './../models/template/element/element.type';
import { TextElement } from './../models/template/element/text-element';
import { EmailElement } from './../models/template/element/email-element';
import { HttpTemplate } from './../models/common/http/template';
import { Configuration } from './../models/configuration';
import { Product } from './../models/product/product';
import { ProductInterface } from './../models/product/product.type';
import { ProductLayer } from './../models/product/product-layer';
import { ProductLayout } from './../models/product/product-layout';
import { ProductTextElement } from './../models/product/element/product-text-element';
import { ProductEmailElement } from './../models/product/element/product-email-element';
import { Response } from '@angular/http';
import { ProductBuilder } from './../models/product/builder/product-builder';
import { TemplateBuilder } from './../models/template/builder/template-builder';

import { Observable, Subject } from 'rxjs';



@Injectable()
export class SmartdesignerService {

  template: Template;
  templateChange: Subject<Template> = new Subject<Template>();

  constructor(private apiService: ApiService) { }

  /**
   * Requests the template data from the API with predefined configurations.
   *
   * @param {Configuration} configuration Configuration class object
   * @return {void} Observable of the template data
   */
  getTemplate(configuration: Configuration): void {
    let template = this.apiService.getTemplate(configuration)
      .subscribe(resp => {
        let template = mapTemplate(resp.body);
        this.setTemplate(template);
      });
  }

  /**
   * Initialize product model from the template data.
   *
   * @param {Template} template Template class object
   * @param {Configuration} configuration Configuration class object
   * @return {ProductInterface} Returns a product model
   */
  initProduct(template: Template, configuration: Configuration): ProductInterface {
    return new ProductBuilder().implement(template);
  }

  /**
   * Set current template
   *
   * @param {Template} template Loaded template
   */
  setTemplate(template:Template) {
      this.template = template;
      this.templateChange.next(this.template);
  }
}

/**
 * Maps HttpTemplate response object to a Template object
 *
 * @param {HttpTemplate} data Data from the API
 * @return {Template} Returns a template object transformed from the API response
 */
function mapTemplate(data: HttpTemplate): Template {
  return new TemplateBuilder()
    .populate(data)
    .build();
}

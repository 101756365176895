import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Configuration } from './../../../models/configuration';
import { ProductInterface } from './../../../models/product/product.type';
import { CloseOut } from './../../../models/close-out.type';
import { ConfigurationService } from './../../../services/configuration.service';
import { ProductService } from './../../../services/product.service';
import { ApiService } from './../../../services/api.service';
import { ValidatorMessage } from './../../../models/common/validator/validator.type';
import { concat, Subscription } from 'rxjs';
import { takeWhile, toArray } from 'rxjs/operators';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { environment } from './../../../../../environments/environment';
import { ProductGroupElement } from '../../../models/product/element/product-group-element';
import { ProductElement } from '../../../models/product/element/product-element.type';
import { ProductTextElement } from '../../../models/product/element/product-text-element';
import { ExtraDataPermission } from '../../../models/common/extra-data-permissions';

declare var $: any;

@Component({
  selector: 'sd-save-n-close',
  templateUrl: './save-close.component.html',
  styleUrls: ['./save-close.component.scss']
})
export class SaveCloseComponent implements OnInit {

  public data: any;
  productSubscription: Subscription;
  configurationSubscription: Subscription;
  configuration: Configuration;
  product: ProductInterface;
  @Input() ready: boolean = true;
  @Input() hasErrors: boolean = false;
  @Input() hasWarnings: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isCancel: boolean = false;

  @Output() processing: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() preventedSave: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private dialog: MatDialog, private router: Router, private configurationService: ConfigurationService, private productService: ProductService, private apiService: ApiService) { }

  ngOnInit() {
    this.configurationSubscription = this.configurationService.configurationChange
    .pipe(takeWhile(() => ! this.configuration))
    .subscribe((configuration) => {
      this.configuration = configuration;
    });
  }

  public getData() {
    const data: CloseOut = <any>{};
    const product = this.productService.getProduct();

    if (product !== undefined && this.configuration !== undefined) {
      data.id = product.id;
      data.template = product.template.id;
      data.ready = this.ready;
      data.userId = this.configuration.userId;
      data.scope = this.configuration.scope;
      data.url = environment.apiBaseUrl + 'products/' + data.id + '/generate';
      data.preview_urls = [];

      let preview_prefix = environment.apiBaseUrl + 'products/' + data.id + '/image/';
      for (let layout in product.layouts) {
        let pageNumber = (parseInt(layout) + 1);
        let preview = {
          preview: preview_prefix + pageNumber,
          page:  pageNumber,
        };
        data.preview_urls.push(preview);
      }
    }

    data.extra_data = this.getExtraData(product);

    return JSON.stringify(data);
  }

  getExtraData(product: ProductInterface) {
    let extraData = <any>{};
    for (let layout of product.layouts) {
      for (let layer of layout.layers) {
        const flattenedElements = this.flattenElements(layer.elements);
        for (let element of flattenedElements) {
          if (element instanceof ProductTextElement) {
            const extraDataPermission = <ExtraDataPermission>element.Permission('extraData');
            const fieldName = extraDataPermission.getExtraDataFieldName();
            if (fieldName !== null && element.content !== null) {
              extraData[fieldName] = this.getTextContent(JSON.parse(element.content).children).trim();
            }
          }
        }
      }
    }
    return extraData;
}

  getTextContent(htmlStructure: any): string {
    let textContent = '';
    for (let element of htmlStructure) {
      if (element.type === 'text' && 'html' in element) {
        textContent += element.html;
      } else if ('children' in element) {
        textContent += this.getTextContent(element.children);
      }
    }
    return textContent;
  }

  /**
   * Flattens product group elements and gets all elements as flat array.
   * Result doesn't contain ProductGroupElements.
   * @param elements
   */
  flattenElements(elements: ProductElement[]): ProductElement[] {
    if (elements.length === 0) {
      return [];
    }
    let [first, ...rest] = elements;
    if (first instanceof ProductGroupElement) {
      return [...this.flattenElements(first.elements), ...this.flattenElements(rest)];
    } else {
      return [first, ...this.flattenElements(rest)];
    }
  }

  /**
   * On submit handler that send a session end request to Api
   * and submits the form data with the user to the client return url.
   *
   * @param {any} form Form
   * @param {any} e Event data
   * @return {void}
   */
  onSubmit(form:any, e: any): void {
    // Adding the data input before submitting
    e.preventDefault();
    if (this.isCancel) {
      this.cancel();
      return ;
    }
    if (this.hasErrors) {
      const dialogRef = this.dialog.open(SdDialogErrorsDialog, {
       height: '350px',
       panelClass: this.configurationService.ThemeClass()
      });
      dialogRef.afterClosed().subscribe(result => {
        this.preventedSave.next(true);
      });
    } else if (this.hasWarnings) {
      const dialogRef = this.dialog.open(SdDialogWarningsDialog, {
       height: '350px',
       panelClass: this.configurationService.ThemeClass()
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.submitForm(form);
        } else {
          this.preventedSave.next(true);
        }
      });
    } else if (this.productService.getTemplate().instruction != undefined && this.productService.getTemplate().instruction !=='') {
      const dialogRef = this.dialog.open(SdDialogInstructionDialog, {
       height: '350px',
       panelClass: this.configurationService.ThemeClass()
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.submitForm(form);
        } else {
          this.preventedSave.next(true);
        }
      });
    } else {
      this.submitForm(form);
    }
   }

   /**
    * Save product data and submit form data to client service.
    * By calling this method, the user is redirected to client service.
    *
    * @return {void}
    */
   submitForm(form: any) {
     this.preventedSave.next(false);
     this.processing.next(true);

     let product = this.productService.getProduct();
     product.published = true;
     let saveResponse = this.apiService.updateProduct(product.id, product.getJson(), this.configurationService.getConfiguration(), true);
     let endReponse = this.apiService.endSession(this.configurationService.getConfiguration());
     concat(saveResponse, endReponse)
       .pipe(toArray())
       .subscribe(responseList => {
         var input = document.createElement('input');
         input.type = 'hidden';
         input.name = 'data';
         input.value = this.getData();
         form.appendChild(input);
         form.submit();
       });
   }

   /**
    * Cancel action ends user session and returns to returnUrl
    *
    * @return {void}
    */
   cancel() {
     let endReponse = this.apiService.endSession(this.configurationService.getConfiguration());
     forkJoin([endReponse])
      .subscribe(responseList => {
        window.top.location.href = this.configurationService.getConfiguration().returnUrl;
      });
   }
}

@Component({
    selector: 'sd-errors-dialog',
    templateUrl: './sd-errors-dialog.component.html',
    styleUrls: ['./save-close.component.scss']
})
export class SdDialogErrorsDialog implements OnInit {
    messages: Array<ValidatorMessage> = [];
    constructor(private productService: ProductService) { }

    ngOnInit() {
      let product = this.productService.getProduct();
      this.messages = product.getErrors(true);
    }
}

@Component({
    selector: 'sd-warnings-dialog',
    templateUrl: './sd-warnings-dialog.component.html',
    styleUrls: ['./save-close.component.scss']
})
export class SdDialogWarningsDialog {
  messages: Array<ValidatorMessage> = [];
  constructor(private productService: ProductService) { }

  ngOnInit() {
    let product = this.productService.getProduct();
    this.messages = product.getWarnings(true);
  }
}

@Component({
    selector: 'sd-instruction-dialog',
    templateUrl: './sd-instruction-dialog.component.html',
    styleUrls: ['./save-close.component.scss']
})
export class SdDialogInstructionDialog implements OnInit {

  instruction: string = '';
  constructor(private productService: ProductService) { }

  ngOnInit() {
    if (this.productService.getTemplate().instruction !== undefined && this.productService.getTemplate().instruction !=='') {
      this.instruction = this.productService.getTemplate().instruction;
    }
  }
}

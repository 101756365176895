import { Template } from './../template';
import { TemplateBuilderInterface } from './template-builder.type';
import { Layout } from './../layout';
import { LayoutBuilder } from './../builder/layout-builder';

export class TemplateBuilder implements TemplateBuilderInterface {
  private id: string;
  private key: string;
  private name: string;
  private groupId: string;
  private description: string;
  private format: string;
  private colorSpace: string;
  private language: string;
  private published: boolean;
  private creationDate: number;
  private modificationDate: number;
  private clientId: string;
  private permissions: any;
  private layouts: Array<Layout>;
  private attributes: any;
  private availableFonts: any;
  private forms: any;
  private instruction: string;

  constructor() {}

  /**
   * Get Id
   *
   * @return {string} Returns the template Id
   */
  get Id() {
    return this.id;
  }

  /**
   * Set Id
   *
   * @param {string} id Template Id
   * @return {TemplateBuilder}
   */
  setId(id: string) {
    this.id = id;
    return this;
  }

  /**
   * Get Group Id
   *
   * @return {string} Returns the template group id
   */
  get GroupId() {
    return this.groupId;
  }

  /**
   * Set group id
   *
   * @param {string} id Template group id
   * @return {TemplateBuilder}
   */
  setGroupId(id: string) {
    this.groupId = id;
    return this;
  }

  /**
   * Get key
   *
   * @return {string} Returns the template key
   */
  get Key() {
    return this.key;
  }

  /**
   * Set key
   *
   * @param {string} key Template key
   * @return {TemplateBuilder}
   */
  setKey(key: string) {
    this.key = key;
    return this;
  }

  /**
   * Get Client Id
   *
   * @return {string} Returns the template Client Id
   */
  get ClientId() {
    return this.clientId;
  }

  /**
   * Set Client Id
   *
   * @param {string} id Template Client Id
   * @return {TemplateBuilder}
   */
  setClientId(id: string) {
    this.clientId = id;
    return this;
  }

  /**
   * Get Name
   *
   * @return {string} Returns the Name
   */
  get Name() {
    return this.name;
  }

  /**
   * Set Name
   *
   * @param {string} name Name
   * @return {TemplateBuilder}
   */
  setName(name: string) {
    this.name = name;
    return this;
  }

  /**
   * Get description
   *
   * @return {string} Returns the description
   */
  get Description() {
    return this.description;
  }

  /**
   * Set description
   *
   * @param {string} description Description
   * @return {TemplateBuilder}
   */
  setDescription(description: string) {
    this.description = description;
    return this;
  }

  /**
   * Get Format
   *
   * @return {string} Returns the Format
   */
  get Format() {
    return this.format;
  }

  /**
   * Set color space
   *
   * @param {string} colorSpace Color space
   * @return {TemplateBuilder}
   */
  setColorSpace(colorSpace: string) {
    this.colorSpace = colorSpace;
    return this;
  }

  /**
   * Get color space
   *
   * @return {string} Returns the color space
   */
  get ColorSpace() {
    return this.colorSpace;
  }

  /**
   * Set language
   *
   * @param {string} language Language short code
   * @return {TemplateBuilder}
   */
  setLanguage(language: string) {
    this.language = language;
    return this;
  }

  /**
   * Get language
   *
   * @return {string} Returns the language
   */
  get Language() {
    return this.language;
  }

  /**
   * Set Format
   *
   * @param {string} format Format
   * @return {TemplateBuilder}
   */
  setFormat(format: string) {
    this.format = format;
    return this;
  }

  /**
   * Is Published
   *
   * @return {boolean} Returns Published value
   */
  get Published() {
    return this.published;
  }

  /**
   * Set Published
   *
   * @param {boolean} published Published value
   * @return {TemplateBuilder}
   */
  setPublished(published: boolean) {
    this.published = published;
    return this;
  }

  /**
   * Get Creation Date
   *
   * @return {number} Returns the Creation date timestamp
   */
  get CreationDate() {
    return this.creationDate;
  }

  /**
   * Set Creation Date
   *
   * @param {number} date CreationDate
   * @return {TemplateBuilder}
   */
  setCreationDate(date: number) {
    this.creationDate = date;
    return this;
  }

  /**
   * Get modification date
   *
   * @return {number} Returns the modification date timestamp
   */
  get ModificationDate() {
    return this.modificationDate;
  }

  /**
   * Set Modification date
   *
   * @param {number} date Modification date
   * @return {TemplateBuilder}
   */
  setModificationDate(date: number) {
    this.modificationDate = date;
    return this;
  }

  /**
   * Get Permissions
   *
   * @return {any} Returns the Permissions
   */
  get Permissions() {
    return this.permissions;
  }

  /**
   * Set Permissions
   *
   * @param {any} permissions Permissions
   * @return {TemplateBuilder}
   */
  setPermissions(permissions: any) {
    this.permissions = permissions;
    return this;
  }

  /**
   * Get attributes
   *
   * @return {any} Returns the attributes
   */
  get Attributes() {
    return this.attributes;
  }

  /**
   * Set attributes
   *
   * @param {any} attributes Attributes
   * @return {LayoutBuilder}
   */
  setAttributes(attributes: any) {
    this.attributes = attributes;

    return this;
  }

  /**
   * Get attributes
   *
   * @return {any} Returns the attributes
   */
  get AvailableFonts() {
    return this.availableFonts;
  }

  /**
   * Set available fonts
   *
   * @param {any} fonts Available fonts
   * @return {TemplateBuilder}
   */
  setAvailableFonts(fonts: any) {
    this.availableFonts = fonts;

    return this;
  }

  /**
   * Get forms meta data
   *
   * @return {any} Returns the forms meta data
   */
  get Forms() {
    return this.forms;
  }

  /**
   * Get instruction text
   *
   * @return {string} Returns the instruction text
   */
  get Instruction() {
    return this.instruction;
  }

  /**
   * Set instruction text
   *
   * @param {string} instruction Instruction text
   * @return {TemplateBuilder}
   */
  setInstruction(instruction: string) {
    this.instruction = instruction;

    return this;
  }

  /**
   * Set forms meta data
   *
   * @param {any} forms Forms meta data
   * @return {TemplateBuilder}
   */
  setForms(forms: any) {
    forms.sort(function(a, b) {
      return ((a.order < b.order) ? -1 : (a.order > b.order ? 1 : 0));
    });
    this.forms = forms;

    return this;
  }

  /**
   * Get layouts
   *
   * @return {Array<Layout>} Returns the template layouts
   */
  get Layouts() {
    return this.layouts;
  }

  /**
   * Set layouts
   *
   * @param {Array<any>} layouts Layouts
   * @return {TemplateBuilder}
   */
  setLayouts(layouts: Array<any>) {
    let templateLayouts = [];
    let builder = new LayoutBuilder();
    for (let layout of layouts) {
      if (layout instanceof Layout) {
        templateLayouts.push(layout);
      } else {
        templateLayouts.push(builder.populate(layout).build());
      }
    }
    this.layouts = templateLayouts;
    return this;
  }

  /**
   * Build product element from class data
   *
   * @return {ProductElement} Returns Product element object
   */
  build(): Template {
    return new Template(
      this.Id,
      this.Key,
      this.Name,
      this.GroupId,
      this.Description,
      this.Format,
      this.ColorSpace,
      this.Language,
      this.Published,
      this.CreationDate,
      this.ModificationDate,
      this.ClientId,
      this.Permissions,
      this.Layouts,
      this.Attributes,
      this.AvailableFonts,
      this.Forms,
      this.Instruction
    );
  }

  /**
   * Populate class data from data input
   *
   * @param {any} data Data object
   * @return {TemplateBuilderInterface} Returns self
   */
  populate(data: any): TemplateBuilderInterface {
    this.setId(data.id);
    this.setKey(data.key);
    this.setName(data.name);
    this.setGroupId(data.groupId);
    this.setDescription(data.description);
    this.setFormat(data.format);
    this.setColorSpace(data.colorSpace);
    this.setLanguage(data.lang);
    this.setPublished(data.published);
    this.setCreationDate(data.creationDate);
    this.setModificationDate(data.modificationDate);
    this.setClientId(data.client);
    this.setPermissions(data.permissions);
    this.setLayouts(data.layouts);
    this.setAttributes(data.attributes);
    this.setAvailableFonts(data.availableFonts);
    this.setForms(data.forms);
    this.setInstruction(data.instruction);

    return this;
  }
}

import { Component, Input, Output, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ImageToolService } from './../../../services/image-tool.service';
import { Image } from './../../../models/common/image.type';
import { ClientImage } from './../../../models/common/client-image.type';
import { ValidatorMessage } from './../../../models/common/validator/validator.type';
import { Unit } from './../../../../shared/unit';

@Component({
  selector: 'sd-image-tool',
  templateUrl: './image-tool.component.html',
  styleUrls: ['./image-tool.component.scss']
})
export class ImageToolComponent implements OnDestroy, OnChanges {
  @Input() id: number;
  @Input() xPos: number = 0;
  @Input() yPos: number = 0;
  @Input() scaledX: number = 1;
  @Input() scaledY: number = 1;
  @Input() scaleMax: number = 2;
  @Input() scaleMin: number = 0.01;
  @Input() width: number = 0;
  @Input() height: number = 0;
  @Input() widthMax: number = 0;
  @Input() heightMax: number = 0;
  @Input() widthMin: number = 0;
  @Input() heightMin: number = 0;
  @Input() fitArea: boolean = false;
  @Input() image: Image;
  @Input() imageSrc: string;
  @Input() imageLabel: string;
  @Input() resizable: boolean = false;
  @Input() scalable: boolean = false;
  @Input() movable: boolean = false;
  @Input() showClientImageSelection: boolean = false;
  @Input() onlyClientImageSelection: boolean = false;
  @Input() errors: Array<ValidatorMessage> = [];
  @Input() warnings: Array<ValidatorMessage> = [];
  @Input() help: string;
  @Input() disabled: boolean = false;
  @Input() editMode: boolean = false;
  @Input() bleed: number = 0;

  @Input() uploadUri: string;
  @Input() parameters: any;
  @Input() clientParameters: any;

  @Output() position: EventEmitter<any> = new EventEmitter<any>();
  @Output() scale: EventEmitter<any> = new EventEmitter<any>();
  @Output() clear: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadError: EventEmitter<any> = new EventEmitter<any>();
  @Output() clientImageSelection: EventEmitter<ClientImage> = new EventEmitter<ClientImage>();
  @Output() onFocus: EventEmitter<boolean> = new EventEmitter<boolean>();

  private imageLoading: boolean = false;
  private clientImagesLoading: boolean = false;
  private loadTimer: any;
  private imageChanged: boolean = false;
  private initialized: boolean = false;

  public options: Object;
  // Subscriptions
  formOpenSubscription: Subscription;
  positionSubscription: Subscription;
  scaleSubscription: Subscription;
  clearSubscription: Subscription;
  uploadSuccessSubscription: Subscription;
  uploadErrorSubscription: Subscription;
  clientImageSelectionSubscription: Subscription;

  constructor(
    private imageToolService: ImageToolService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  /**
   * Unsubscribe all listeners before destroyal
   */
  ngOnDestroy() {
    this.unsubscribeAll();
  }

  /**
   * On changes lifecycle handler
   *
   * @param {SimpleChanges} changes Change event data
   * @return {void}
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('editMode') && changes.editMode.firstChange == false) {
      if (changes.editMode.currentValue == true) {
        this.setFormData();
        this.addSubscriptions();
        this.onFocus.emit(true);

      } else {
        this.unsubscribeAll();

        if (this.imageToolService.isOpen(this.id)) {
          this.close();
        } else {
          this.onFocus.emit(false);
        }
      }
    }

    if (! this.editMode) {
      return ;
    }

    let properties = ['image', 'imageSrc', 'disabled'];
    for (let property of properties) {
      if (changes.hasOwnProperty(property)) {
        this.imageToolService.updateData(
          this.image,
          this.imageSrc,
          this.xPos,
          this.yPos,
          this.scaledX,
          this.scaledY,
          this.scaleMax,
          this.scaleMin,
          this.width,
          this.height,
          this.widthMax,
          this.heightMax,
          this.widthMin,
          this.heightMin,
          this.errors,
          this.warnings,
          this.uploadUri,
          this.parameters,
          this.clientParameters,
          this.disabled
        );
        if (! this.initialized) {
          this.addSubscriptions();
        }

        return ;
      }
    }
  }

  /**
   * Unsubscribe all subscriptions
   */
  unsubscribeAll() {
    if (! this.initialized) {
      return ;
    }
    this.formOpenSubscription.unsubscribe();
    this.positionSubscription.unsubscribe();
    this.scaleSubscription.unsubscribe();
    this.clearSubscription.unsubscribe();
    this.uploadSuccessSubscription.unsubscribe();
    this.uploadErrorSubscription.unsubscribe();
    this.clientImageSelectionSubscription.unsubscribe();
  }

  /**
   * Add subscriptions to image tool service
   */
  addSubscriptions() {
    this.formOpenSubscription = this.imageToolService.imageFormOpen.subscribe(async(val) => await this.onClose(false));
    this.positionSubscription = this.imageToolService.position.subscribe(async(val) => await this.position.emit(val));
    this.scaleSubscription = this.imageToolService.scale.subscribe(async(val) => await this.scale.emit(val));
    this.clearSubscription = this.imageToolService.clear.subscribe(async(val) => await this.clear.emit(val));
    this.uploadSuccessSubscription = this.imageToolService.uploadSuccess.subscribe(async(val) => await this.uploadSuccess.emit(val));
    this.uploadErrorSubscription = this.imageToolService.uploadError.subscribe(async(val) => await this.uploadError.emit(val));
    this.clientImageSelectionSubscription = this.imageToolService.clientImageSelection.subscribe(async(val) => await this.clientImageSelection.emit(val));

    this.initialized = true;
  }

  /**
   * Handler for closing events
   *
   * @param {boolean} isOpen Is image tool form open
   * @return {void}
   */
  onClose(isOpen: boolean) {
    if (! isOpen) {
      this.close(false);
    }
  }

  /**
   * Open image tool form
   *
   * @return {void}
   */
  public open() {
    if (this.editMode) {
      return ;
    }
    this.editMode = true;
    this.setFormData();
  }

  /**
   * Element optional help text
   *
   * @return {string}
   */
  HelpText(): string {
    if (! this.help) {
      return '';
    }

    return this.help;
  }

  /**
   * Close image tool form
   *
   * @param {boolean} notifyService Should image tool service be notified of the closure
   * @return {void}
   */
  public close(notifyService: boolean = true) {
    if (notifyService) {
      this.imageToolService.close();
    }

    this.editMode = false;
    this.onFocus.emit(this.editMode);
  }

  /**
   * Toggle handler for edit mode
   *
   * @param {any} event Click event data
   * @return {void}
   */
  toggleEditMode(event: any) {
    this.editMode = ! this.editMode;
    // Handle showing/closing of the tool form
    if (this.editMode) {
      this.onFocus.emit(this.editMode);
      this.setFormData();
    } else {
      this.close();
    }
  }

  /**
   * Set image to form element
   *
   * @return {any} Returns the image element styles for the HTML wrapper
   */
  setImageStyle() {
    let backgroundImage = (this.imageSrc) ? 'url(' + this.imageSrc + ')' : "url('../../../../../assets/add_photo.svg')";
    return {
      'background-image': backgroundImage
    };
  }

  /**
   * Is uploader uploading at the moment
   *
   * @return {boolean}
   */
  isUploading(): boolean {
    return this.imageLoading;
  }

  /**
   * Is there any errors that should be shown
   *
   * @return {boolean}
   */
  hasErrors(): boolean {
    return (this.errors.length > 0);
  }

  /**
   * Get all errors that should be shown
   *
   * @return {Array<any>}
   */
  getErrors(): Array<any> {
    return this.errors;
  }

  /**
   * Is there any warnings that should be shown
   *
   * @return {boolean}
   */
  hasWarnings(): boolean {
    return (this.warnings.length > 0);
  }

  /**
   * Get all warnings that should be shown
   *
   * @return {Array<any>}
   */
  getWarnings(): Array<any> {
    return this.warnings;
  }

  /**
   * Set form data to form component
   *
   * @return {void}
   */
  protected setFormData() {
    this.imageToolService.setFormElement(
      this.id,
      this.image,
      this.xPos,
      this.yPos,
      this.scaledX,
      this.scaledY,
      this.scaleMax,
      this.scaleMin,
      this.width,
      this.height,
      this.widthMax,
      this.heightMax,
      this.widthMin,
      this.heightMin,
      this.bleed,
      this.fitArea,
      this.imageSrc,
      this.imageLabel,
      this.resizable,
      this.scalable,
      this.movable,
      this.showClientImageSelection,
      this.onlyClientImageSelection,
      this.uploadUri,
      this.parameters,
      this.clientParameters,
      this.errors,
      this.warnings
    );
  }
}

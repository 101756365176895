import { Injectable, Inject } from '@angular/core';

import { ApiService } from './api.service';
import { CryptoService } from './,,/../../../services/crypto.service';
import { Configuration } from './../models/configuration';
import { HttpConfiguration } from './../models/common/http/configuration';
import { environment } from './../../../environments/environment';

import { Response } from '@angular/http';
import { Observable, Subject, EMPTY } from 'rxjs';
import { map, startWith, switchMap, takeWhile, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import {interval} from "rxjs/internal/observable/interval";

@Injectable()
export class ConfigurationService {

  private configuration: Configuration;
  configurationChange: Subject<Configuration> = new Subject<Configuration>();

  constructor(private apiService: ApiService, private cryptoService: CryptoService) { }

  /**
   * Set configuration
   *
   * @param {Configuration} configuration Configuration instance
   * @return {void}
   */
  public setConfiguration(configuration:Configuration) {
    this.configuration = configuration;
    this.configurationChange.next(this.configuration);
  }

  /**
   * Get configuration
   *
   * @return {Configuration} Returns the Configuration instance
   */
  public getConfiguration():Configuration {
    return this.configuration;
  }

  public ThemeClass(): string {
    if (! this.configuration) {
      return 'sd-theme';
    }

    return this.configuration.theme;
  }

  /**
   * Fetch configuration for this application with given hash
   *
   * @param {any} hash Hash that is used to get the application configuration from the API
   * @return {void}
   */
  public fetchConfiguration(hash: any): void {
      let alive = true;
      interval(2000)
        .pipe(
          takeWhile(() => (alive && (! this.configuration || ! this.configuration.id))),
          startWith(0),
          switchMap(() => this.apiService.getConfiguration(hash).pipe(
            catchError((error) => {
              alive = false;
              return of(error);
            })
          ))
        )
        .subscribe(resp => {
          if (alive) {
             let configuration = this.mapConfiguration(resp.body, hash);
             this.setConfiguration(configuration);
          }
        });
  }

  /**
   * Get authorization token
   *
   * @param {string} url Request URL
   * @return {string} Returns the correct token for Authrorization header
   */
  public getAuthorizationToken(url: string): string {
      let token = this.cryptoService.hmac(url, this.configuration.secret);
      let authorization = this.cryptoService.base64encode(this.configuration.clientId + ':' + token);

      return authorization;
  }

  /**
   * End current session request to API
   *
   * @return {boolean} Returns the API response
   */
  public endSession(): Observable<boolean> {
    let response = this.apiService.endSession(this.configuration)
      .pipe(map((response) => { return true; }));

    return response;
  }

  /**
   * Map HttpConfiguration response object to a configuration object
   *
   * @param {HttpConfiguration} data Data from the API
   * @param {string} sessionId Identifier of the session
   * @return {Configuration}
   */
  protected mapConfiguration(data: HttpConfiguration, sessionId: string): Configuration {
    let configuration = new Configuration(
      data.type,
      data.id,
      data.clientid,
      data.secret,
      data.scope,
      data.userid,
      data.returnurl,
      data.productidentifier,
      sessionId,
      data.logo,
      data.theme
    );

    return configuration;
  }
}

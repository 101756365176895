import { Iterator } from './../iterator';
import { Validator } from './validator.type';

export class ValidatorIterator  implements Iterator {
  private validators: Array<Validator> = [];
  private position: number = 0;

  constructor(validators: Array<Validator>) {
    this.validators = validators;
  }

  /**
   * Add a validator to the iteration
   *
   * @param {Validator} validator Validator added to the iteration
   * @return {void}
   */
  addValidator(validator: Validator) {
    this.validators.push(validator);
  }

  /**
   * Return next validator from the collection of validators
   *
   * @return {Validator} Returns the next validator
   */
  next(): Validator {
    if (this.validators[this.position]) {
      let validator = this.validators[this.position];
      this.position += 1;
      return validator;
    }

    throw new Error("Called next() without any validators left.");
  }

  /**
   * Is there a next validator in line?
   *
   * @return {boolean}
   */
  hasNext(): boolean {
    return this.position < this.validators.length;
  }
}

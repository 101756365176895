import { Iterator } from './iterator';
import { Selectable } from './selectable.type';

export class SelectableIterator  implements Iterator {
  private children: Array<Selectable> = [];
  private position: number = 0;

  constructor(children: Array<Selectable>) {
    this.children = children;
  }

  /**
   * Return next child from the collection of selectable children
   *
   * @return {Selectable} Returns the next child
   */
  next(): Selectable {
    if (this.children[this.position]) {
      let child = this.children[this.position];
      this.position += 1;
      return child;
    }

    throw new Error("Called next() without any children left.");
  }

  /**
   * Is there a next child in the line?
   *
   * @return {boolean}
   */
  hasNext(): boolean {
    return this.position < this.children.length;
  }
}

import { Directive, EventEmitter, Output, Input, ElementRef, DoCheck } from '@angular/core';
import { Unit } from './../../shared/unit';

@Directive({
  selector: '[size-detector]'
})
export class SizeDetectorDirective implements DoCheck {

  @Output() height: EventEmitter<number> = new EventEmitter<number>();
  @Output() width: EventEmitter<number> = new EventEmitter<number>();
  @Output() isOverflown: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected curHeight: number;
  protected curWidth: number;
  protected overflown: boolean = false;
  @Input() debug: boolean;
  @Input() notifyOverflow: boolean = false;
  @Input() maxHeight: number;
  @Input() maxWidth: number;

  constructor(private elRef: ElementRef) { }

  ngDoCheck() {
    let size = this.getSize();
    if (this.curHeight != size.height || this.curWidth != size.width) {
        this.curHeight = size.height;
        this.curWidth = size.width;
        // Emit changes
        this.height.emit(size.height);
        this.width.emit(size.width);
        if (this.hasOverflow(size) != this.overflown) {
            this.overflown = ! this.overflown;
            this.isOverflown.emit(this.overflown);
        }
    }
  }

  /**
   * Get HTML element size
   *
   * @return {any} Returns the size in an object
   */
  getSize(): any {
    let el = this.elRef.nativeElement;

    let height = el.clientHeight;
    let width = el.clientWidth;
    let spans = el.getElementsByTagName('span');
    if (spans.length > 0) {
      let child = spans[0];
      height = (child.clientHeight > height) ? child.clientHeight : height;
      width = (child.clientWidth > width) ? child.clientWidth : width;
    }
    // Add quater of pixel to make texts fit
    let wAddition = (width > 0) ? 0.1 : 0;
    let hAddition = (height > 0) ? 0.1 : 0;
    let heightMm = Number(Number(Unit.pxToMm(height)) + hAddition).toFixed(1);
    let widthMm = Number(Number(Unit.pxToMm(width)) + wAddition).toFixed(1);

    return { height: heightMm, width: widthMm };
  }

  /**
   * Is content of the element overflown the maximums?
   *
   * @param {any} size Size object
   * @return {boolean}
   */
  hasOverflow(size: any): boolean {
    if (! this.notifyOverflow) {
      return false;
    }
    let margin = 0.5;
    // Is height overflown
    if (this.maxHeight != undefined && this.maxHeight > 0 && (this.maxHeight + margin) < size.height) {
      return true;
    }
    // Is width overflown
    if (this.maxWidth != undefined && this.maxWidth > 0 && (this.maxWidth + margin) < size.width) {
      return true;
    }

    return false;
  }
}

import { Image } from './image.type';
import { Pictorial } from './pictorial';

export class ImageHelper
{
  /**
   * Returns the counted resolution for the image based on scaling and resolutions.
   *
   * @param {Pictorial} pictorial The picture element
   * @param {string} axis Resolution on axis [x, y]. Optional.
   * @return {number} Returns the recalculated resolution
   */
  public static calcResolution(pictorial: Pictorial, axis?: string): number {
    if (! pictorial.Image()) {
      return undefined;
    }

    let resolutionAxis = ((! axis) ? ((pictorial.Format() == 'landscape') ? 'x' : 'y') : axis);
    let scaled = pictorial.ScaleValue(resolutionAxis);
    let originalDpi = pictorial.OriginalDpi(resolutionAxis);

    return originalDpi / scaled;
  }
}

import { BaseElement } from './base-element';
import { TextElementInterface } from './text-element.type';
import { FontColorPermission } from './../../common/font-color-permission';
import { FontBackgroundColorPermission } from './../../common/font-background-color-permission';
import { LineHeightPermission } from './../../common/line-height-permission';
import { LetterSpacingPermission } from './../../common/letter-spacing-permission';
import { AttributeInterpreter } from './../../common/attribute-interpreter';

export class TextElement extends BaseElement implements TextElementInterface {

    constructor(
      public id: number,
      public key: string,
      public elementType: string,
      public required: boolean,
      public label: string,
      public width: number,
      public height: number,
      public helpText: string,
      public content: string,
      public placeHolder: string,
      public attributes: any,
      public permissions: any,
      public fonts: any,
      public defaultFontSize: number,
      public defaultAlign: string,
      public maxRows: number,
      public paragraphFormats: any,
      public formId?: string,
      public formIndex?: number,
      public formScale?: number
    ) {
        super(id, key, elementType, required, label, width, height, helpText, attributes, permissions, formId, formIndex);
    }

    /**
     * Returns all available fonts for the element
     *
     * @return {any} Return available fonts in an object
     */
    getFonts() {
      return this.fonts;
    }

    /**
     * Returns all available font colors for the element
     *
     * @return {any} Return available font colors in an object
     */
    getFontColors() {
      let fontColorPermission = this.getFontColorPermission();

      return (fontColorPermission.canChange() && fontColorPermission.Colors().length > 0) ? fontColorPermission.Colors() : {};
    }

    /**
     * Returns the default font color for the text element
     *
     * @return {string} Returns the default font color
     */
    getDefaultFontColor(): string
    {
        let colors = this.getFontColors();
        if (colors.length > 0) {
          for (let color of colors) {
              return color.rgb;
          }
        }
        return 'inherit';
    }

    /**
     * Returns the used default line height
     *
     * @return {number} Return default line-height
     */
    getDefaultLineHeight() {
      if (this.leading() !== undefined) {
        return this.leading();
      }
      let permission = this.getLineHeightPermission();

      return permission.getDefault();
    }

    /**
     * Returns leading default value from element attributes if set
     *
     * @return {number|undefined} Returns default leading value from element attributes
     */
    leading(): number|undefined {
      let interpreter = new AttributeInterpreter();

      return interpreter.leading(this.attributes);
    }

    /**
     * Returns letter spacing default value from element attributes if set
     *
     * @return {number} Returns default letter spacing value from element attributes
     */
    letterSpacing(): number {
      let interpreter = new AttributeInterpreter();

      return interpreter.letterSpacing(this.attributes);
    }

    /**
     * Returns letter spacing unit from element attributes if set
     *
     * @return {string} Returns letter spacing unit from element attributes
     */
    letterSpacingUnit(): string {
      let interpreter = new AttributeInterpreter();

      return interpreter.letterSpacingUnit(this.attributes);
    }

    /**
     * Returns the element font color permission if available
     *
     * @return {FontColorPermission}
     */
    protected getFontColorPermission(): FontColorPermission {
      return <FontColorPermission>this.Permission('fontColor');
    }

    /**
     * Returns the element font background color permission if available
     *
     * @return {FontColorPermission}
     */
    protected getFontBackgroundColorPermission(): FontBackgroundColorPermission {
      return <FontBackgroundColorPermission>this.Permission('fontBgColor');
    }

    /**
     * Returns the element line height permission if available
     *
     * @return {LineHeightPermission}
     */
    protected getLineHeightPermission(): LineHeightPermission {
      return <LineHeightPermission>this.Permission('lineHeight');
    }

    /**
     * Returns the element letter spacing permission if available
     *
     * @return {LetterSpacingPermission}
     */
    protected getLetterSpacingPermission(): LetterSpacingPermission {
      return <LetterSpacingPermission>this.Permission('letterSpacing');
    }
}

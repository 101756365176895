import { Permission } from './permission.type';

export class ResolutionPermission implements Permission
{
  permission: boolean;

  constructor(permission:any) {
    this.permission = permission;
  }

  /**
   * Can an action be exuted based on the permission
   *
   * @return {boolean}
   */
  public canChange(): boolean {
    return (this.Permission().update || false);
  }

  /**
   * Get permission value
   *
   * @return {any} Returns the permission value
   */
  public Permission(): any {
    return this.permission;
  }

  /**
   * Get resolution limit by key
   * Available keys: low, high
   *
   */
  public getByKey(key: string): number
  {
      switch (key) {
        case 'low':
          return this.getLow();
        case 'high':
            return this.getHigh();
      }

      // Not found by key, so throwing an error
      throw new Error('Resolution with "' + key + '" not found.');
  }

  /**
   * Returns the low resolution limit for an image
   * undefined = 1
   *
   * @return number
   */
  public getLow(): number {
    return (this.Permission().low || 100);
  }

  /**
   * Returns the high resolution limit for an image
   * undefined = 300
   *
   * @return number
   */
  public getHigh(): number {
    return (this.Permission().high || 300);
  }
}

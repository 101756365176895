import { BaseProductElement } from './base-product-element';
import { TextElementInterface } from './../../template/element/text-element.type';
import { ProductLayerInterface } from './../product-layer.type';

import { HtmlElement } from './../../html-element.type';
import { ProductElement } from './product-element.type';
import { ProductTextElementInterface } from './product-text-element.type';
import { ProductTextElementOut } from './product-text-element-out.type';
import { Element } from './../../template/element/element.type';
import { JsonToHtmlConverter } from './../../common/json-to-html-converter';
import { ContentAdapter } from './content-adapter';
import { OverflowValidator } from './../../common/validator/overflow-validator';
import { Validator } from './../../common/validator/validator.type';
import { AttributeInterpreter } from './../../common/attribute-interpreter';

import * as _ from "lodash";

export class ProductTextElement extends BaseProductElement implements ProductTextElementInterface {

    private contentOverflown: boolean = false;

    constructor(
      public defaultElement: TextElementInterface,
      public id: number,
      public key: string,
      public width: number,
      public height: number,
      public content: string,
      public elementType: string = 'text',
      public attributes: any
    ) {
        super(defaultElement, id, key, elementType, width, height, attributes);
    }

    /**
     * Set element content/structure in a JSON string
     *
     * @param {string} contentString Element structure in JSON string
     */
    public setContent(contentString: string, updateHtml:boolean = false) {
      this.content = contentString;
    }

    /**
     * Returns all available fonts for the element
     *
     * @return {any} Return available fonts in an object
     */
    getFonts() {
      if (! this.defaultElement) {
        return [];
      }

      return this.defaultElement.fonts;
    }

    /**
     * Returns all available paragraph formats for the element
     *
     * @return {any} Return available paragraph formats in an object
     */
    getParagraphFormats() {
      if (this.defaultElement == undefined) {
        return [];
      }

      return this.defaultElement.paragraphFormats;
    }

    /**
     * Returns the default font for the element
     *
     * @return {any} Returns a font in an object
     */
    getDefaultFont() {
      let fonts = this.getFonts();

      return fonts[0];
    }

    /**
     * Returns the default font color for the element
     *
     * @return {string} Returns a font color
     */
    getDefaultFontColor(): string {
      if (this.defaultElement !== undefined) {
        return this.defaultElement.getDefaultFontColor();
      }

      return 'inherit';
    }

    /**
     * Returns a default font size for the element content
     *
     * @return {number}
     */
    getDefaultFontSize(): number {
      let defaultFontSize = 12;
      if (this.defaultElement !== undefined) {
        return this.defaultElement.defaultFontSize;
      }

      return defaultFontSize;
    }

    /**
     * Returns a default text align value for the element content
     *
     * @return {number}
     */
    getDefaultAlign(): string {
      let defaultValue = 'left';
      if (this.defaultElement !== undefined) {
        return this.defaultElement.defaultAlign;
      }

      return defaultValue;
    }

    /**
     * Is multiple lines/rows allowed for the text element
     *
     * @return {boolean}
     */
    isMultiline(): boolean {
      if (this.defaultElement == undefined) {
        return true;
      }

      return (this.defaultElement.maxRows == undefined || this.defaultElement.maxRows > 1);
    }

    /**
     * Retrieves the font object from this.fonts by font.key
     *
     * @param {string} key Key of the font
     * @return {any} Retunrns the object from this.fonts
     * @throws {Error} Throws an error if font not found
     */
    getFontObjectByKey(key: string): any {
        for (let font of this.getFonts()) {
          if (font.key === key) {
            return font;
          }
        }
        // Not found by key, so throwing an error
        throw new Error('Font with "' + key + '" not found');
    }

    /**
     * Get font families currenlty in use on the element content
     *
     * @return {any} Retunrs the font families of the current content
     */
    getContentFontFamilies(): any {
        if (! this.content) {
          return [];
        }
        let data = JSON.parse(this.content);
        let fontFamilies = [];
        if (data.tag === 'html' && data.children.length === 1) {
          fontFamilies = this.getContentItemFontFamilies(data);
        }

        return _.uniqBy(fontFamilies, 'key');
    }

    /**
     * Returns default line height value from element permissions
     *
     * @return {number} Returns the default line height value
     */
    getDefaultLineHeight(): number {
      if (this.leading() !== undefined) {
        return this.leading();
      }
      if (this.defaultElement == undefined) {
        return 1.2;
      }

      return this.defaultElement.getDefaultLineHeight();
    }

    /**
     * Returns a placeholder for the element input
     *
     * @return {string} Returns a place holder text
     */
     getPlaceHolder(): string {
       if (this.defaultElement == undefined) {
         return '';
       }

       return (this.defaultElement.placeHolder) ? this.defaultElement.placeHolder : '';
     }

    /**
     * Update element content object from JSON
     *
     * @param {string} data HTML converted to JSON
     */
    update(data: string) {
      // Set new structure
      this.setContent(data, true);
      // Validate structure and content
      this.validate();
    }

    /**
     * Get element validators
     *
     * @return {Array<Validator>} Returns an array of validators
     */
    Validators(): Array<Validator> {
       let validators = super.Validators();
       if (this.canImplement()) {
         validators.push(new OverflowValidator(this));
       }

       return validators;
    }

    /**
     * Is text element content empty
     *
     * @return {boolean}
     */
    isContentEmpty(): boolean {
      // Empty if no content property value at all.
      if (! this.content) {
        return true;
      }
      let content = JSON.parse(this.content);
      // If content property value has no children, the content is empty
      if (! content.children[0]) {
        return true;
      }
      let body = content.children[0];
      // If content body has no children, the content is empty
      if (! body || body.children.length == 0) {
        return true;
      }

      return false;
    }

    /**
     * Returns leading default value from element attributes if set
     *
     * @return {number|undefined} Returns default leading value from element attributes
     */
    leading(): number|undefined {
      let interpreter = new AttributeInterpreter();

      return interpreter.leading(this.attributes);
    }

    /**
     * Returns letter spacing default value from element attributes if set
     *
     * @return {number} Returns default letter spacing value from element attributes
     */
    letterSpacing(): number {
      let interpreter = new AttributeInterpreter();

      return interpreter.letterSpacing(this.attributes);
    }

    /**
     * Returns letter spacing unit from element attributes if set
     *
     * @return {string} Returns letter spacing unit from element attributes
     */
    letterSpacingUnit(): string {
      let interpreter = new AttributeInterpreter();

      return interpreter.letterSpacingUnit(this.attributes);
    }

    /**
     * Is element content overflown
     *
     * @return {boolean}
     */
    public isContentOverflown(): boolean {
      return this.contentOverflown;
    }

    /**
     * Return scale value for the element in the form
     *
     * @return {number} Returns the scale value
     */
    public FormScale(): number {
      let scaleValue = 1;
      if (this.defaultElement == undefined) {
        return scaleValue;
      }

      return (! this.defaultElement.formScale) ? scaleValue : this.defaultElement.formScale;
    }

    /**
     * Toggle flag is the content overflown or not
     *
     * @param {boolean} event Event data
     * @return {void}
     */
    public toggleOverflow(event: boolean) {
      if (this.contentOverflown != event) {
        this.contentOverflown = event;
        this.validate();
      }
    }

    /**
     * Get product element data.
     * This data is used for saving this object to the API.
     *
     * @return {ProductTextElementOut} Returns the product element data for the API
     */
    getData(): ProductTextElementOut {
      let data:ProductTextElementOut = <any>{};
      data.id = this.id;
      data.width = this.width;
      data.height = this.height;
      data.attributes = this.attributes;
      data.defaultElement = (this.defaultElement !== undefined) ? this.defaultElement.id : undefined;
      data.content = this.content;
      data.elementType = this.elementType;

      return data;
    }

    /**
     * Returns the correct overflow-wrap -value for the element
     *
     * @return {string} Returns the overflow-wrap value (Normal is default)
     */
    getOverflowWrap(): string {
      let  value = 'normal';
      if (! this.canResize('horizontal') && this.canResize('vertical')) {
         value = 'break-word';
      }

      return  value;
    }

    /**
     * Returns the correct overflow-wrap -value for the element
     *
     * @return {string} Returns the overflow-wrap value (Normal is default)
     */
    getWhiteSpace(): string {
      let value = 'normal';
      if (! this.canResize('vertical') && this.canResize('horizontal')) {
        value = 'nowrap';
      }

      return value;
    }

    /**
     * Generates a HTML template for the element from the content data
     *
     * @param {boolean} useFormScale Optional. Is form scale values used in font sizes. Default false
     * @return {string} Returns the HTML
     */
    public getContentHtml(useFormScale: boolean = false): string {
        if (! this.content) {
          return '';
        }
        let formScale = (useFormScale) ? this.FormScale() : undefined;
        let html = JsonToHtmlConverter.convert(JSON.parse(this.content), this.defaultElement.getFontColors(), formScale);

        return html;
    }

    /**
     * Get font families of a content item
     *
     * @return {any} Retunrs the font families of the content item
     */
    private getContentItemFontFamilies(item: any): any {
        let fontFamilies = [];
        if (item.style && item.style.fontFamily) {
          let fontObj = this.getFontObjectByKey(item.style.fontFamily);
          fontFamilies.push({
            key: item.style.fontFamily,
            name: fontObj.name,
            src: fontObj.fontFiles
          });
        }
        if (item.children && item.children.length > 0) {
          for (let child of item.children) {
            let childFontFamilies = this.getContentItemFontFamilies(child);
            fontFamilies = fontFamilies.concat(childFontFamilies);
          }
        }

        return fontFamilies;
    }
}

import { GroupElementInterface } from './group-element.type';
import { Element } from './element.type';
import { BaseElement } from './base-element';
import { Color } from './../../common/color.type';

export class GroupElement extends BaseElement implements GroupElementInterface {

    constructor(
      public id: number,
      public key: string,
      public elementType: string,
      public label: string,
      public width: number,
      public height: number,
      public attributes: any,
      public helpText: string,
      public bgColor: Color,
      public permissions: any,
      public elements: Array<Element> = []
    ) {
        super(id, key, elementType, false, label, width, height, helpText, attributes, permissions);
    }

    /**
     * Set group elements
     *
     * @param {Array<Element>} elements Layer elements in an array
     * @return void
     */
    setElements(elements: Array<Element>): void {
        this.elements = elements;
    }

    /**
     * Can current user implement group element
     *
     * @return boolean
     */
    canImplement(): boolean {
      return this.permissions.create || false;
    }

    /**
     * Can current user update the group
     *
     * @return boolean
     */
    canUpdate(): boolean {
      return this.permissions.update || false;
    }

    /**
     * Can current user view the group
     *
     * @return boolean
     */
    canView(): boolean {
      return this.permissions.view || false;
    }

    /**
     * Can current user delete the group
     *
     * @return boolean
     */
    canDelete(): boolean {
      return this.permissions.delete || false;
    }
}

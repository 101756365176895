import { ComponentFactoryResolver, Injectable, Injector, ComponentRef, EmbeddedViewRef, ApplicationRef } from '@angular/core'
import { Subject } from 'rxjs';
import { ImageToolFormComponent } from './../components/forms/image-tool/form/image-tool-form.component';
import { ProductInterface } from './../models/product/product.type';
import { ImageFormable } from './../models/common/image-formable.type';
import { Image } from './../models/common/image.type';
import { ClientImage } from './../models/common/client-image.type';
import { ValidatorMessage } from './../models/common/validator/validator.type';

@Injectable()
export class ImageToolService {
  currentComponent: ComponentRef<ImageToolFormComponent>;

  imageFormOpen: Subject<boolean> = new Subject<boolean>();
  position: Subject<any> = new Subject<any>();
  scale: Subject<any> = new Subject<any>();
  clear: Subject<any> = new Subject<any>();
  uploadSuccess: Subject<any> = new Subject<any>();
  uploadError: Subject<any> = new Subject<any>();
  clientImageSelection: Subject<ClientImage> = new Subject<ClientImage>();

  private id: number;
  private x: number;
  private y: number;
  private xPos: number = 0;
  private yPos: number = 0;
  private scaledX: number = 1;
  private scaledY: number = 1;
  private scaleMax: number = 2;
  private scaleMin: number = 0.01;
  private width: number = 0;
  private height: number = 0;
  private widthMax: number = 0;
  private heightMax: number = 0;
  private widthMin: number = 0;
  private heightMin: number = 0;
  private bleed: number = 0;
  private fitArea: boolean = false;
  private image: Image;
  private imageSrc: string;
  private imageLabel: string;
  private resizable: boolean = false;
  private scalable: boolean = false;
  private movable: boolean = false;
  private showClientImageSelection: boolean = false;
  private onlyClientImageSelection: boolean = false;
  private errors: Array<ValidatorMessage> = [];
  private warnings: Array<ValidatorMessage> = [];
  private help: string;
  private disabled: boolean = false;

  private uploadUri: string;
  private parameters: any;
  private clientParameters: any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) { }

  /**
   * Append image tool form component to body
   *
   * @return {void}
   */
  appendComponentToBody() {
    // 1. Destroy possible other components
    this.clearComponent();

    // 2. Create a component reference from the component
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(ImageToolFormComponent)
      .create(this.injector);
    this.currentComponent = componentRef;
    // 3. Bind data to the component
    this.bindDataToComponent();

    // 4. Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(this.currentComponent.hostView);

    // 5. Get DOM element from component
    const domElem = (this.currentComponent.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // 6. Append DOM element to the smartdesigner-app container
    document.getElementsByClassName('app-container')[0].appendChild(domElem);
}

  /**
   * Set image for editing
   *
   * @param {Image} image The image element that is edited in the form
   * @param {number} x X-position of the event. Optional
   * @param {number} y Y-position of the event. Optinal
   */
  public setFormElement(
    id: number,
    image: Image,
    xPos: number,
    yPos: number,
    scaledX: number,
    scaledY: number,
    scaleMax: number,
    scaleMin: number,
    width: number,
    height: number,
    widthMax: number,
    heightMax: number,
    widthMin: number,
    heightMin: number,
    bleed: number,
    fitArea: boolean,
    imageSrc: string,
    imageLabel: string,
    resizable: boolean,
    scalable: boolean,
    movable: boolean,
    showClientImageSelection: boolean,
    onlyClientImageSelection: boolean,
    uploadUri: string,
    parameters: any,
    clientParameters: any,
    errors: Array<ValidatorMessage>,
    warnings: Array<ValidatorMessage>,
    x?:number,
    y?: number
  ) {
      this.id = id;
      this.image = image;
      this.x = x;
      this.y = y;
      this.xPos = xPos;
      this.yPos = yPos;
      this.scaledX = scaledX;
      this.scaledY = scaledY;
      this.scaleMax = scaleMax;
      this.scaleMin = scaleMin;
      this.width = width;
      this.height = height;
      this.widthMax = widthMax;
      this.heightMax = heightMax;
      this.widthMin = widthMin;
      this.heightMin = heightMin;
      this.bleed = bleed;
      this.fitArea = fitArea;
      this.imageSrc = imageSrc;
      this.imageLabel = imageLabel;
      this.resizable = resizable;
      this.scalable = scalable;
      this.movable = movable;
      this.showClientImageSelection = showClientImageSelection;
      this.onlyClientImageSelection = onlyClientImageSelection;
      this.uploadUri = uploadUri;
      this.parameters = parameters;
      this.clientParameters = clientParameters;
      this.errors = errors;
      this.warnings = warnings;

      // Add component to the body
      this.appendComponentToBody();
  }

  /**
   * Close current form by clearing data and the component
   *
   * @return {void}
   */
  public close() {
      // Clear data
      this.clearForm();
      // Clear component
      this.clearComponent();
  }

  /**
   * Clear form elements
   *
   * @return {void}
   */
  public clearForm() {
    this.id = undefined;
    this.image = undefined;
    this.x = undefined;
    this.y = undefined;
    this.xPos = undefined;
    this.yPos = undefined;
    this.scaledX = undefined;
    this.scaledY = undefined;
    this.scaleMax = undefined;
    this.scaleMin = undefined;
    this.width = undefined;
    this.height = undefined;
    this.widthMax = undefined;
    this.heightMax = undefined;
    this.widthMin = undefined;
    this.heightMin = undefined;
    this.bleed = undefined;
    this.fitArea = undefined;
    this.imageSrc = undefined;
    this.imageLabel = undefined;
    this.resizable = undefined;
    this.scalable = undefined;
    this.movable = undefined;
    this.disabled = undefined;
    this.showClientImageSelection = undefined;
    this.onlyClientImageSelection = undefined;
    this.uploadUri = undefined;
    this.parameters = undefined;
    this.clientParameters = undefined;
    this.errors = undefined;
    this.warnings = undefined;
  }

  /**
   * Update form element data if form is open
   * and there has been a change in the data in the background
   *
   * @param {Image} image The image data
   * @param {string} imageSrc Image source
   * @return {void}
   */
  public updateData(
    image: Image,
    imageSrc: string,
    xPos: number,
    yPos: number,
    scaledX: number,
    scaledY: number,
    scaleMax: number,
    scaleMin: number,
    width: number,
    height: number,
    widthMax: number,
    heightMax: number,
    widthMin: number,
    heightMin: number,
    errors: Array<ValidatorMessage>,
    warnings: Array<ValidatorMessage>,
    uploadUri: string,
    parameters: any,
    clientParameters: any,
    disabled: boolean
  ) {
    this.imageSrc = imageSrc;
    this.image = image;
    this.xPos = xPos;
    this.yPos = yPos;
    this.scaledX = scaledX;
    this.scaledY = scaledY;
    this.scaleMax = scaleMax;
    this.scaleMin = scaleMin;
    this.width = width;
    this.height = height;
    this.widthMax = widthMax;
    this.heightMax = heightMax;
    this.widthMin = widthMin;
    this.heightMin = heightMin;
    this.errors = errors;
    this.warnings = warnings;
    this.uploadUri = uploadUri;
    this.parameters = parameters;
    this.clientParameters = clientParameters;
    this.disabled = disabled;
    if (!this.currentComponent) {
	this.appendComponentToBody();
    }
    this.currentComponent.instance.imageSrc = imageSrc;
    this.currentComponent.instance.image = image;
    this.currentComponent.instance.xPos = this.xPos;
    this.currentComponent.instance.yPos = this.yPos;
    this.currentComponent.instance.scaledX = this.scaledX;
    this.currentComponent.instance.scaledY = this.scaledY;
    this.currentComponent.instance.width = this.width;
    this.currentComponent.instance.height = this.height;
    this.currentComponent.instance.heightMax = this.heightMax;
    this.currentComponent.instance.widthMax = this.widthMax;
    this.currentComponent.instance.heightMin = this.heightMin;
    this.currentComponent.instance.widthMin = this.widthMin;
    this.currentComponent.instance.errors = this.errors;
    this.currentComponent.instance.warnings = this.warnings;
    this.currentComponent.instance.uploadUri = this.uploadUri;
    this.currentComponent.instance.parameters = this.parameters;
    this.currentComponent.instance.clientParameters  = this.clientParameters;
    this.currentComponent.instance.disabled = this.disabled;
    this.currentComponent.instance.reload();
  }

  /**
   * Toggle form disabled value
   *
   * @param {boolean} disabled Form disabled value
   * @return {void}
   */
  public toggleFormDisabled(disabled: boolean) {
    if (this.currentComponent) {
      this.currentComponent.instance.disabled = disabled;
    }
  }

  /**
   * Is element with given identifier currently open in the form
   *
   * @param {number} id Element identifier
   * @return {boolean}
   */
  public isOpen(id: number)
  {
    return (id && id == this.id);
  }

  /**
   * Bind data to the current component
   *
   * @return {void}
   */
  bindDataToComponent(withSubscription: boolean = true) {
    this.currentComponent.instance.id = this.id;
    this.currentComponent.instance.xPos = this.xPos;
    this.currentComponent.instance.yPos = this.yPos;
    this.currentComponent.instance.x = this.x;
    this.currentComponent.instance.y = this.y;
    this.currentComponent.instance.scaledX = this.scaledX;
    this.currentComponent.instance.scaledY = this.scaledY;
    this.currentComponent.instance.width = this.width;
    this.currentComponent.instance.height = this.height;
    this.currentComponent.instance.heightMax = this.heightMax;
    this.currentComponent.instance.widthMax = this.widthMax;
    this.currentComponent.instance.heightMin = this.heightMin;
    this.currentComponent.instance.widthMin = this.widthMin;
    this.currentComponent.instance.bleed = this.bleed;
    this.currentComponent.instance.fitArea = this.fitArea;
    this.currentComponent.instance.image = this.image;
    this.currentComponent.instance.imageSrc = this.imageSrc;
    this.currentComponent.instance.imageLabel = this.imageLabel;
    this.currentComponent.instance.resizable = this.resizable;
    this.currentComponent.instance.scalable = this.scalable;
    this.currentComponent.instance.movable = this.movable;
    this.currentComponent.instance.showClientImageSelection = this.showClientImageSelection;
    this.currentComponent.instance.onlyClientImageSelection = this.onlyClientImageSelection;
    this.currentComponent.instance.uploadUri = this.uploadUri;
    this.currentComponent.instance.parameters = this.parameters;
    this.currentComponent.instance.clientParameters  = this.clientParameters;
    this.currentComponent.instance.errors = this.errors;
    this.currentComponent.instance.warnings = this.warnings;

    this.currentComponent.instance.close.subscribe(val => this.onClose(val));
    this.currentComponent.instance.position.subscribe(val => this.position.next(val));
    this.currentComponent.instance.scale.subscribe(val => this.scale.next(val));
    this.currentComponent.instance.clear.subscribe(val => this.clear.next(val));
    this.currentComponent.instance.uploadSuccess.subscribe(val => this.uploadSuccess.next(val));
    this.currentComponent.instance.uploadError.subscribe(val => this.uploadError.next(val));
    this.currentComponent.instance.clientImageSelection.subscribe(val => this.clientImageSelection.next(val));
  }

  /**
   * On close handler
   *
   * @param {any} data Data
   */
  onClose(data: any) {
    this.close();
    // Submit data of closure
    this.imageFormOpen.next(false);
  }

  /**
   * Clear component from DOM
   *
   * @return {void}
   */
  clearComponent() {
    if (this.currentComponent) {
      this.currentComponent.instance.close.unsubscribe();
      this.currentComponent.instance.position.unsubscribe();
      this.currentComponent.instance.scale.unsubscribe();
      this.currentComponent.instance.clear.unsubscribe();
      this.currentComponent.instance.uploadSuccess.unsubscribe();
      this.currentComponent.instance.uploadError.unsubscribe();
      this.currentComponent.instance.clientImageSelection.unsubscribe();

      this.appRef.detachView(this.currentComponent.hostView);
      this.currentComponent.destroy();
    }
    this.currentComponent = undefined;
  }
}

import { AbstractValidator } from './abstract';
import { ContentRequired } from './../content-required';

export class RequiredValidator extends AbstractValidator {

  content: ContentRequired;

  /**
   * Setting needed values in the constructor for the validator
   *
   * @param {ContentRequired} content ContentRequired implementing class
   */
  constructor(content: ContentRequired) {
    super();

    this.content = content;
  }

  /**
   * Run validation in the class
   *
   * @return {boolean} Returns boolean based on validness
   */
  run(): boolean {
    if (! this.content) {
      return true;
    }

    let unvalid: boolean = false;
    if (this.content.isRequired() && this.content.isContentEmpty()) {
      this.setError('content', 'VALIDATION.ERROR.REQUIRED', this.content.ElementName());
      return false;
    }

    return true;
  }
}

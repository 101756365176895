import { BaseProductElement } from './base-product-element';
import { ProductElement } from './product-element.type';
import { ProductGroupElementInterface } from './product-group-element.type';
import { ProductGroupElementOut } from './product-group-element-out.type';
import { Element } from './../../template/element/element.type';
import { GroupElementInterface } from './../../template/element/group-element.type';
import { Selectable } from './../../common/selectable.type';
import { SelectionType } from './../../common/selection-type';
import { ResizePermission } from './../../common/resize-permission';
import { Color } from './../../common/color.type';
import { Formable } from './../../common/formable.type';
import { FormableType } from './../../common/formable-type';
import { Validatable } from './../../common/validatable.type';
import { AttributeInterpreter } from './../../common/attribute-interpreter';

export class ProductGroupElement extends BaseProductElement implements ProductGroupElementInterface {

    public selectionType: SelectionType = SelectionType.group;

    constructor(
      public defaultElement: GroupElementInterface,
      public id: number,
      public key: string,
      public width: number,
      public height: number,
      public elementType: string = 'group',
      public attributes: any,
      public bgColor: Color,
      public elements: Array<ProductElement> = []
    ) {
        super(defaultElement, id, key, elementType, width, height, attributes);
        this.setChildrenParentTier();
    }

    /**
     * Set product group elements
     *
     * @param {Array<ProductElement>} elements Elements in an array
     * @return void
     */
    setElements(elements: Array<ProductElement>): void {
      this.elements = elements;
      this.setChildrenParentTier();
    }

    /**
     * Set product group element
     *
     * @param {ProductElement} element Element
     * @return void
     */
    setElement(element: ProductElement): void {
      this.elements.push(element);
    }

    /**
     * Get product group elements
     *
     * @return {Array<ProductElement>} Returns product group elements
     */
    getElements(): Array<ProductElement> {
      return this.elements;
    }

    /**
     * Returns children of the current validatable
     *
     * @return {Array<Validatable>}
     */
    public Children(): Array<Validatable> {
      return this.getElements();
    }

    /**
     * Find a formable from product structure by its type and key
     *
     * @param {string} key Formable key
     * @param {FormableType} type Formable type
     * @return {Formable} Return the fetched formable
     */
    findFormableByKey(key: string, type: FormableType): Formable {
        for (let element of this.getElements()) {
            if (element.elementType === 'group') {
              let item = element.findFormableByKey(key, type);
              if (item !== undefined) {
                return item;
              }
            } else if (element.key == key && element.getFormableType() == type) {
              return element;
            }
        }

        return undefined;
    }

    setChildrenParentTier() {
      this.childSelectables = this.elements;
      for (let child of this.childSelectables) {
          child.parentTier = this;
      }
    }

    /**
     * Get product element data.
     * This data is used for saving this object to the API.
     *
     * @return {ProductGroupElementOut} Returns the product element data for the API
     */
    getData(): ProductGroupElementOut {
      let data:ProductGroupElementOut = <any>{};
      data.id = this.id;
      data.width = this.width;
      data.height = this.height;
      data.attributes = this.attributes;
      data.defaultElement = (this.defaultElement !== undefined) ? this.defaultElement.id : undefined;
      data.elementType = this.elementType;
      data.backgroundColor = this.bgColor;
      if (this.elements.length > 0) {
        data.elements = [];
        for (let element of this.elements) {
          let elementData = element.getData();
          data.elements.push(elementData);
        }
      }

      return data;
    }

    /**
     * Update element content object from JSON.
     * But since group is only a container, this gets never called.
     *
     * @param {any} data HTML converted to JSON with Himalaya
     */
    update(data: any) {
      // Do nothing.
    }


    /**
     * Can current user resize the group
     *
     * @param {string} direction Optional parameter for resize direction [horizontal, vertical]
     * @return {boolean}
     */
    canResize(direction?: string): boolean {
      if (this.defaultElement === undefined) {
        return true;
      }

      let permission = <ResizePermission>this.defaultElement.Permission('resize');
      return permission.canResize(direction);
    }

    /**
     * Returns the possible maximum width for the group
     * undefined = unlimited
     *
     * @return {number|undefined}
     */
    getMaxWidth(): number | undefined {
      if (! this.canResize('horizontal')) {
        return this.width;
      }

      let permission = <ResizePermission>this.defaultElement.Permission('resize');
      return permission.getMaxWidth();
    }

    /**
     * Returns the possible maximum height for the group
     * undefined = unlimited
     *
     * @return {number|undefined}
     */
    getMaxHeight(): number | undefined {
      if (! this.canResize('vertical')) {
        return this.height;
      }

      let permission = <ResizePermission>this.defaultElement.Permission('resize');
      return permission.getMaxHeight();
    }

    /**
     * Returns the possible minimum width for the group
     * undefined = unlimited
     *
     * @return {number|undefined}
     */
    getMinWidth(): number | undefined {
      if (! this.canResize('horizontal')) {
        return this.width;
      }

      let permission = <ResizePermission>this.defaultElement.Permission('resize');
      return permission.getMinWidth();
    }

    /**
     * Returns the possible minimum height for the group
     * undefined = unlimited
     *
     * @return {number|undefined}
     */
    getMinHeight(): number | undefined {
      if (! this.canResize('vertical')) {
        return this.height;
      }

      let permission = <ResizePermission>this.defaultElement.Permission('resize');
      return permission.getMinHeight();
    }

    /**
     * Returns the unit for notch in height
     * undefined = dynamic
     *
     * @return {number|undefined}
     */
    getNotchHeight(): number | undefined {
      if (! this.canResize('vertical')) {
        return undefined;
      }

      let permission = <ResizePermission>this.defaultElement.Permission('resize');
      return permission.getNotchHeight();
    }

    /**
     * Returns the unit for notch in width
     * undefined = dynamic
     *
     * @return {number|undefined}
     */
    getNotchWidth(): number | undefined {
      if (! this.canResize('horizontal')) {
        return undefined;
      }

      let permission = <ResizePermission>this.defaultElement.Permission('resize');
      return permission.getNotchWidth();
    }

    /**
     * Parse the given value valid for height of the group
     *
     * @param {number} value Height value
     * @return {number} Returns a valid value
     */
    parseValidHeight(value:number): number {
      if (! value) {
        return this.height;
      }
      if (this.getNotchHeight() && this.getNotchHeight() > 0) {
        value = Math.ceil(value / this.getNotchHeight()) * this.getNotchHeight();
      }
      if (this.getMaxHeight() && value > this.getMaxHeight()) {
        return this.getMaxHeight();
      }
      if (this.getMinHeight() && value < this.getMinHeight()) {
        return this.getMinHeight();
      }

      return value;
    }

    /**
     * Parse the given value valid for width of the group
     *
     * @param {number} value Width value
     * @return {number} Return a valid value
     */
    parseValidWidth(value:number): number {
      if (! value) {
        return this.width;
      }
      if (this.getNotchWidth() && this.getNotchWidth() > 0) {
        value = Math.ceil(value / this.getNotchWidth()) * this.getNotchWidth();
      }
      if (this.getMaxWidth() && value > this.getMaxWidth()) {
        return this.getMaxWidth();
      }
      if (this.getMinWidth() && value < this.getMinWidth()) {
        return this.getMinWidth();
      }

      return value;
    }

    /**
     * Returns justify content value from element attributes if set
     *
     * @return {string} Returns justify content value from element attributes if set else 'initial'
     */
    justifyContent(): string {
      let interpreter = new AttributeInterpreter();

      return interpreter.justifyContent(this.attributes);
    }
}

import { Permission } from './permission.type';

export class QrcodePermission implements Permission
{
  permission: any;

  constructor(permission:any) {
    this.permission = permission;
  }

  /**
   * Can an action be exuted based on the permission
   *
   * @return {boolean}
   */
  public canChange(): boolean {
    return this.canUpdate();
  }

  public canUpdate(): boolean {
    return this.permission.update;
  }

  /**
   * Get permission value
   *
   * @return {any} Returns the permission value
   */
  public Permission(): any {
    return this.permission;
  }
}

import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { NotificationService } from './../services/notification.service';

@Injectable()
export class SdErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) {
    }

    handleError(error: any): void {
      const notificationService = this.injector.get(NotificationService);
      //this.showErrorInConsole(error);
      if ('title' in error) {
          //notificationService.error(error);
      } else {
        let errorObj = {
          'title': 'Application exception!',
          'code': '500',
          'messages': [{'title': 'Unexpected error occurred', 'message': (error.message ? error.message : error.toString())}],
        }
        //notificationService.snackError((error.message ? error.message : error.toString()));
      }
      // IMPORTANT: Rethrow the error otherwise it gets swallowed
      throw error;
    }

    private showErrorInConsole(error: any) :void {
        if (console && console.group && console.error) {
            console.group("Error Log");
            console.error(error);
            console.error(error.message);
            console.error(error.stack);
            console.groupEnd();
        }
    }
}

import { Permission } from './permission.type';

export class BgColorPermission implements Permission
{
  permission: boolean;

  constructor(permission:any) {
    this.permission = permission;
  }

  /**
   * Can an action be exuted based on the permission
   *
   * @return {boolean}
   */
  public canChange(): boolean {
    return (this.Permission().update || false)
  }

  /**
   * Get permission value
   *
   * @return {any} Returns the permission value
   */
  public Permission(): any {
    return this.permission;
  }

  /**
   * Is the background color restricted to pre-defined colors
   *
   * @return {boolean} Returns true if any selectable colors are set
   */
  public isRestricted(): boolean {
    return (this.Colors().length > 0);
  }

  /**
   * Get selectable colors for the background color. No value == any color
   *
   * @param {string} colorspace Parameter for color space [rgb, cmyk, pantone]
   * @return {any}
   */
  public getColors(colorspace: string): any {
    let colors = this.Colors();
    let values = [];
    for (let item of colors) {
      if (item[colorspace]) {
        values.push(item[colorspace]);
      }
    }

    return values;
  }

  /**
   * Returns the possible selectable Rgb colors
   * undefined = unlimited
   *
   * @return number|undefined
   */
  public getRgbColors(): number | undefined {
    return this.Permission().maxWidth;
  }

  /**
   * Returns the possible selectanle colors for the resource background
   *
   * @return {any}
   */
  public Colors(): any {
    return this.Permission().colors;
  }
}

import { Animation } from './animation';
import { Animetable } from './animetable.type';

export class Animator {

  /**
   * Set animetable to the animator
   *
   * @param {Animetable} animetable Animated element
   */
  constructor(public animetable: Animetable) {}

  public getAnimationStyles(): Object {
    if (! this.animetable.isAnimetable()) {
      return {};
    }
    let iteration = (this.animetable.isInfinite()) ? 'infinite' : 1;

    return {
      'WebkitAnimationName': Animation[this.animetable.Animation()],
      'MsAnimationName': Animation[this.animetable.Animation()],
      'MozAnimationName': Animation[this.animetable.Animation()],
      'OAnimationName': Animation[this.animetable.Animation()],
      'KhtmlAnimationName': Animation[this.animetable.Animation()],
      'animationName': Animation[this.animetable.Animation()],

      'WebkitAnimationDuration': this.animetable.Duration() + 's',
      'MsAnimationDuration': this.animetable.Duration() + 's',
      'MozAnimationDuration': this.animetable.Duration() + 's',
      'OAnimationDuration': this.animetable.Duration() + 's',
      'KhtmlAnimationDuration': this.animetable.Duration() + 's',
      'animationDuration': this.animetable.Duration() + 's',

      'WebkitAnimationDelay': this.animetable.Delay() + 's',
      'MsAnimationDelay': this.animetable.Delay() + 's',
      'MozAnimationDelay': this.animetable.Delay() + 's',
      'OAnimationDelay': this.animetable.Delay() + 's',
      'KhtmlAnimationDelay': this.animetable.Delay() + 's',
      'animationDelay': this.animetable.Delay() + 's',

      'WebkitAnimationIterationCount': iteration,
      'MsAnimationIterationCount': iteration,
      'MozAnimationIterationCount': iteration,
      'OAnimationIterationCount': iteration,
      'KhtmlAnimationIterationCount': iteration,
      'animationIterationCount': iteration,
    };
  }
}

import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { Configuration } from './../models/configuration';
import { HttpProduct } from './../models/common/http/product';
import { ProductInterface } from './../models/product/product.type';
import { Product } from './../models/product/product';
import { Template } from './../models/template/template';
import { ProductLayoutInterface } from './../models/product/product-layout.type';
import { ProductLayerInterface } from './../models/product/product-layer.type';
import { ProductElement } from './../models/product/element/product-element.type';
import { ProductBuilder } from './../models/product/builder/product-builder';
import { Response } from '@angular/http';
import { Observable, Subject, interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { Router } from '@angular/router';

@Injectable()
export class ProductService {

  private product: ProductInterface;
  private lastChange: number;
  private autosaving: boolean = false;
  private autosaveInterval: number = 10000;

  productChange: Subject<ProductInterface> = new Subject<ProductInterface>();
  unsaved: Subject<ProductInterface> = new Subject<ProductInterface>();
  saving: Subject<boolean> = new Subject<boolean>();

  constructor(private apiService: ApiService, private router: Router) {

  }

  /**
   * Set current product
   *
   * @param {ProductInterface} product The product
   */
  public setProduct(product: ProductInterface) {
    this.product = product;
    this.productChange.next(this.product);
  }

  /**
   * Return current product
   *
   * @return {ProductInterface}
   */
  public getProduct():ProductInterface {
    return this.product;
  }

  /**
   * Return current product's template
   *
   * @return {Template}
   */
  public getTemplate():Template {
    return this.product.template;
  }

  /**
   * Request product data from API
   *
   * @param {Configuration} configuration Configuration object
   * @return {void}
   */
  public requestProduct(configuration: Configuration): void {
    this.apiService.getProduct(configuration)
      .subscribe(resp => {
         let product = mapProduct(resp.body);
         this.setProduct(product);
      });
  }

  /**
   * Method used to inform product service that product data has changed.
   * Based on lastChange and last modification timestamp the service desides
   * on auto-saving the product data.
   *
   * @param {number} value Time of change
   */
  public setChangeTime(value: number): void {
    if (! this.lastChange || value > this.lastChange) {
      this.lastChange = value;
      if (! this.autosaving) {
        // Initialize autosaving
        // this.initAutosave();
      }
    }
  }

  /**
   * Sends a request to the API for creating a new product
   *
   * @param {Configuration} configuration The configuration data
   * @param {ProductInterface} product The product data
   * @param {boolean} forcePublished Adds the published flag on product no matter what
   * @return {ProductInterface} Returns the product
   */
  public saveProduct(configuration: Configuration, product?: ProductInterface, forcePublished: boolean = false) {
    this.saving.next(true);
    if (product === undefined) {
      product = this.product;
    }

    let autosaveMode = (this.autosaving === true && ! forcePublished);
    this.autosaving = false;
    if (forcePublished) {
      product.published = true;
    }
    if (product.id === undefined) {
      this.apiService.createProduct(product.getJson(), configuration).subscribe(
        product => {
          // refresh the product
          this.setProduct(mapProduct(product));
          this.saving.next(false);
        }
     );
    } else {
      this.apiService.updateProduct(product.id, product.getJson(), configuration).subscribe(
         product => {
           // refresh the product if not in autosave mode
           if (! autosaveMode) {
             this.setProduct(mapProduct(product));
           }
           this.saving.next(false);
         }
      );
    }

    return this.getProduct();
  }

  /**
   * Resolve and return the layer of the given element
   *
   * @param {ProductElement} element Product element
   * @return {ProductLayerInterface} Returns the layer of the element
   * @throws {Error} Throws an error if layer is not found
   */
  public getElementLayer(element:ProductElement): ProductLayerInterface {
    let layers = this.getVisibleLayers();
    for (let layer of layers) {
      for (let layerElement of layer.elements) {
        if (layerElement === element) {
          return layer;
        }
      }
    }

    throw new Error('An error occurred. Element has no layer.');
  }

  /**
   * Get selected layout of the current product
   *
   * @return {ProductLayoutInterface} Returns the currently selected layout
   */
  public getSelectedLayout(): ProductLayoutInterface {
    let product = this.getProduct();
    if (! product) {
      return undefined;
    }

    return this.getProduct().getSelectedLayout();
  }

  /**
   * Returns the product & template language
   *
   * @return {string} Returns the language short code
   */
  public getLanguage(): string {
    return this.getProduct().template.language;
  }

  /**
   * Get product layers of the selected layout
   *
   * @return {Array<ProductLayerInterface>} Returns all visible layers
   */
  protected getVisibleLayers(): Array<ProductLayerInterface> {
    let layout = this.getSelectedLayout();

    return layout.layers;
  }

  /**
   * Handler for the autosaving process
   *
   * @return {}
   */
  protected handleAutosave() {
    if (this.lastChange < Date.now() - this.autosaveInterval) {
      this.unsaved.next(this.product);
    }
  }

  /**
   * Initializes the autosaving process.
   * Sets a timer to check that enough time has passed
   * since the last change in the product.
   *
   * @return {void}
   */
  protected initAutosave() {
    this.autosaving = true;
    interval(10000)
      .pipe(takeWhile(() => this.autosaving))
      .subscribe(i => {
        this.handleAutosave();
      });
  }

  private extractData(response: Response) {
    let body = response.json();
    let builder = new ProductBuilder();
    let product = builder.populate(body.data).build();
    this.setProduct(product);
  }
}

/**
 * Maps HttpProduct response object to a Product object
 *
 * @param {HttpProduct} data Http response from the API
 * @return {ProductInterface} Returns the mapped product object
 */
function mapProduct(data: HttpProduct): ProductInterface {
  return new ProductBuilder()
    .populate(data)
    .build();
}

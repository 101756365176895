import { Injectable, EventEmitter } from '@angular/core';
import { Selectable } from './../models/common/selectable.type';
import { SelectionType } from './../models/common/selection-type';
import { Subject } from 'rxjs';

@Injectable()
export class SelectionService {
  selectionChange: Subject<Selectable> = new Subject<Selectable>();
  private selected: Selectable;
  private currentEvent: any;

  /**
   * Returns the currently selected selectable [layout, layer, group, element]
   *
   * @return {Selectable} Returns the selected selectable
   */
  getSelected(): Selectable {
      return this.selected;
  }

  /**
   * Is there a selection made
   *
   * @return {boolean}
   */
  isSelection(): boolean {
     return (this.selected !== undefined);
  }

  /**
   * Is given selectable selected at the moment
   *
   * @return {boolean}
   */
  isSelected(selectable: Selectable): boolean {
     return (this.isSelection() && this.selected === selectable);
  }

  /**
   * Does the selection have a parent tier
   *
   * @return {boolean}
   */
  hasParent(): boolean {
     return (this.isSelection() && this.selected.parentTier !== undefined);
  }

  /**
   * Is an element selected currently
   *
   * @return {boolean}
   */
  isElement(): boolean {
      return (this.isSelection() && this.selected.selectionType === SelectionType.element);
  }

  /**
   * Get the element of the selectable.
   * If currently selected selectable is an element, returns the selected
   *
   * @return {Selectable}
   */
  getSelectableElement(): Selectable {
    if (this.isElement()) {
      return this.selected;
    }

    return undefined;
  }

  /**
   * Is a group selected currently
   *
   * @return {boolean}
   */
  isGroup(): boolean {
      return (this.isSelection() && this.selected.selectionType === SelectionType.group);
  }

  /**
   * Get the group of the selectable.
   *  - If currently selected selectable is a group , returns the selected
   *  - If currently selected is an element returns possible element group
   *
   * @return {Selectable}
   */
  getSelectableGroup(): Selectable {
    if (this.isGroup()) {
      return this.selected;
    }

    return this.findParent(SelectionType.group);
  }

  /**
   * Is a layer selected currently
   *
   * @return {boolean}
   */
  isLayer(): boolean {
      return (this.isSelection() && this.selected.selectionType === SelectionType.layer);
  }

  /**
   * Get the layer of the selectable.
   *  - If currently selected selectable is a layer , returns the selected
   *  - If currently selected selectable is a group , returns the group layer
   *  - If currently selected is an element returns possible element layer or element group's layer
   *
   * @return {Selectable}
   */
  getSelectableLayer(): Selectable {
    if (this.isLayer()) {
      return this.selected;
    }

    return this.findParent(SelectionType.layer);
  }

  /**
   * Is a layout selected currently
   *
   * @return {boolean}
   */
  isLayout(): boolean {
      return (this.isSelection() && this.selected.selectionType === SelectionType.layout);
  }

  /**
   * Get the layout of the selectable.
   *  - If currently selected selectable is a layout , returns the selected
   *  - If currently selected selectable is a layer , returns the layer layout
   *  - If currently selected selectable is a group , returns the group layer's layout
   *  - If currently selected is an element returns possible element layer's layout or element group layer's layout
   *
   * @return {Selectable}
   */
  getSelectableLayout(): Selectable {
    if (this.isLayout()) {
      return this.selected;
    }

    return this.findParent(SelectionType.layout);
  }

  /**
   * Get current event data
   *
   * @return {any} Returns the event data
   */
  getCurrentEvent(): any {
      return this.currentEvent;
  }

  /**
   * Set selection or unselect with selectable === undefined.
   * If same selecable is seleted, selectable gets unselected
   *
   * @param {any} event Event data
   * @param {Selectable} selectable The selected selectable or undefined for unselection
   * @return {void}
   */
  select(event: any, selectable: Selectable): void {
      this.currentEvent = event;
      if (selectable.can('implement')) {
        this.selected = selectable;
        this.selectionChange.next(this.selected);
      } else {
        this.clearSelection();
      }
  }

  /**
   * Clear current selection
   *
   * @return {void}
   */
  clearSelection(): void {
    this.selected = undefined;
    this.selectionChange.next(this.selected);
  }

  /**
   * Find parent by selection type from currently selected
   *
   * @return {Selectable}
   */
  protected findParent(selectionType: SelectionType): Selectable {
    if (! this.isSelection()) {
      return undefined;
    }
    let parent: Selectable = this.selected.parentTier;
    while (parent !== undefined && parent.selectionType !== selectionType) {
      parent = parent.parentTier;
    }

    return parent;
  }
}

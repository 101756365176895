import { Component, Input, Output, ViewChild, ElementRef, EventEmitter, ChangeDetectorRef, OnInit, DoCheck } from '@angular/core';
import { ElementService } from './../../../services/element.service';
import { SelectionService } from './../../../services/selection.service';
import { ImageService } from './../../../services/image.service';
import { ConfigurationService } from './../../../services/configuration.service';
import { ProductImageElement } from './../../../models/product/element/product-image-element';
import { ProductElement } from './../../../models/product/element/product-element.type';
import { StyleObject } from './../../../models/common/style-object.type';
import { Selectable } from './../../../models/common/selectable.type';
import { Unit } from './../../../../shared/unit';
import { environment } from './../../../../../environments/environment';
import { ResizePermission } from './../../../models/common/resize-permission';
import { ScalePermission } from './../../../models/common/scale-permission';
import { Image } from './../../../models/common/image.type';
import { Animator } from './../../../models/common/animator';

@Component({
  selector: 'sd-image-element',
  templateUrl: './image-element.component.html',
  styleUrls: ['./image-element.component.scss'],
})
export class ImageElementComponent implements OnInit, DoCheck {
  imageSrc: any;
  isLoading: boolean = false;
  image: Image;
  imageWidth: number;
  imageHeight: number;

  @Input() element: ProductImageElement;
  @Input() previewing: boolean = false;

  @Output() height: EventEmitter<number> = new EventEmitter<number>();
  @Output() width: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private elementService: ElementService,
    private elRef: ElementRef,
    private changeDetectionRef: ChangeDetectorRef,
    private selectionService: SelectionService,
    private imageService: ImageService,
    private configurationService: ConfigurationService
  ) {
  }

  ngOnInit() {
    this.image = this.element.image;
    this.imageWidth = this.element.width;
    this.imageHeight = this.element.height;

    this.loadImage();
  }

  ngDoCheck() {
    if (this.image !== this.element.image) {
      this.loadImage();
      this.changeDetectionRef.markForCheck();
    }
    if (this.imageWidth !== this.element.width) {
      this.setWidth(this.element.width);
    }
    if (this.imageHeight !== this.element.height) {
      this.setHeight(this.element.height);
    }
  }

  /**
   * Can current user update the element
   *
   * @return boolean
   */
  isEditable(): boolean {
    return this.element.can('implement');
  }

  /**
   * Is current element animated
   *
   * @return boolean
   */
  isAnimated(): boolean {
    return (this.previewing && this.element.isAnimetable());
  }

  /**
   * Load image source from API
   */
  loadImage() {
      if (this.isLoading) {
          return ;
      }
      if (! this.element.image || ! this.element.image.id) {
        this.imageSrc = undefined;
        this.image = undefined;

        return ;
      }
      this.isLoading = true;
      this.imageService.getImage(this.element.image.id, this.configurationService.getConfiguration())
        .subscribe(resp => {
          this.imageSrc = resp.body;
          this.element.imageSrc = resp.body;
          this.image = this.element.image;
          this.isLoading = false;
        },
        error => {
          this.image = this.element.image;
          this.isLoading = false;
          console.log(error);
        }
      );
  }

  /**
   * Is current element selected
   *
   * @return {boolean}
   */
  isSelected(): boolean {
    return this.selectionService.isSelected(this.element);
  }

  /**
   * Set element height to model from component element's real height
   *
   * @param {number} value Element real height
   * @return {void}
   */
  setHeight(value: number) {
    let curValue = this.imageHeight;
    if (value === curValue) {
      return ;
    }

    this.imageHeight = value;
    this.emitResize('height');
  }

  /**
   * Set element width to model from component element's real width
   *
   * @param {number} value Element real width
   * @return {void}
   */
  setWidth(value: number) {
    let curValue = this.imageWidth;
    if (value === curValue) {
      return ;
    }

    this.imageWidth = value;
    this.emitResize('width');
  }

  /**
   * Handler for position changes
   *
   * @param {any} event Event
   * @return {void}
   */
  onPositionChange(event: any) {
    this.element.xPos = (event.x) ? event.x : this.element.xPos;
    this.element.yPos = (event.y) ? event.y : this.element.yPos;
    // Tell view to detect changes
    this.changeDetectionRef.detectChanges();
  }

  /**
   * Emit detected resize
   *
   * @param {string} dimension Which dimension was resized: [width, height]
   * @return {void}
   */
  emitResize(dimension: string): void {
    if (dimension === 'width') {
      this.width.emit(this.element.width);
    } else if (dimension === 'height') {
      this.height.emit(this.element.height);
    }
    // Tell view to detect changes
    this.changeDetectionRef.detectChanges();
  }

  /**
   * Is the text element scallable
   *
   * @return {boolean} Returns bool based on the scale permission
   */
  isScallable(): boolean {
    let scalePermission: ScalePermission = (this.element.defaultElement) ? <ScalePermission>this.element.defaultElement.Permission('scale') : undefined;

    return (scalePermission && scalePermission.canChange());
  }

  /**
   * Return element in-line styles for data binding
   *
   * @return {any} Returns the styles in an object
   */
  setStyles(): any {
    let resizePermission: ResizePermission = (this.element.defaultElement) ? <ResizePermission>this.element.defaultElement.Permission('resize') : undefined;
    let maxHeight = (resizePermission) ? resizePermission.getMaxHeight() : undefined;
    let maxWidth = (resizePermission) ? resizePermission.getMaxWidth() : undefined;
    //  Scaling
    let scalePermission: ScalePermission = (this.element.defaultElement) ? <ScalePermission>this.element.defaultElement.Permission('scale') : undefined;

    let styles: StyleObject = {
      'width.mm': this.element.width,
      'maxWidth.mm': maxWidth,
      'height.mm': this.element.height,
      'maxHeight.mm': maxHeight,
      'marginTop.mm': this.element.getMargin('top'),
      'marginLeft.mm': this.element.getMargin('left'),
      'marginBottom.mm': this.element.getMargin('bottom'),
      'marginRight.mm': this.element.getMargin('right'),
      'float': this.element.getFloat(),
    };

    if (this.previewing) {
      let animator = new Animator(this.element);
      return { ...styles, ...animator.getAnimationStyles() }
    }

    return styles;
  }

  /**
   * Return element img-tag in-line styles for data binding
   *
   * @return {any} Returns the styles in an object
   */
  setImageStyles(): any {
    let scaledWidth = this.element.image.dimensions.width * this.element.scaledX;
    let scaledHeight = this.element.image.dimensions.height * this.element.scaledY;
    let objectPosition = this.element.xPos + 'mm ' + this.element.yPos + 'mm';

    let styles: StyleObject = {
      'width.mm': this.element.width,
      'height.mm': this.element.height,
      'background-image': 'url(' + this.imageSrc + ')',
      'background-position': objectPosition,
      'background-repeat': 'no-repeat',
      'background-size': scaledWidth + 'mm ' + scaledHeight + 'mm'
    };

    return styles;
  }

  /**
   * Return icon in-line styles for data binding
   *
   * @return {any} Returns the styles in an object
   */
  setIconStyles(): any {
    let styles: StyleObject = {
      'font-size.mm': (this.element.height > this.element.width) ? this.element.width : this.element.height
    };

    return styles;
  }

  /**
   * Does the layout have errors
   *
   * @return {boolean}
   */
  hasErrors(): boolean {
    return this.element.hasErrors();
  }

  /**
   * Does the layout have warnings
   *
   * @return {boolean}
   */
  hasWarnings(): boolean {
    return this.element.hasWarnings();
  }
}

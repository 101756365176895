import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';

import { environment } from './../../../environments/environment';
import { HttpTemplate } from './../models/common/http/template';
import { HttpProduct } from './../models/common/http/product';
import { HttpConfiguration } from './../models/common/http/configuration';
import { HttpProductImageElement } from './../models/common/http/product-image-element';
import { Configuration } from './../models/configuration';
import { NotificationService } from './../../services/notification.service';
import { AuthService } from './../services/auth.service';
import { PendingRequest } from './../models/common/http/pending-request';

import { catchError } from 'rxjs/operators';
import {encodeUriFragment, encodeUriQuery} from '@angular/router/src/url_tree';

@Injectable()
export class ApiService {

    private apiBaseUrl: string;
    private apiKey: string;
    private apiClientId: string;

    private imageRequests$ = new Subject<any>();
    private imageQueue: PendingRequest[] = [];


    constructor(private http: HttpClient, private notificationService: NotificationService, private authService: AuthService) {
        this.apiBaseUrl = environment.apiBaseUrl;
        this.apiKey = environment.apiKey;
        this.apiClientId = environment.clientId;
        // Subscribe to image request queue
        this.imageRequests$.subscribe(request => this.executeImageQueue(request));
    }

    /**
     * Requests template data with configuration that has been fetched
     *
     * @param {Configuration} configuration Configuration object
     * @return {Observable<HttpResponse<HttpTemplate>>} Returns the template data
     */
    getTemplate(configuration: Configuration): Observable<HttpResponse<HttpTemplate>> {
        const fullUrl = this.getApiUrl('templates/' + configuration.id, configuration.scope, configuration.userId);

        return this.http.get<HttpTemplate>(fullUrl, {observe: 'response', headers: this.getHeaders(fullUrl, configuration.secret, configuration.clientId,)});
    }

    /**
     * Requests product data with configuration that has been fetched
     *
     * @param {Configuration} configuration Configuration object
     * @return {Observable<HttpResponse<HttpProduct>>} Returns the template data
     */
    getProduct(configuration: Configuration): Observable<HttpResponse<HttpProduct>> {
        const fullUrl = this.getApiUrl('products/' + configuration.id, configuration.scope, configuration.userId);
        let data = this.http.get<HttpProduct>(fullUrl, {observe: 'response', headers: this.getHeaders(fullUrl, configuration.secret, configuration.clientId,)});

        return data;
    }

    /**
     * Requests configuration for this application with given hash
     *
     * @param {string} hash Hash that contains the needed information for getting the configuration
     * @return {Observable<HttpResponse<HttpConfiguration>>} Returns the configuration data
     */
    getConfiguration(hash: string): Observable<HttpResponse<HttpConfiguration>> {
      const fullUrl = this.getApiUrl('configuration/' + hash, null, null);

      return this.http.get<HttpConfiguration>(fullUrl, {observe: 'response', headers: this.getHeaders(fullUrl, this.apiKey, this.apiClientId)});
    }

    /**
     * Post new product data to API
     *
     * @param {string} product The product data in a JSON string
     * @param {Configuration} configuration The configuration class object
     * @return {Observable<HttpResponse<any>>} Returns the API response for the request
     */
    createProduct(product: string, configuration: Configuration): Observable<HttpProduct> {
      const fullUrl = this.getApiUrl('products/', configuration.scope, configuration.userId);
      let productCode = (configuration.productCode) ? '&productcode=' + configuration.productCode : '';
      let session = (configuration.sessionId) ? '&sessionId=' + configuration.sessionId : '';
      let url = fullUrl + productCode + session;
      let headers = {headers: this.getHeaders(url, configuration.secret, configuration.clientId)};

      return this.http.post<HttpProduct>(url, product, headers);
    }

    /**
     * Update product data to API
     *
     * @param {string} productCode The product code
     * @param {string} product The product data in a JSON string
     * @param {Configuration} configuration The configuration class object
     * @param {boolean} lightReturn If true, only product details are retuned without template structure.
     * @return {Observable<HttpProduct>} Returns the API response for the request
     */
    updateProduct(productCode: string, product: string, configuration: Configuration, lightReturn: boolean = false): Observable<HttpProduct> {
      const fullUrl = this.getApiUrl('products/' + productCode, configuration.scope, configuration.userId);
      let url = fullUrl + ((lightReturn) ?  '&info=1' : '');
      let headers = {headers: this.getHeaders(url, configuration.secret, configuration.clientId)};

      return this.http.put<HttpProduct>(url, product, headers);
    }

    /**
     * Requests image source with element id
     *
     * @param {number} id Image element ID
     * @param {Configuration} configuration The configuration class object
     * @param {boolean} optimized Is only optimized image retured. Default true
     * @return {Observable<HttpResponse<string>>} Returns the image source
     */
    getImage(id: number, configuration: Configuration, optimized: boolean = true): Observable<HttpResponse<string>> {
      const fullUrl = this.getApiUrl('images/' + id, configuration.scope, configuration.userId);
      let optimizeParam = (optimized) ? '&optimize=1' : '';
      let url = fullUrl + optimizeParam;

      return this.invokeImageQueue(url, 'GET', {}, {responseType: 'text', observe: 'response', headers: this.getHeaders(url, configuration.secret, configuration.clientId,)});
      //return this.http.get(url, {responseType: 'text', observe: 'response', headers: this.getHeaders(url, configuration.secret, configuration.clientId,)});
    }

    /**
     * Requests preview image source with template or product id
     *
     * @param {number} page Page number of the previewed document
     * @param {Configuration} configuration The configuration class object
     * @param {string} size Preview image size. [xsmall, small, medium, full]. Default medium
     * @return {Observable<HttpResponse<string>>} Returns the image source
     */
    getPreviewImage(page: number, configuration: Configuration, size: string = 'medium'): Observable<HttpResponse<string>> {
        page = (! page) ? 1 : page;
        size = (! size) ? '&size=medium' : '&size=' + size;
        let type = (configuration.isTemplate()) ? 'templates' : 'products';
        const fullUrl = this.getApiUrl(type + '/' + configuration.id + '/image/' + page, configuration.scope, configuration.userId);
        let url = fullUrl + '&base64=1' + size;
        return this.invokeImageQueue(url, 'GET', {}, {responseType: 'text', observe: 'response', headers: this.getHeaders(url, configuration.secret, configuration.clientId,)});
        //return this.http.get(url, {responseType: 'text', observe: 'response', headers: this.getHeaders(url, configuration.secret, configuration.clientId,)});
    }

    /**
     * Requests client images from client API.
     * SmartDesigner API redirects the request to the client API.
     *
     * @param {Configuration} configuration The configuration class object
     * @param {any} parameters Optional parameters passed with the request to the API
     * @return {Observable<HttpResponse>} Returns the images in an array
     */
    getClientImages(configuration: Configuration, parameters?:any): Observable<HttpResponse<any>> {
        let httpParams = new HttpParams();
        for (let key in parameters) {
          let value = parameters[key];
          if (Array.isArray(value)) {
            let paramName = (value.length > 1) ? key + '[]' : key;
            for (let val of value) {
              httpParams = httpParams.append(paramName, val);
            }
          } else {
            httpParams = httpParams.append(key, value);
          }
        }
        const fullUrl = this.getApiUrl('client/images', configuration.scope, configuration.userId);
        let url = fullUrl + '&' + decodeURIComponent(httpParams.toString());
        return this.http.get(url, {observe: 'response', headers: this.getHeaders(url, configuration.secret, configuration.clientId,)});
    }

    /**
     * Upload to API
     *
     * @param {number} id Image Id. If null, new image will be created
     * @param {FormData} formData Form data
     * @param {any} parameters Other parameters
     * @param {Configuration} configuration Configuration model
     * @return {Observable<HttpProductImageElement>} Returns the image element data
     */
    upload(id: number, formData: FormData, parameters: any, configuration: Configuration): Observable<HttpProductImageElement> {
        const fullUrl = this.getApiUrl(('images/' + (id !== undefined ? String(id) : '')), configuration.scope, configuration.userId);
        let options = {
          headers: this.getHeaders(fullUrl, configuration.secret, configuration.clientId,),
          params: parameters
        };

        return this.http.post<HttpProductImageElement>(fullUrl, formData, options);
    }

  /**
   * Upload QR code to replace an image with
   * @param imageAssetId
   * @param imageElementId
   * @param qrcodeContent
   * @param configuration
   * @return {Observable<HttpProductImageElement>} Returns generated image element data
   */
  uploadQrcode(imageAssetId: number, imageElementId: number, qrcodeContent: string, configuration: Configuration): Observable<HttpProductImageElement> {
    const formData = {
      'qrcode_content': qrcodeContent,
    };
    let params = new HttpParams();
    params = params.set('is_qrcode', '1');
    params = params.set('resource', imageElementId.toString());
    let fullUrl = this.getApiUrl(('images/' + (imageAssetId !== undefined ? String(imageAssetId) : '')), configuration.scope, configuration.userId);
    fullUrl += '&' + params;

    const options = {
      headers: this.getHeaders(fullUrl, configuration.secret, configuration.clientId, ),
      params: {},
    };

    return this.http.post<HttpProductImageElement>(fullUrl, formData, options);
  }

    /**
     * Send client image selection to API
     *
     * @param {number} id Image Id. If null, new image will be created
     * @param {FormData} formData Form data
     * @param {any} parameters Other parameters
     * @param {Configuration} configuration Configuration model
     * @return {Observable<Object>} Returns the image element data
     */
    selectClientImage(id: number, formData: FormData, parameters: any, configuration: Configuration): Observable<Object> {
        const fullUrl = this.getApiUrl(('images/' + (id !== undefined ? String(id) : '')), configuration.scope, configuration.userId);
        let options = {
          headers: this.getHeaders(fullUrl, configuration.secret, configuration.clientId, false),
          params: parameters
        };

        return this.http.post(fullUrl, formData, options);
    }

    /**
     * Send end session request to the API
     *
     * @param {Configuration} configuration Current configuration
     * @return {Observable<{}>} Returns the API response
     */
    endSession(configuration: Configuration): Observable<{}> {
      const fullUrl = this.getApiUrl('configuration/' + configuration.sessionId, configuration.scope, configuration.userId);
      let headers = {headers: this.getHeaders(fullUrl, this.apiKey, this.apiClientId)};

      return this.http.delete(fullUrl, headers);
    }

  /**
   * Returns an API url
   *
   * @param {string} action Name of the method called
   * @param {string} scope User scope
   * @param {string} userId Id of the current user
   * @return {string} Returns the API url
   */
  public getApiUrl(action: string, scope: string, userId: string): string {
    let apiUrl = this.apiBaseUrl + action + '?callback=JSONP_CALLBACK';
    if (scope) {
      apiUrl += '&scope=' + scope;
    }
    if (userId) {
      apiUrl += '&userid=' + userId;
    }

    return apiUrl;
  }

  /**
   * Invoke image request queue by adding a new request to the queue
   *
   * @param {string} url Request URL
   * @param {string} method Request method
   * @param {any} params Request parameters
   * @param {any} options Request options
   * @return {Subject<any>} Returns the request observable
   */
  protected invokeImageQueue(url: string, method, params, options) {
    return this.addImageRequestToQueue(url, method, params, options);
  }

  /**
   * Get API request headers: Accept and Authorization is set
   *
   * @param {string} Url URL of the request
   * @param {string} clientApiKey API key for API Requests
   * @param {string} clientId Id for the client
   * @param {boolean} contentTypeJson Is JSON used as content type. Default true
   * @return {HttpHeaders} Returns the headers in an object
   */
  private getHeaders(url:string, clientApiKey: string, clientId: string, contentTypeJson: boolean = true): HttpHeaders {
    let headers = {
      'Accept': 'application/json'
    };

    if (contentTypeJson) {
      headers['Content-Type'] = 'application/json; charset=UTF-8';
    }
    if (clientId !== null && clientApiKey !== null) {
      let authorization = this.authService.getAuthToken(url, clientApiKey, clientId);
      headers['Authorization'] = authorization;
    }

    return new HttpHeaders(headers);
  }

  /**
   * Execute requests from image queue
   *
   * @param {PendingRequest} requestData Request data
   * @return {void}
   */
  private executeImageQueue(requestData: PendingRequest) {
    switch (requestData.method) {
      case 'GET':
        this.executeImageQueueGet(requestData);
        break;
      // Implement more methods if needed
    }
  }

  /**
   * Execute requests from image queue
   *
   * @param {PendingRequest} requestData Request data
   * @return {void}
   */
  private executeImageQueueGet(requestData: PendingRequest) {
    const req = this.http.get(requestData.url, requestData.options)
      .subscribe(res => {
        const sub = requestData.subscription;
        sub.next(res);
        this.imageQueue.shift();
        this.startNextImageRequest();
      });
  }

  /**
   * Add new image request to queue
   *
   * @param {string} url Request Url
   * @param {string} method Request method
   * @param {any} params Request parameters
   * @param {any} options Request options
   * @return {Subject<any>} Returns the request observable
   */
  private addImageRequestToQueue(url: string, method: string, params: any, options: any) {
    const sub = new Subject<any>();
    const request = new PendingRequest(url, method, options, sub);

    this.imageQueue.push(request);
    if (this.imageQueue.length === 1) {
      this.startNextImageRequest();
    }
    return sub;
  }

  /**
   * Start execution of the next image request
   *
   * @return {void}
   */
  private startNextImageRequest() {
    // get next request, if any.
    if (this.imageQueue.length > 0) {
      this.executeImageQueue(this.imageQueue[0]);
    }
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Unit } from './../../shared/unit';

@Injectable()
export class ZoomService {

  protected zoomLevel:number;
  protected zoomStep: number = .25;
  protected maxScale = 3;
  protected minScale = .25;

  zoomChanged: Subject<number> = new Subject<number>();

  /**
   * Set environment globals in the constructor
   *
   * @return {void}
   */
  constructor() { }

  /**
   * Is application zoom level initialized yet
   *
   * @return {boolean}
   */
  isZoomInitialized()
  {
    return (! this.zoomLevel) ? false : true;
  }

  /**
   * Initialize zoom level based on product size
   *
   * @param {number} layoutWidth
   * @param {number} layoutHeight
   * @return {void}
   */
  initializeZoomLevel(layoutWidth: number, layoutHeight: number) {
    let scale = .75;
    let zoomLevel = 0;
    let screenAreaWidth = Number(Unit.pxToMm(window.screen.width) * scale);
    let screenAreaHeight = Number(Unit.pxToMm(window.screen.height) * scale);
    // Check if the layout is a lot smaller than the screen
    if ((screenAreaWidth > (layoutWidth * this.Zoom(zoomLevel))
      || screenAreaHeight > (layoutHeight * this.Zoom(zoomLevel)))) {
      var maxZoom = 4;
      while (zoomLevel < maxZoom && screenAreaWidth > (layoutWidth * this.Zoom(zoomLevel))) {
        zoomLevel += 1;
      }
      while (zoomLevel < maxZoom && screenAreaHeight > (layoutHeight * this.Zoom(zoomLevel))) {
        zoomLevel += 1;
      }
    }
    // Check that layout fits horizontally
    while (screenAreaWidth < (layoutWidth * this.Zoom(zoomLevel))) {
      zoomLevel -= 1;
    }
    // Check that layout fits vertically
    while (screenAreaHeight < (layoutHeight * this.Zoom(zoomLevel))) {
      zoomLevel -= 1;
    }

    this.zoomLevel = zoomLevel;
    this.zoomChanged.next(this.Zoom());
  }

  /**
   * Get current zoom level
   *
   * @return {number}
   */
  ZoomLevel(): number {
    return this.zoomLevel;
  }

  /**
   * Increase current zoom level
   */
  increaseZoom() {
    this.zoomLevel += 1;

    this.zoomChanged.next(this.Zoom());
  }

  /**
   * Decrease current zoom level
   */
  decreaseZoom() {
    this.zoomLevel -= 1;

    this.zoomChanged.next(this.Zoom());
  }

  /**
   * Can user increase the zoom level
   *
   * @param {string} direction Zoom direction
   * @return {boolean}
   */
  canZoom(direction: string, layoutWidth: number, layoutHeight: number): boolean {
    let scale = .75;
    // Check that layout fits horizontally
    let covers = (layoutWidth * this.Zoom(this.zoomLevel)) / (Unit.pxToMm(window.screen.width) * scale);
    if (direction === 'increase') {
       return (covers < this.maxScale);
    }
    return (this.zoomLevel > 0 || covers > this.minScale);
  }

  /**
   * Returns the current zoom percentage
   *
   * @param {number} zoomLevel Zoom level used in the zoom calculations
   * @return {number}
   */
  Zoom(zoomLevel?: number): number {
    let level = (zoomLevel != undefined) ? zoomLevel : this.zoomLevel;
    let exponent = (level > 0) ? level : level * -1;
    let step = (level > 0) ? 1 + this.zoomStep : 1 - this.zoomStep;

    return (Math.pow(step, exponent));
  }
}

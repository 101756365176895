import { Component, Input, OnInit, OnDestroy, OnChanges, DoCheck, SimpleChanges, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { LayerService } from './../../services/layer.service';
import { FormService } from './../../services/form.service';
import { SelectionService } from './../../services/selection.service';
import { ImageService } from './../../services/image.service';
import { ConfigurationService } from './../../services/configuration.service';
import { ProductLayoutInterface } from './../../models/product/product-layout.type';
import { ProductInterface } from './../../models/product/product.type';
import { ProductLayerInterface } from './../../models/product/product-layer.type';
import { Unit } from './../../../shared/unit';
import { StyleObject } from './../../models/common/style-object.type';
import { Selectable } from './../../models/common/selectable.type';
import { ResizePermission } from './../../models/common/resize-permission';
import { Image } from './../../models/common/image.type';
import { Animator } from './../../models/common/animator';

@Component({
  selector: 'sd-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit, OnDestroy, OnChanges, DoCheck {
  alive: boolean = false;

  @Input() product: ProductInterface;
  @Input() previewing: boolean = false;

  @ViewChild('container') container;
  layout: ProductLayoutInterface;

  isLoading: boolean = false;
  imageSrc: any;
  image: Image;
  imageWidth: number;
  imageHeight: number;

  constructor(
    private layerService: LayerService,
    private formService: FormService,
    private elRef: ElementRef,
    private changeDetectionRef: ChangeDetectorRef,
    private selectionService: SelectionService,
    private imageService: ImageService,
    private configurationService: ConfigurationService
  ){}

  ngOnInit() {
    this.alive = true;
    this.layout = this.product.getSelectedLayout();
    this.image = this.layout.backgroundImage;

    this.loadImage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.product) {
      this.product = <ProductInterface>changes.product.currentValue;
      this.layout = this.product.getSelectedLayout();
    }
  }

  ngDoCheck() {
    let markForCheck = false;
    if (! this.isLoading && this.image != this.layout.backgroundImage) {
      this.loadImage();
      markForCheck = true;
    }
    if (this.layout != this.product.getSelectedLayout()) {
      this.layout = this.product.getSelectedLayout();
      this.imageSrc = undefined;
      this.image = undefined;
      markForCheck = true;
    }
    if (markForCheck) {
      this.changeDetectionRef.markForCheck();
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

  /**
   * On page selection handler
   *
   * @param {any} event Click Event
   * @param {Selectable} selectable Selected page
   * @return {void}
   */
  onSelection(event: any, selectable: Selectable) {
    this.selectionService.select(event, selectable);
  }


  /**
   * Load image source from API
   */
  loadImage() {
      // If image is loading at the moment, wont start a new load.
      if (this.isLoading) {
        return ;
      }
      if (! this.layout.backgroundImage || ! this.layout.backgroundImage.id || this.layout.backgroundImage.id == null) {
        this.imageSrc = undefined;
        this.image = undefined;

        return ;
      }
      this.isLoading = true;
      this.imageService.getImage(this.layout.backgroundImage.id, this.configurationService.getConfiguration())
        .subscribe(resp => {
          this.imageSrc = resp.body;
          this.layout.imageSrc = resp.body;
          this.image = this.layout.backgroundImage;
          this.isLoading = false;
        },
        error => {
          this.image = this.layout.backgroundImage;
          this.isLoading = false;
          console.log(error);
        }
      );
  }

  /**
   * Can current user edit the background in general
   *
   * @return boolean
   */
  isEditable(): boolean {
    return (! this.previewing && this.layout.can('implement') && (this.canUpdateBgColor() || this.canUpdateBgImage()));
  }

  /**
   * Is current element animated
   *
   * @return boolean
   */
  isAnimated(): boolean {
    return (this.previewing && this.layout.isAnimetable());
  }

  /**
   * Does the layout have errors
   *
   * @return {boolean}
   */
  hasErrors(): boolean {
    return this.layout.hasErrors();
  }

  /**
   * Does the layout have warnings
   *
   * @return {boolean}
   */
  hasWarnings(): boolean {
    return this.layout.hasWarnings();
  }

  /**
   * Can current user update the background color
   *
   * @return boolean
   */
  canUpdateBgColor(): boolean {
    return this.layout.can('backgroundColor');
  }

  /**
   * Can current user update the background image
   *
   * @return boolean
   */
  canUpdateBgImage(): boolean {
    return this.layout.can('backgroundImage');
  }

  /**
   * Set layout height to model from content's real height
   *
   * @param {number} value Layer real height
   * @param {ProductLayerInterface} layer Layer which height have changed
   * @return {void}
   */
  setHeight(value: number, layer: ProductLayerInterface) {
    let calcValue = this.layout.calcContentHeight();
    let curValue = this.layout.height;
    if (calcValue === curValue) {
      return ;
    }

    this.layout.setHeight(calcValue);
    if (curValue !== this.layout.height) {
      this.changeDetectionRef.detectChanges();
    }
  }

  /**
   * Set layout width to model from content's real width
   *
   * @param {number} value Layer real width
   * @param {ProductLayerInterface} layer Layer which width have changed
   * @return {void}
   */
  setWidth(value: number, layer: ProductLayerInterface) {
    let calcValue = this.layout.calcContentWidth();
    let curValue = this.layout.width;
    if (calcValue === curValue) {
      return ;
    }
    this.layout.setWidth(calcValue);
    if (curValue !== this.layout.width) {
      this.changeDetectionRef.detectChanges();
    }
  }

  /**
   * Return element in-line styles for data binding
   *
   * @return {any} Returns the styles in an object
   */
  setStyles(): any {
    let resizePermission: ResizePermission = (this.layout.layout) ? <ResizePermission>this.layout.layout.Permission('resize') : undefined;
    let maxHeight = (resizePermission) ? resizePermission.getMaxHeight() : undefined;
    let maxWidth = (resizePermission) ? resizePermission.getMaxWidth() : undefined;

    let scaledWidth = (this.layout.backgroundImage) ?  this.layout.backgroundImage.dimensions.width * this.layout.scaledX : undefined;
    let scaledHeight = (this.layout.backgroundImage) ? this.layout.backgroundImage.dimensions.height * this.layout.scaledY : undefined;
    let backgroundSize = (scaledWidth && scaledHeight) ? scaledWidth + 'mm ' + scaledHeight + 'mm' : 'auto';
    let objectPosition = (this.imageSrc) ? this.layout.xPos + 'mm ' + this.layout.yPos + 'mm' : '0% 0%';
    let backgroundImage = (this.imageSrc) ? 'url(' + this.imageSrc + ')' : 'none';

    let styles: StyleObject = {
      'width.mm': this.layout.width,
      'height.mm': this.layout.height,
      'maxWidth.mm': maxWidth,
      'maxHeight.mm': maxHeight,
      'background-color': this.layout.backgroundColor.rgb,
      'background-image': backgroundImage,
      'background-position': objectPosition,
      'background-repeat': 'no-repeat',
      'background-size': backgroundSize,
      'overflow': 'hidden'
    };

    if (this.imageSrc) {
      styles['background-image'] = 'url(' + this.imageSrc + ')';
    }

    if (this.previewing) {
      let animator = new Animator(this.layout);
      return { ...styles, ...animator.getAnimationStyles() }
    }

    return styles;
  }

  /**
   * Return element in-line styles for page trimbox
   *
   * @return {any} Returns the styles in an object
   */
  getTrimboxStyles(): any {

    let styles: StyleObject = {
      'border-width.mm': this.product.Bleed()
    };

    return styles;
  }
}

import { Permission } from './permission.type';

export class ScalePermission implements Permission
{
  permission: boolean;

  constructor(permission:any) {
    this.permission = permission;
  }

  /**
   * Can an action be exuted based on the permission
   *
   * @return {boolean}
   */
  public canChange(): boolean {
    return (this.Permission().update || false)
  }

  /**
   * Get permission value
   *
   * @return {any} Returns the permission value
   */
  public Permission(): any {
    return this.permission;
  }

  /**
   * Returns the possible maximum scaled size for the resource
   * undefined = until size exeeded
   *
   * @return number|undefined
   */
  public getMax(): number | undefined {
    return this.Permission().max;
  }

  /**
   * Returns the possible minimum scaled size for the resource
   *
   * @return number
   */
  public getMin(): number | undefined {
    return this.Permission().min;
  }

  /**
   * Returns the one notch unit for scaling: Divisible used in scaling
   * undefined = dynamic
   *
   * @return number|undefined
   */
  public getNotch(): number | undefined {
    return this.Permission().notch;
  }
}

import { Injectable, EventEmitter } from '@angular/core';
import { Configuration } from './../models/configuration';
import { ProductElement } from './../models/product/element/product-element.type';
import { ProductImageElement } from './../models/product/element/product-image-element';
import { ClientImage } from './../models/common/client-image.type';
import { ClientCategory } from './../models/common/client-category.type';
import { HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { ProductElementBuilder } from './../models/product/builder/product-element-builder';


@Injectable()
export class ImageService {

  clientImagesChange: Subject<Array<ClientCategory>> = new Subject<Array<ClientCategory>>();

  constructor(private apiService: ApiService) { }

  /**
   * Get preview image of the current resource by page number
   *
   * @param {number} page Page number of the previewed document
   * @param {Configuration} configuration Ui configuration
   * @param {string} size Preview image size. [xsmall, small, medium, full]. Default medium
   * @return {Observable<HttpResponse<string>>} Returns image source as base64 encoded string
   */
  public getPreviewImage(page: number, configuration: Configuration, size: string = 'medium'): Observable<HttpResponse<string>> {
    return this.apiService.getPreviewImage(page, configuration, size);
  }

  /**
   * Get preview image element image source from API
   *
   * @param {number} id Image element ID
   * @param {Configuration} configuration Ui configuration
   * @param {boolean} optimized Is only optimized image retured. Default true
   * @return {Observable<HttpResponse<string>>} Returns image source
   */
  public getImage(id: number, configuration: Configuration, optimized: boolean = true): Observable<HttpResponse<string>> {
    return this.apiService.getImage(id, configuration, optimized);
  }

  /**
   * Get images from client service API
   *
   * @param {Configuration} configuration Configuration model
   * @param {any} parameters Optional parameters passed with the request to the API
   * @return {any} Returns image data
   */
  public getClientImages(configuration: Configuration, parameters?:any): any {
    this.apiService.getClientImages(configuration, parameters).subscribe(
       data => {
         // refresh the product
         return this.setClientImages(data.body);
       }
    );
  }

  /**
   * Set a client image as the selected image.
   * Need to send the image ID to the SD API and that will download the image from the client API
   *
   * @param {number} id Id of the image. If set, image is replaced
   * @param {ClientImage} clientImage Selected image
   * @param {number} elementId Id of the product image element if set.
   * @param {number} templateId Id of the product's template.
   * @param {number} defaultElementId Id of the layout default image element.
   * @param {number} width Image width
   * @param {number} height Image height
   * @param {Configuration} configuration Configuration object model
   * @return {}
   */
  public makeClientImageSelection(id: number, clientImage: ClientImage, elementId: number, templateId: string, defaultElementId: number, width: number, height: number, configuration: Configuration) {
    let formData: FormData = new FormData();
    formData.append('clientImageUrl', clientImage.url);
    formData.append('clientImageName', clientImage.name);
    formData.append('template', templateId);
    if (elementId) {
      formData.append('resource', String(elementId));
    }
    if (defaultElementId) {
      formData.append('default', String(defaultElementId));
    }
    formData.append('width', String(width));
    formData.append('height', String(height));

    return this.apiService.selectClientImage(id, formData, {}, configuration)
      .pipe(map(
        response => {
          return response;
        },
      ));
  }

  /**
   * Upload image to API
   *
   * @param {number} id Id of the image. If set, image is replaced
   * @param {number} elementId Id of the product image element if set.
   * @param {number} templateId Id of the product's template.
   * @param {File} file Uploaded file
   * @param {number} defaultElementId Id of the layout default image element.
   * @param {number} width Image width
   * @param {number} height Image height
   * @param {Configuration} configuration Service configuration object
   * @return {void}
   */
  public uploadImage(id: number, elementId: number, templateId: string, file: File, defaultElementId: number, width: number, height: number, configuration: Configuration): Observable<ProductImageElement> {
    if (! file) {
      return ;
    }
    let formData: FormData = new FormData();
    formData.append('Filedata[]', file, file.name);
    formData.append('template', templateId);
    if (elementId) {
      formData.append('element', String(elementId));
    }
    if (defaultElementId) {
      formData.append('defaultElement', String(defaultElementId));
    }
    formData.append('width', String(width));
    formData.append('height', String(height));

    this.apiService.upload(id, formData, {}, configuration)
      .subscribe(
        response => {
          let element = ProductElementBuilder.build(response);
          if (element instanceof ProductImageElement) {
            return element;
          }
        },
    );

  }

  /**
   * Returns the upload URI for an element file
   *
   * @return {string} Returns the upload URI
   */
  public getUploadUri(id: number, configuration: Configuration): string {
    return this.apiService.getApiUrl(('images/' + (id !== undefined ? String(id) : '')), configuration.scope, configuration.userId);
  }

  /**
   * Set client images requested from client service API
   *
   * @param {any} categories Response from client API with image data
   * @return {void}
   */
  protected setClientImages(categories: any) {
    let clientCategories: Array<ClientCategory> = [];

    for (let category of categories) {
      let clientImages: Array<ClientImage> = [];
      for (let image of category.images) {
        let clientImage: ClientImage = {
          'name': image.name,
          'url': image.url,
          'thumbnail': image.thumbnail,
          'mimeType': image.mimetype,
          'resolutions': image.resolutions,
          'dimensions': image.dimensions,
          'filesize': image.filesize
        };
        clientImages.push(clientImage);
      }
      let clientCategory: ClientCategory = {
        'category': category.category,
        'images': clientImages,
      };

      clientCategories.push(clientCategory);
    }

    this.clientImagesChange.next(clientCategories);
  }
}

import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { MatDialog } from '@angular/material';
import { SdDialogComponent } from './../smartdesigner-app/components/common/dialog/sd-dialog.component';
import { Observable, Subject } from 'rxjs';


@Injectable()
export class NotificationService {

  notificationChange: Subject<any> = new Subject<any>();

  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) { }

  success(detail: string, summary?: string): void {

  }

  info(detail: string, summary?: string): void {

  }

  warning(detail: string, summary?: string): void {

  }

  error(detail: any, summary?: string): void {

    this.notificationChange.next(detail);
    //let config = new MatSnackBarConfig();
    //this.snackBar.open(detail, 'Ok', config);
    /*let dialogRef = this.dialog.open(SdDialogComponent, {
      width: '250px',
      data: detail
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

    dialogRef.afterOpen().subscribe(result => {
      console.log('The dialog was opened');
    });*/
  }

  snackError(detail: any, summary?: string): void {
    let config = new MatSnackBarConfig();
    this.snackBar.open(detail, 'Ok', config);
    /*let dialogRef = this.dialog.open(SdDialogComponent, {
      width: '250px',
      data: detail
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

    dialogRef.afterOpen().subscribe(result => {
      console.log('The dialog was opened');
    });*/
  }
}

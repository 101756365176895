import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ConfirmDeactivateGuard } from "./smartdesigner-app/guards/confirm-deactivate.guard";
import { SmartdesignerAppComponent } from "./smartdesigner-app/smartdesigner-app.component";
import { FroalaComponent } from "./froala/froala.component";
import { PageNotFoundComponent } from "./page-not-found-component/page-not-found.component";

const appRoutes: Routes = [
  {
    path: "sd/:hash",
    component: SmartdesignerAppComponent,
    canDeactivate: [ConfirmDeactivateGuard]
  },
  {
    path: "froala",
    component: FroalaComponent
  },
  {
    path: "**",
    component: PageNotFoundComponent,
    canDeactivate: [ConfirmDeactivateGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { ProductLayoutInterface } from './../product-layout.type';
import { ProductLayout } from './../product-layout';
import { ProductLayoutBuilderInterface } from './product-layout-builder.type';
import { Layout } from './../../template/layout';
import { Layer } from './../../template/layer';
import { ProductLayerInterface } from './../product-layer.type';
import { ProductLayer } from './../product-layer';
import { ProductLayerBuilder } from './product-layer-builder';
import { LayoutBuilder } from './../../template/builder/layout-builder';
import { Selectable } from './../../common/selectable.type';
import { Image } from './../../common/image.type';
import { Color } from './../../common/color.type';

export class ProductLayoutBuilder implements ProductLayoutBuilderInterface {
  private id: number;
  private key: string;
  private layout: Layout;
  private width: number;
  private height: number;
  private published: boolean;
  private creationDate: number;
  private modificationDate: number;
  private backgroundColor: Color;
  private backgroundImage: Image;
  public xPos: number = 0;
  public yPos: number = 0;
  public scaledX: number = 1;
  public scaledY: number = 1;
  private layers: Array<ProductLayerInterface>;
  private attributes: any;

  constructor() {}

  /**
   * Get Id
   *
   * @return {number} Returns the product layout Id
   */
  get Id() {
    return this.id;
  }

  /**
   * Set Id
   *
   * @param {number} id Product layout Id
   * @return {ProductLayoutBuilder}
   */
  setId(id: number) {
    this.id = id;
    return this;
  }

  /**
   * Get key
   *
   * @return {string} Returns the product layout key
   */
  get Key() {
    return this.key;
  }

  /**
   * Set key
   *
   * @param {string} key Product layout key
   * @return {ProductLayoutBuilder}
   */
  setKey(key: string) {
    this.key = key;
    return this;
  }

  /**
   * Get width
   *
   * @return {number} Returns the product layout width
   */
  get Width() {
    return this.width;
  }

  /**
   * Set width
   *
   * @param {number} width Product layout width
   * @return {ProductLayoutBuilder}
   */
  setWidth(width: number) {
    this.width = width;
    return this;
  }

  /**
   * Get height
   *
   * @return {number} Returns the product layout height
   */
  get Height() {
    return this.height;
  }

  /**
   * Set height
   *
   * @param {number} height Height
   * @return {ProductLayoutBuilder}
   */
  setHeight(height: number) {
    this.height = height;
    return this;
  }

  /**
   * Get layout
   *
   * @return {Layout} Returns the template layout
   */
  get Layout() {
    return this.layout;
  }

  /**
   * Set template layout
   *
   * @param {Layout} layout Template layout
   * @return {ProductLayoutBuilder}
   */
  setLayout(layout: Layout) {
    if (layout instanceof Layout) {
      this.layout = layout;
    } else {
      let builder = new LayoutBuilder();
      this.layout = builder.populate(layout).build();
    }
  }

  /**
   * Is Published
   *
   * @return {boolean} Returns Published value
   */
  get Published() {
    return this.published;
  }

  /**
   * Set Published
   *
   * @param {boolean} published Published value
   * @return {ProductLayoutBuilder}
   */
  setPublished(published: boolean) {
    this.published = published;
    return this;
  }

  /**
   * Get Creation Date
   *
   * @return {number} Returns the Creation date timestamp
   */
  get CreationDate() {
    return this.creationDate;
  }

  /**
   * Set Creation Date
   *
   * @param {number} date CreationDate
   * @return {ProductLayoutBuilder}
   */
  setCreationDate(date: number) {
    this.creationDate = date;
    return this;
  }

  /**
   * Get modification date
   *
   * @return {number} Returns the modification date timestamp
   */
  get ModificationDate() {
    return this.modificationDate;
  }

  /**
   * Set Modification date
   *
   * @param {number} date Modification date
   * @return {ProductLayoutBuilder}
   */
  setModificationDate(date: number) {
    this.modificationDate = date;
    return this;
  }

  /**
   * Get layers
   *
   * @return {Array<ProductLayerInterface>} Returns the product layout layers
   */
  get Layers() {
    return this.layers;
  }

  /**
   * Set layers
   *
   * @param {Array<any>} layers Layers
   * @return {ProductLayoutBuilder}
   */
  setLayers(layers: Array<any>) {
    if (! layers || layers.length == 0) {
      return this;
    }
    let productLayers = [];
    for (let layer of layers) {
      if (layer instanceof ProductLayer) {
        productLayers.push(layer);
      } else {
        layer.layer = this.getLayerById(layer.defaultLayer);
        let builder = new ProductLayerBuilder();
        productLayers.push(builder.populate(layer).build());
      }
    }
    this.layers = productLayers;
    return this;
  }

  /**
   * Get background color
   *
   * @return {Color} Returns the layout background color
   */
  get BackgroundColor() {
    return this.backgroundColor;
  }

  /**
   * Set background color
   *
   * @param {Color} color Layout background color
   * @return {ProductLayoutBuilder}
   */
  setBackgroundColor(color: Color) {
    this.backgroundColor = color;
    return this;
  }

  /**
   * Get background image
   *
   * @return {Image} Returns the layout background image
   */
  get BackgroundImage() {
    return this.backgroundImage;
  }

  /**
   * Set background image
   *
   * @param {Image} image Layout background image data
   * @return {ProductLayoutBuilder}
   */
  setBackgroundImage(image: Image) {
    this.backgroundImage = image;
    return this;
  }

  /**
   * Get background X-position
   *
   * @return {number} Returns the product layout background X-position
   */
  get XPos() {
    return this.xPos;
  }

  /**
   * Set background X-position
   *
   * @param {number} value The product layout background X-position
   * @return {LayoutBuilder}
   */
  setXPos(value: number) {
    this.xPos = value;

    return this;
  }

  /**
   * Get background Y-position
   *
   * @return {number} Returns the product layout background Y-position
   */
  get YPos() {
    return this.yPos;
  }

  /**
   * Set background Y-position
   *
   * @param {number} value The product layout background Y-position
   * @return {LayoutBuilder}
   */
  setYPos(value: number) {
    this.yPos = value;

    return this;
  }

  /**
   * Get background scale value X
   *
   * @return {number} Returns the product layout background scale value X
   */
  get ScaledX() {
    return this.scaledX;
  }

  /**
   * Set background scale value X
   *
   * @param {number} value The product layout background scale value X
   * @return {LayoutBuilder}
   */
  setScaledX(value: number) {
    this.scaledX = value;

    return this;
  }

  /**
   * Get background scale value Y
   *
   * @return {number} Returns the product layout background scale value Y
   */
  get ScaledY() {
    return this.scaledY;
  }

  /**
   * Set background scale value Y
   *
   * @param {number} value The product layout background scale value Y
   * @return {LayoutBuilder}
   */
  setScaledY(value: number) {
    this.scaledY = value;

    return this;
  }

  /**
   * Get attributes
   *
   * @return {any} Returns the attributes
   */
  get Attributes() {
    return this.attributes;
  }

  /**
   * Set attributes
   *
   * @param {any} attributes Attributes
   * @return {ProductLayoutBuilder}
   */
  setAttributes(attributes: any) {
    this.attributes = attributes;

    return this;
  }

  /**
   * Build product layout from class data
   *
   * @return {ProductLayoutInterface} Returns Product layout object
   */
  build(): ProductLayoutInterface {
    return new ProductLayout(
      this.Layout,
      this.Id,
      this.Key,
      this.Width,
      this.Height,
      this.BackgroundColor,
      this.BackgroundImage,
      this.XPos,
      this.YPos,
      this.ScaledX,
      this.ScaledY,
      this.Layers,
      this.Attributes,
      this.CreationDate,
      this.ModificationDate,
      this.Published
    );
  }

  /**
   * Populate class data from data input
   *
   * @param {any} data Data object
   * @return {ProductLayoutBuilderInterface} Returns self
   */
  populate(data: any): ProductLayoutBuilderInterface {
    this.setId(data.id);
    this.setKey(data.key);
    // If not a number
    if (isNaN(parseFloat(data.layout))) {
      this.setLayout(data.layout);
    }
    this.setWidth(Number(data.width));
    this.setHeight(Number(data.height));
    this.setBackgroundColor(data.backgroundColor);
    this.setBackgroundImage(data.backgroundImage);
    this.setXPos(Number(data.xPos));
    this.setYPos(Number(data.yPos));
    this.setScaledX(Number(data.scaledX));
    this.setScaledY(Number(data.scaledY));
    this.setPublished(data.published);
    this.setCreationDate(data.creationDate);
    this.setModificationDate(data.modificationDate);
    this.setLayers(data.layers);
    this.setAttributes(data.attributes);

    return this;
  }

  /**
   * implement template layout to a new product layout object.
   *
   * @param {Layout} layout Layout
   * @return {ProductLayoutInterface} Returns the product layout class object
   */
  implement(layout: Layout):ProductLayoutInterface
  {
    let data:any = <any>{};
    data.layout = layout;
    data.key = layout.key;
    data.width = layout.width;
    data.height = layout.height;
    data.backgroundColor = layout.bgColor;
    data.backgroundImage = layout.backgroundImage;
    data.xPos = layout.xPos;
    data.yPos = layout.yPos;
    data.scaledX = layout.scaled;
    data.scaledY = layout.scaled;
    data.attributes = layout.attributes;
    data.layers = [];
    // Implement template layers as well
    let builder:ProductLayerBuilder;
    for (let layer of layout.layers) {
      builder = new ProductLayerBuilder();
      data.layers.push(builder.implement(layer));
    }

    return this.populate(data).build();
  }

  /**
   * Get layer from layout layers by ID
   *
   * @param {number} layerId Id of the layerId
   * @return {Layer} Returns the found layer
   */
  private getLayerById(layerId: number): Layer {
    let layout = this.Layout;
    if (layout.layers.length == 0) {
      return undefined;
    }
    for (let templateLayer of layout.layers) {
      if (templateLayer.id === layerId) {
        return templateLayer;
      }
    }
  }
}

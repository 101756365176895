import { Permittable } from './../common/permittable.type';
import { Permission } from './../common/permission.type';
import { PermissionFactory } from './../common/permission-factory';

export abstract class Abstract implements Permittable {

    protected permissionHolder: Array<Permission> = [];

    constructor(
      public permissions: any
    ) {
      this.setPermissions(permissions);
    }

    /**
     * Get all permissions
     *
     * @return {Array<Permission>} Return all permissions
     */
     public Permissions(): Array<Permission> {
       return this.permissionHolder;
     }

    /**
     * Can an action be executed on an element based on element permissions
     *
     * @param {string} key Name or Key of the permission
     * @return {boolean}
     */
    public can(key: string) : boolean {
      if (! this.permissionHolder[key]) {
        return false;
      }

      return this.permissionHolder[key].canChange();
    }

    /**
     * Return element permissions by key/name
     *
     * @param {string} key Name/Key of the permission
     * @return {Permission} Returns the permission model
     */
    public Permission(key: string) : Permission {
      if (! this.permissionHolder[key]) {
        return undefined;
      }

      return this.permissionHolder[key];
    }

    /**
     * Return element permissions by key/name
     *
     * @param {string} key Name/Key of the permission
     * @return {any} Returns the permission configurations
     */
    public getPermissionByKey(key: string) : any {
      if (! this.permissionHolder[key]) {
        return undefined;
      }

      return this.permissionHolder[key].Permission();
    }

    /**
     * Can current user view the element
     *
     * @return boolean
     */
    public canView(): boolean {
      return this.can('view');
    }

    /**
     * Can current user implement the element
     *
     * @return boolean
     */
    public canImplement(): boolean {
      return this.can('implement');
    }

    /**
     * Can current user delete the element
     *
     * @return boolean
     */
    public canDelete(): boolean {
      return this.can('delete');
    }

    /**
     * Returns the form item index
     *
     * @return {number} Returns form item index
     */
    public FormIndex(): number {
      return 0;
    }

    /**
     * Set permission to the holder from API permission data
     *
     * @param {any} permissions Permission data from the API
     */
    protected setPermissions(permissions: any): void {
      let permissionKeys = Object.keys(permissions);
      for (let key of permissionKeys) {
        let permission = PermissionFactory.createPermission(key, permissions[key]);
        this.permissionHolder[key] = permission;
      }
    }
  }

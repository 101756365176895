/// <reference types="crypto-js" />

import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class CryptoService {

  constructor() { }

  decrypt(encrypted: string, key: string):string {
console.log(encrypted);
    let params = JSON.parse(encrypted);
    //let decrypted = JSON.parse(CryptoJS.AES.decrypt(encrypted, key, {format: CryptoJSAesJson}).toString(CryptoJS.enc.Utf8));
    return ''/*decrypted*/;
    /*let splitted = encrypted.split(':', 3);
    if (splitted.length != 3) {
       throw new Error('An error occurred due an invalid hash');
    }

    var ciphertext = splitted[0];
    var salt = CryptoJS.enc.Hex.parse(splitted[1]);
    var iv = CryptoJS.enc.Hex.parse(splitted[2]);

    //var key2 = CryptoJS.PBKDF2(key, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999});

    var decrypted = CryptoJS.AES.decrypt(ciphertext, key, {iv: iv});

    return decrypted.toString(CryptoJS.enc.Utf8);*/
  }

  /**
   * Creates a HMAC of a request URL and hashes it with the given secret
   *
   * @param {string} url Request Url
   * @param {string} secret Cryptography key
   * @return {string} Returns the SHA256 encrypted HMAC token
   */
  public hmac(url: string, secret: string): string {
      return CryptoJS.HmacSHA256(url, secret).toString();
  }

  /**
   * Base64 encodes given string
   *
   * @param {string} value Plain text value
   * @return {string} Returns the base64 encoded string
   */
  public base64encode(value: string): string {
    let wordArray = CryptoJS.enc.Utf8.parse(value);
    let encoded = CryptoJS.enc.Base64.stringify(wordArray);

    return encoded;
  }
}
/*var CryptoJSAesJson = {
    stringify: function (cipherParams) {
        var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64), iv: undefined, s: undefined};
        if (cipherParams.iv) j.iv = cipherParams.iv.toString();
        if (cipherParams.salt) j.s = cipherParams.salt.toString();
        return JSON.stringify(j);
    },
    parse: function (jsonStr) {
        var j = JSON.parse(jsonStr);
        var cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
        if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
        if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
        return cipherParams;
    }
}*/

import { Permission } from './permission.type';

export class BoolPermission implements Permission
{
  permission: boolean;

  constructor(permission:any) {
    this.permission = <boolean>permission;
  }

  /**
   * Can an action be exuted based on the permission
   *
   * @return {boolean}
   */
  public canChange(): boolean {
    return this.Permission();
  }

  /**
   * Get permission value
   *
   * @return {boolean} Returns the permission value
   */
  public Permission(): boolean {
    return <boolean>this.permission;
  }
}

import { Aggregator } from './aggregator';
import { ChildIterator } from './child-iterator';
import { SelectableIterator } from './selectable-iterator';
import { Validatable } from './validatable.type';
import { Selectable } from './selectable.type';

export class ChildAggregator implements Aggregator {
  private children: Array<Validatable> = [];

  constructor(children: Array<Validatable>) {
    this.children = children;
  }

  /**
   * Create an iterator
   *
   * @return {Iterator} Returns the created iterator
   */
  public createIterator(onlySelectable: boolean = false): ChildIterator {
    return new ChildIterator(this.children);
  }

  /**
   * Create an iterator
   *
   * @return {Iterator} Returns the created iterator
   */
  public createSelectableIterator(): SelectableIterator {
      return new SelectableIterator(this.adaptToSelectables(this.children));
  }

  /**
   * Adapter method for picking all selectables from the validatables
   *
   * @param {any} itemd Itemd that are adapted to a selectables
   * @return {Array<Selectable>} Returns an array of selectables
   */
  protected adaptToSelectables(items: any): Array<Selectable> {
    let selectables = [];
    for (let item of items) {
      let selectable = <Selectable> item;
      if (typeof (selectable.FormId) !== "undefined") {
          selectables.push(selectable);
      }
    }

    return selectables;
  }
}

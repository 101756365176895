import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Animation } from './../../../models/common/animation';
import { environment } from './../../../../../environments/environment';

@Component({
  selector: 'sd-animation-form',
  templateUrl: './animation-form.component.html',
  styleUrls: ['./animation-form.component.scss']
})
export class AnimationFormComponent implements OnInit {
  @Input() delay: number = 0;
  @Input() duration: number = 1;
  @Input() infinite: boolean = false;
  @Input() animations: Array<Animation> = [];
  @Input() selected: Animation;
  @Input() min: number = 0;
  @Input() max: number = 15;
  @Input() disabled: boolean = false;

  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  public rangeValues: number[];
  public isAnimated: boolean = false;

  constructor() { }

  ngOnInit() {
    // Set defaults
    this.duration = (! this.duration) ? 1 : this.duration;
    this.delay = (! this.delay) ? 0 : this.delay;
    this.isAnimated = (this.selected !== undefined && this.selected !== null);
    this.rangeValues = this.Values();
  }

  /**
   * Handle change events on the slider
   *
   * @param {any} event Slider event
   */
  handleRangeChange(event: any) {
    let values = event.values;

    let delay = (! values[0]) ? this.delay : values[0];
    let duration = (! values[1]) ? this.duration : (values[1] - this.delay);

    this.emitChange(this.selected, delay, duration, this.infinite);
  }

  /**
   * Handler for animated toggle
   *
   * @param {any} event Event data
   * @return {void}
   */
  onAnimatedChange(event: any) {
    this.isAnimated = event.checked;
    if (! this.isAnimated) {
      this.emitChange(undefined, 0, 1, false);
    }
  }

  /**
   * Handler for infinite toggle
   *
   * @param {any} event Event data
   * @return {void}
   */
  onInfiniteChange(event: any) {
    this.infinite = event.checked;
  }

  /**
   * Returns the animation name as a string
   *
   * @return {string} Returns the animation name
   */
  getLabel(animation: Animation): string {
    return Animation[animation];
  }

  /**
   * Returns the range values,
   * First one for delay and second determines the duration
   *
   * @return {number[]} Returns an array of values
   */
  Values(): number[] {
      return [
        this.delay,
        (this.delay + this.duration),
      ];
  }

  /**
   * Emit event of changed values to listening components
   *
   * @param {Animation} animation Selected animation
   * @param {number} delay Animation delay
   * @param {number} duration Animation duration
   */
  protected emitChange(animation: Animation, delay: number, duration: number, infinite: boolean) {
    this.changed.emit({animation: animation, delay: delay, duration: duration, infinite: infinite});
  }
}

import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { SmartdesignerService } from './../../services/smartdesigner.service';
import { ElementService } from './../../services/element.service';
import { LayerService } from './../../services/layer.service';
import { ProductService } from './../../services/product.service';
import { SelectionService } from './../../services/selection.service';
import { ZoomService } from './../../services/zoom.service';
import { FormService } from './../../services/form.service';
import { Template } from './../../models/template/template';
import { ProductInterface } from './../../models/product/product.type';
import { ProductLayoutInterface } from './../../models/product/product-layout.type';
import { Configuration } from './../../models/configuration';
import { Layout } from './../../models/template/layout';
import { Observable, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { SaveProductDirective } from './../../directives/save-product.directive';

@Component({
  selector: 'sd-stage',
  templateUrl: './stage.component.html',
  styleUrls: ['./stage.component.scss']
})
export class StageComponent implements OnInit, OnDestroy {
  @Input() configuration: Configuration;
  @Input() alive: boolean = true;
  @Input() zoomLevel: number = 1;
  @Input() previewing: boolean = false;
  @ViewChild('stageArea') stageArea;

  isLoading: boolean = true;

  template: Template;
  product: ProductInterface;
  productSubscription: Subscription;

  constructor(
    private smartdesignerService: SmartdesignerService,
    private productService: ProductService,
    private selectionService: SelectionService,
    private formService: FormService,
    private zoomService: ZoomService
  ) { }

  ngOnInit() {
    this.productSubscription = this.productService.productChange
     .pipe(takeWhile(() => this.alive))
     .subscribe((product) => {
      this.setProduct(product);
      this.formService.resolveFormElements(this.productService.getSelectedLayout());
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    //prevent memory leak when component destroyed
    this.productSubscription.unsubscribe();
  }

  /**
   * Page selection handler for changing current page
   *
   * @param {ProductLayoutInterface} layout Selected layout/page
   * @return {void}
   */
  onPageSelection(layout: ProductLayoutInterface) {
    this.product.setSelectedLayout(layout);
    this.selectionService.select({}, layout);
    // Resolve form elements again
    this.formService.resolveFormElements(layout);
  }

  /**
   * Set template variable and product if not set yet.
   *
   * @param {Template} template Template model
   */
  setTemplate(template: Template) {
    this.template = template;
    if (this.product == undefined) {
      let product = this.smartdesignerService.initProduct(this.template, this.configuration);
      this.setProduct(product, true);
    }
  }

  /**
   * Set product variable
   *
   * @param {ProductInterface} product Product model
   * @param {boolean} toService Should the product be passed to product service as well. Default false.
   * @return {void}
   */
   setProduct(product: ProductInterface, toService: boolean = false) {
     this.product = product;
     if (toService) {
       this.productService.setProduct(product);
     }
     // call on product validators
     this.product.validate();
   }

   setZoom() {
     let zoomLevel = (this.zoomService.isZoomInitialized()) ? this.zoomService.Zoom() : 1;
     const transform = 'scale(' + zoomLevel + ') translate(-50%, 0%)';
     return {
       'transform': transform,
       'MsTransform': transform,
       'WebkitTransform': transform,
       'MozTransform': transform,
       'OTransform': transform,
       'transformOrigin': 'left top',
       'MsTransformOrigin': 'left top',
       'WebkitTransformOrigin': 'left top',
       'MozTransformOrigin': 'left top',
     };
   }
}

import { Component, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { ImageService } from './../../../services/image.service';
import { ConfigurationService } from './../../../services/configuration.service';
import { Unit } from './../../../../shared/unit';
import { StyleObject } from './../../../models/common/style-object.type';
import { environment } from './../../../../../environments/environment';

@Component({
  selector: 'sd-page-button',
  templateUrl: './page-button.component.html',
  styleUrls: ['./page-button.component.scss'],
})
export class PageButtonComponent implements OnInit {
  imageSrc: any;
  isLoading: boolean = false;
  @Input() alive: boolean = true;
  @Input() page: number = 1;
  @Input() caption: string = '';
  @Input() selected: boolean = false;

  constructor(
    private imageService: ImageService,
    private configurationService: ConfigurationService
  ) {
  }

  ngOnInit() {
    this.loadImage();
  }

  /**
   * Load image source from API
   */
  loadImage() {
      if (this.isLoading || ! this.configurationService.getConfiguration().id) {
        return ;
      }
      this.isLoading = true;
      this.imageService.getPreviewImage(this.page, this.configurationService.getConfiguration(), 'small')
        .subscribe(resp => {
          this.imageSrc = resp.body;
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
          console.log(error);
        }
      );
  }
}

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from "@angular/core";
import { Subscription } from "rxjs";
import { takeWhile } from "rxjs/operators";
import {
  ScrollToService,
  ScrollToConfigOptions
} from "@nicky-lenaers/ngx-scroll-to";
import { SelectionService } from "./../../services/selection.service";
import { FormService } from "./../../services/form.service";
import { ImageToolService } from "./../../services/image-tool.service";
import { Formable } from "./../../models/common/formable.type";
import { Selectable } from "./../../models/common/selectable.type";
import { Animation } from "./../../models/common/animation";
import { FormableType } from "./../../models/common/formable-type";
import { FormableAware } from "./../../models/common/formable-type.decorator";

@Component({
  selector: "sd-form",
  templateUrl: "./element-form.component.html",
  styleUrls: ["./element-form.component.scss"]
})
@FormableAware
export class ElementFormComponent implements OnInit {
  @Input() formables: Formable[] = [];
  protected selected: Selectable;
  @Input() disabled: boolean = false;
  @Input() alive: boolean = true;
  @Input() formGroups: any;
  @Input() selectedTab: string = "elements";
  @Input() selectedBgColor: string = "grey";

  protected formBackgroundColors: any = [
    { id: "white", color: "#ffffff" },
    { id: "grey", color: "#929292" },
    { id: "black", color: "#000000" }
  ];

  protected isGrouplessExpanded: boolean = true;

  @Output() changed: EventEmitter<number> = new EventEmitter<number>();
  selectionSubscription: Subscription;

  constructor(
    private selectionService: SelectionService,
    private formService: FormService,
    private imageToolService: ImageToolService,
    private scrollToService: ScrollToService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // Subscription for current selection
    this.selectionSubscription = this.selectionService.selectionChange
      .pipe(takeWhile(() => this.alive))
      .subscribe(data => {
        if (data) {
          this.handleSelection(data);
        }
      });
  }

  /**
   * Returns the currently selected form background color
   *
   * @return {string}
   */
  FormBackgroundColor(): string {
    if (this.selectedTab != "elements") {
      return this.formBackgroundColors[0].color;
    }
    for (let color of this.formBackgroundColors) {
      if (color.id == this.selectedBgColor) {
        return color.color;
      }
    }

    return this.formBackgroundColors[0].color;
  }

  /**
   * Returns the currently selected form background color
   *
   * @return {string}
   */
  SelectedBackgroundColorId(): string {
    if (this.selectedTab != "elements") {
      return this.formBackgroundColors[0].id;
    }
    for (let color of this.formBackgroundColors) {
      if (color.id == this.selectedBgColor) {
        return color.id;
      }
    }

    return this.formBackgroundColors[0].id;
  }

  /**
   * Get form groups
   *
   * @return {any} Returns form groups shown in the form
   */
  FormGroups() {
    return this.formGroups;
  }

  /**
   * Returns formables by form identifier
   *
   * @param {string} formId Form identifier
   * @return {Formable[]} Returns the formables
   */
  getFormablesByFormId(formId: string) {
    let items = [];
    for (let item of this.FormElements()) {
      let selectable = <Selectable>(<any>item);
      if (
        (!formId &&
          (!selectable.FormId() ||
            this.getFormGroupByFormId(selectable.FormId()) == undefined)) ||
        selectable.FormId() == formId
      ) {
        items.push(item);
      }
    }

    return items;
  }

  /**
   * Returns form group by form identifier
   *
   * @param {string} formId Form identifier
   * @return {any} Returns the form group
   */
  getFormGroupByFormId(formId: string) {
    let items = [];
    for (let item of this.FormGroups()) {
      if (item.identifier == formId) {
        return item;
      }
    }

    return undefined;
  }

  /**
   * Set groupless form group opened/closed
   *
   * @param {boolean} isExpanded Is panel expanded?
   * @return {void}
   */
  setGrouplessExpanded(isExpanded: boolean) {
    this.isGrouplessExpanded = isExpanded;
    this.selectedTab = "elements";
  }

  /**
   * Event handler on content changed
   *
   * @param {any} content Element content
   * @param {Formable} formable Formable that is updated
   * @return {void}
   */
  onContentChanged(content: any, formable: Formable) {
    formable.update(content);
    // Emit an event of structure change
    this.changed.emit(Date.now());
  }

  /**
   * Event handler on text element focus changed
   *
   * @param {boolean} event Element focus value
   * @param {Formable} formable Formable that focused
   * @return {void}
   */
  onFocus(event: boolean, formable: Formable) {
    if (event) {
      let selectable = <Selectable>(<any>formable);
      this.selectionService.select({}, selectable);
    }
  }

  /**
   * Does form element have focus
   *
   * @param {Formable} formable Formable
   * @return {boolean}
   */
  isSelected(formable: Formable) {
    return this.selected == <Selectable>(<any>formable);
  }

  /**
   * Handler for changing form background color
   *
   * @param {any} event Event data
   * @param {string} colorId Identifier of the selected color
   * @return {void}
   */
  onBgColorSelection(event: any, colorId: string) {
    this.selectedBgColor = colorId;
  }

  /**
   * Listener for any change in formables.
   * Emits an timestamp of change to listening components.
   *
   * @param {any} event Event data
   * @param {Formable} formable Formable that is updated
   * @return {void}
   */
  onChanged(event: any, formable: Formable) {
    formable.validate();
    this.changed.emit(Date.now());
  }

  /**
   * Listener for any change in formable's animations.
   *
   * @param {any} event Event data
   * @param {Formable} formable Formable that is updated
   * @return {void}
   */
  onAnimationChanged(event: any, formable: Formable) {
    let animation: Animation = event.animation;
    let delay: number = event.delay;
    let duration: number = event.duration;
    let infinite: boolean = event.infinite;
    // Update animation settings
    formable.updateAnimation(animation, delay, duration, infinite);

    formable.validate();
    this.changed.emit(Date.now());
  }

  /**
   * Handler for tab selection
   *
   * @param {any} event Click Event
   * @param {string} tab Tab name
   * @return {void}
   */
  onTabSelection(event: any, tab: string) {
    this.selectedTab = tab;
    this.selected = undefined;
    // Close image tool forms
    this.imageToolService.close();
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Should given tab be shown on the sidenav
   *
   * @param {string} tab Tab name
   * @return {boolean} Returns true if text/email/image elements found
   */
  showTab(tab: string) {
    let elements = this.FormElementsByTab(tab);

    return elements.length > 0;
  }

  /**
   * Is there groupless elements in the formables
   *
   * @return {boolean}
   */
  protected hasGrouplessElements() {
    return this.getFormablesByFormId(undefined).length > 0;
  }

  /**
   * Get form elements that actually have a form
   *
   * @return {Formable[]}
   */
  protected FormElements(): Formable[] {
    return this.FormElementsByTab(this.selectedTab);
  }

  /**
   * Get form elements that actually have a form by tab name
   *
   * @param {string} tab Tab name
   * @return {Formable[]}
   */
  protected FormElementsByTab(tab: string): Formable[] {
    let items = [];
    let formTypes = [FormableType.text, FormableType.email, FormableType.image, FormableType.qrcode];
    let bgTypes = [FormableType.layout /*, FormableType.layer*/];
    if (!this.formables) {
      return items;
    }
    for (let item of this.formables) {
      if (tab == "elements" && formTypes.includes(item.getFormableType())) {
        items.push(item);
      } else if (
        tab == "background" &&
        bgTypes.includes(item.getFormableType())
      ) {
        items.push(item);
      }
    }

    return items;
  }

  /**
   * Handle selection
   *
   * @param {Selectable} selectable The selected selectable (element)
   * @return {void}
   */
  protected handleSelection(selectable: Selectable) {
    let time = 1;
    let formGroup = selectable.FormId()
      ? this.getFormGroupByFormId(selectable.FormId())
      : undefined;
    let formable = <Formable>(<any>selectable);
    if (!formGroup) {
      if (
        typeof formable.getFormableType !== "undefined" &&
        formable.getFormableType() == FormableType.layout
      ) {
        this.selectedTab = "background";
        return;
      }
      this.setGrouplessExpanded(true);
    } else {
      /*
       * Expansion takes time, so to make focus work
       * need to set the selected (focus) after expansion animation
       */
      time = !formGroup.opened ? 2000 : time;
      formGroup.opened = true;
      this.selectedTab = "elements";
    }

    setTimeout(() => {
      this.selected = selectable;
      if (formable.getFormableType() !== FormableType.image) {
        this.imageToolService.close();
      }
      let selected = <any>this.selected;
      this.triggerScrollTo("item-" + selected.id);
      this.changeDetectorRef.detectChanges();
    }, time);
  }

  /**
   * Trigger scrolling to destination element
   *
   * @param {string} destination Id attribute of the destination element
   * @return {void}
   */
  protected triggerScrollTo(destination: string) {
    const config: ScrollToConfigOptions = {
      target: destination,
      duration: 650,
      easing: "easeOutElastic",
      offset: -50
    };

    this.scrollToService.scrollTo(config);
  }
}

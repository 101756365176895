import { Permission } from './permission.type';

export class RepositionPermission implements Permission
{
  permission: any;

  constructor(permission:any) {
    this.permission = permission;
  }

  /**
   * Can an action be exuted based on the permission
   *
   * @return {boolean}
   */
  public canChange(): boolean {
    return this.canReposition() || this.canRescale();
  }

  /**
   * Get permission value
   *
   * @return {any} Returns the permission value
   */
  public Permission(): any {
    return this.permission;
  }

  /**
   * Can rescale
   *
   * @return bool
   */
  public canRescale(): boolean {
    return !! this.Permission().rescale;
  }

  /**
   * Can reposition
   *
   * @return bool
   */
  public canReposition(): boolean {
    return !! this.Permission().reposition;
  }
}

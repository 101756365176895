import { Component, OnInit, Input, Output, ViewChild, ElementRef, NgZone, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { ProductImageElement } from './../../../models/product/element/product-image-element';
import { ProductService } from './../../../services/product.service';
import { ImageService } from './../../../services/image.service';
import { ConfigurationService } from './../../../services/configuration.service';
import { NotificationService } from './../../../../services/notification.service';
import { environment } from './../../../../../environments/environment';
import { Image } from './../../../models/common/image.type';
import { ClientImage } from './../../../models/common/client-image.type';
import { ResizePermission } from './../../../models/common/resize-permission';
import { ResolutionPermission } from './../../../models/common/resolution-permission';
import { RepositionPermission } from './../../../models/common/reposition-permission';
import { ClientApiPermission } from './../../../models/common/client-api-permission';
import { Unit } from './../../../../shared/unit';
import { ProductElementBuilder } from './../../../models/product/builder/product-element-builder';
import { ImageToolComponent } from './../image-tool/image-tool.component';
import {AttributeInterpreter} from '../../../models/common/attribute-interpreter';

@Component({
  selector: 'sd-image-form',
  templateUrl: './image-form.component.html',
  styleUrls: ['./image-form.component.scss']
})
export class ImageFormComponent implements OnInit {
  @Input() element: ProductImageElement;
  @Input() disabled: boolean = false;
  @Input() focus: boolean = false;

  @Output() content: EventEmitter<Image> = new EventEmitter<Image>();
  @Output() changed: EventEmitter<ProductImageElement> = new EventEmitter<ProductImageElement>();
  @Output() onFocus: EventEmitter<boolean> = new EventEmitter<boolean>();

  private attributeInterpreter: AttributeInterpreter;

  showImage: boolean = true;
  imageWidth: number;
  imageHeight: number;

  @ViewChild('tool') tool: ImageToolComponent;

  constructor(
    private elRef: ElementRef,
    private zone: NgZone,
    private productService: ProductService,
    private imageService: ImageService,
    private notificationService: NotificationService,
    private configurationService: ConfigurationService,
    private changeDetectionRef: ChangeDetectorRef,
  ) {
    this.attributeInterpreter = new AttributeInterpreter();
  }

  /**
   * Set defaults on initialize
   */
  ngOnInit() {
    if (this.element.width === 0 && this.element.height === 0) {
      this.showImage = false;
      this.imageWidth = (this.element.defaultElement) ? this.element.defaultElement.width : 10;
      this.imageHeight = (this.element.defaultElement) ? this.element.defaultElement.height : 10;
    } else {
      this.imageWidth = this.element.width;
      this.imageHeight = this.element.height;
    }
  }

  /**
   * Returns parameters that should be passed with the image upload POST request
   *
   * @return {any} Returns parameters for POST request
   */
  UploadParameters(): any {
    return {
      'template': this.productService.getTemplate().id,
      'resource': (this.element.id) ? this.element.id : '',
      'default': (this.element.defaultElement) ? this.element.defaultElement.id : '',
      'width': this.element.width,
      'height': this.element.height,
    };
  }

  /**
   * Returns parameters that should be passed with the image upload POST request
   *
   * @return {any} Returns parameters for POST request
   */
  ClientAPIParameters(): any {
    return { 'resource': this.element.defaultElement.id };
  }

  /**
   * Is client service API image selectiong shown
   *
   * @return {boolean} Returns true if upload from client API is allowed
   */
  showClientServiceSelection(): boolean {
    let permission: ClientApiPermission = this.getClientApiPermission()

    return permission.canChange();
  }

  /**
   * Is client service API image selectiong shown
   *
   * @return {boolean} Returns true if upload from client API is allowed
   */
  onlyClientServiceSelection(): boolean {
    if (! this.showClientServiceSelection()) {
      return false;
    }
    let permission: ClientApiPermission = this.getClientApiPermission();

    return permission.onlyApi();
  }

  /**
   * On focus change handler
   *
   * @param {boolean} event Focus change event data
   */
  onFocusChange(event: boolean) {
    this.focus = event;
    if (! this.showImage && this.focus == true) {
      this.onShowChange({'checked': true});
    }

    this.onFocus.emit(this.focus);
  }

  /**
   * On successful upload -handler
   * Updates the element image
   *
   * @param {any} event Data from file upload
   * @return {void}
   */
  onSuccessItem(event: any) {
    let element = ProductElementBuilder.build(event);
    if (element instanceof ProductImageElement) {
      this.updateImage(element);
    }
  }

  /**
   * On failed upload -handler
   * Pushes the error to the notification service
   *
   * @param {any} event Error data
   * @return {void}
   */
  onErrorItem(event: any) {
    let errorObj = {
      'title': event.message,
      'code': event.code,
      'messages': [],
    }
    for (let exception of event.errors) {
      errorObj.messages.push({'code': exception.code, 'title': exception.field, 'message': exception.message});
    }
    this.notificationService.error(errorObj);
  }

  /**
   * On show image slide -toggle handler
   *
   * @param {Event} event Change event object
   * @return {void}
   */
  onShowChange(event: any) {
    this.showImage = event.checked;
    if (! this.showImage) {
      this.imageWidth = this.element.width;
      this.imageHeight = this.element.height;

      this.element.width = 0;
      this.element.height = 0;
      this.onFocusChange(false);
    } else {
      this.element.width = this.imageWidth;
      this.element.height = this.imageHeight;
    }
    // Emit changed Event
    this.changed.emit(this.element);
  }

  /**
   * Returns the upload URI for an element file
   *
   * @return {string} Returns the upload URI
   */
  public UploadUri(): string {
    return this.imageService.getUploadUri(this.getUpdateImageId(), this.configurationService.getConfiguration());
  }

  /**
   * Clear image handler
   *
   * @param {Event} event
   */
  onClearImage(event: Event) {
    this.element.clearImage();
    // Emit changed Event
    this.changed.emit(this.element);
  }

  /**
   * Scale handler that is called on scale change
   *
   * @param {any} event Slider event
   */
  onScaleChange(event: any) {
    this.element.scaledX = event.value;
    // Setting scale on Y-axis to the same as X-axis does not work as expected.
    // Todo: calculate correct scale value for Y-axis
    this.element.scaledY = event.value;

    if (this.element.canResize()) {
      this.element.width = this.element.scaledX * this.element.image.dimensions.width;
      this.element.width = (this.element.width > this.element.getMaxWidth()) ? this.element.getMaxWidth() : this.element.width;
      this.element.width = (this.element.width < this.element.getMinWidth()) ? this.element.getMinWidth() : this.element.width;

      this.element.height = this.element.scaledY * this.element.image.dimensions.height;
      this.element.height = (this.element.height > this.element.getMaxHeight()) ? this.element.getMaxHeight() : this.element.height;
      this.element.height = (this.element.height < this.element.getMinHeight()) ? this.element.getMinHeight() : this.element.height;
    }
    // Emit changed Event
    this.changed.emit(this.element);
  }

  /**
   * On backround image position change handler
   *
   * @param {any} event Position event params
   * @return {void}
   */
  onPositionChange(event:any) {
      this.element.xPos = event.x;
      this.element.yPos = event.y;
      // Emit changed Event
      this.changed.emit(this.element);
  }

  /**
   * Handler that is envoked on client image selection.
   *
   * @param {ClientImage} event Selected client image
   * @return {void}
   */
  onClientImageSelection(event:ClientImage) {
    let uploadParams = this.UploadParameters();
    this.imageService.makeClientImageSelection(
      this.getUpdateImageId(),
      event,
      uploadParams.resource,
      uploadParams.template,
      uploadParams.default,
      uploadParams.width,
      uploadParams.height,
      this.configurationService.getConfiguration()
    ).subscribe(
      response => {
        // create an image element from the Response
        this.onSuccessItem(response);
      }
    );
  }

  /**
   * Update image content
   *
   * @param {ProductImageElement} image Image element
   */
  updateImage(image: ProductImageElement) {
      this.zone.run(()=> {
        this.content.emit(image);
      });
      // For view, that there are changes
      this.changeDetectionRef.detectChanges();
  }

  /**
   * Is the toggle switch for image visibility shown
   *
   * @return {boolean}
   */
  showVisibilitySwitch(): boolean {
    if (! this.canResize()) {
      return false;
    }
    let resizePermission: ResizePermission = (this.element.defaultElement) ? <ResizePermission>this.element.defaultElement.Permission('resize') : undefined;

    let minHeight = (resizePermission) ? resizePermission.getMinHeight() : 0;
    let minWidth = (resizePermission) ? resizePermission.getMinWidth() : 0;

    return (minHeight <= 0 && minWidth <= 0);
  }

  /**
   * Can user scale the image
   *
   * @return {boolean}
   */
  canScale(): boolean {
    return this.element.canScale();
  }

  /**
   * Can user reposition the element image
   *
   * @return {boolean}
   */
  canReposition(): boolean {
    return this.element.canReposition();
  }

  /**
   * Can user resize the image area while scaling the image
   *
   * @return {boolean}
   */
  canResize(): boolean {
    if (! this.canScale()) {
      return false;
    }
    let resizePermission: ResizePermission = (this.element.defaultElement) ? <ResizePermission>this.element.defaultElement.Permission('resize') : undefined;

    return (resizePermission) ? resizePermission.canChange() : false;
  }

  /**
   * Returns the maximum scale percentage for the image
   *
   * @return {boolean}
   */
  getMaxScale(): number | undefined {
    let scaleMax = undefined;
    if (! this.element.image) {
      return scaleMax;
    }

    if (this.element.FitArea() || this.element.canResize()) {
      let maxHeight = (this.element.canResize() ? this.element.getMaxHeight() : (this.element.FitArea() ? this.element.height : undefined));
      let scaleMaxY = (maxHeight) ? maxHeight / this.element.image.dimensions.height : undefined;

      let maxWidth = (this.element.canResize() ? this.element.getMaxWidth() : (this.element.FitArea() ? this.element.width : undefined));
      let scaleMaxX = (maxWidth) ? maxWidth / this.element.image.dimensions.width : undefined;

      if (scaleMaxY && scaleMaxX) {
        scaleMax = (scaleMaxY > scaleMaxX) ? scaleMaxX : scaleMaxY;
      } else if (scaleMaxY && ! scaleMaxX) {
        scaleMax = scaleMaxY;
      } else if (! scaleMaxY && scaleMaxX) {
        scaleMax = scaleMaxX;
      }
    }
    // If still no maximum scale value. The maximum must be counted from DPI and real size
    if (! scaleMax) {
        let lowestDPI = 72;
        let resolution = this.element.OriginalDpi();
        scaleMax = ((resolution / lowestDPI) < 2) ? 2 : (resolution / lowestDPI);
    }

    return scaleMax;
  }

  /**
   * Returns the minimum scale percentage for the image
   *
   * @return {boolean}
   */
  getMinScale(): number {
    let scaleMin: number = 0.01;
    if (! this.element.image) {
      return scaleMin;
    }
    if (this.element.FitArea() || this.element.canResize()) {

      let minHeight = (this.element.canResize()) ? this.element.getMinHeight() : 0;
      let minY = (minHeight) ? minHeight / this.element.image.dimensions.height : 0;

      let minWidth = (this.element.canResize()) ? this.element.getMinWidth() : 0;
      let minX = (minWidth) ? minWidth / this.element.image.dimensions.width  : 0;

      if (minY && minX) {
        scaleMin = (minY < minX) ? minY : minX;
      } else if (minY && ! minX) {
        scaleMin = minY;
      } else if (! minY && minX) {
        scaleMin = minX;
      }
    }

    return scaleMin;
  }

  /**
   * Get element errors
   *
   * @return {Array<any>}
   */
  getErrors(): Array<any> {
    return this.element.getErrors();
  }

  /**
   * Get element warnings
   *
   * @return {Array<any>}
   */
  getWarnings(): Array<any> {
    return this.element.getWarnings();
  }

  /**
   * Return element's possible help text
   *
   * @return {string} Help text
   */
  getHelpText(): string {
      return this.element.getHelpText();
  }

  /**
   * Returns the image ID if not the same as the default image ID
   *
   * @return {number} Returns image ID for upload/update
   */
  protected getUpdateImageId(): number {
    let updateImageId = undefined;
    // If product image is the same as the template layout's image, create a new image instead of updating the default image
    if (this.element.image !== undefined && this.element.image.id !== undefined) {
        updateImageId = (this.element.defaultElement && this.element.image.id === this.element.defaultElement.image.id) ? undefined : this.element.image.id;
    }

    return updateImageId;
  }

  /**
   * Returns the client api permission object if is set
   *
   * return {ClientApiPermission}
   */
  protected getClientApiPermission(): ClientApiPermission
  {
    let permission: ClientApiPermission = (this.element.defaultElement) ? <ClientApiPermission>this.element.defaultElement.Permission('clientApi') : new ClientApiPermission({});

    return permission;
  }
}

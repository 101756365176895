export enum ValidatorMessageType {
  error = 1,
  warning = 2
}

export interface ValidatorMessage
{
  type: ValidatorMessageType;
  key: string;
  label: string;
  message: string;
}
export interface Validator {

  /**
   * Run validation in the class
   *
   * @return {boolean} Returns boolean based on validness
   */
  run(): boolean;

  /**
   * Returns validator errors
   *
   * @return {Array<ValidatorMessage>}
   */
  Errors(): Array<ValidatorMessage>;

  /**
   * Returns validator warnings
   *
   * @return {Array<ValidatorMessage>}
   */
  Warnings(): Array<ValidatorMessage>;

  /**
   * Returns all validator messages, warnings and errors
   *
   * @return {Array<ValidatorMessage>} Returns all validator messages
   */
  Messages(): Array<ValidatorMessage>;
}

import { Layout } from './layout';
import { Abstract } from './abstract';
import { environment } from './../../../../environments/environment';

export class Template extends Abstract {

    constructor(
      public id: string,
      public key: string,
      public name: string,
      public groupId: string,
      public description: string,
      public format: string,
      public colorSpace: string,
      public language: string,
      public published: boolean,
      public creationDate: number,
      public modificationDate: number,
      public clientId: string,
      public permissions: any,
      public layouts: Array<Layout> = [],
      public attributes: any,
      public availableFonts: any,
      public forms: any,
      public instruction: string
    ) {
      super(permissions);
    }

    /**
     * Set template layouts
     *
     * @param {Array<Layout>} layouts Layouts in an array
     * @return void
     */
    setLayouts(layouts: Array<Layout>): void {
        this.layouts = layouts;
    }

    /**
     * Can current user implement the template
     *
     * @return boolean
     */
    canImplement(): boolean {
      return this.can('implement');
    }

    /**
     * Returns the preview URL for this page
     *
     * @param {number} page Page number (which layout is previewed)
     * @return {string} Returns the preview URL
     */
    getPreviewUrl(page:number): string {
      page = (! page) ? 1 : page;

      return environment.apiBaseUrl + 'templates/' + this.id + '/' + page;
    }

    /**
     * Get template bleed width
     *
     * @return {number} Returns the bleed width
     */
    Bleed(): number {
        let bleed = 0;
        if (! this.attributes.printProperties) {
          return bleed;
        }

        return (this.attributes.printProperties.bleed) ? this.attributes.printProperties.bleed : bleed;
    }

    /**
     * Get template mediabox width (one side)
     *
     * @return {number} Returns the mediabox width
     */
    Media(): number {
        let media = 0;
        if (! this.attributes.printProperties) {
          return media;
        }

        return (this.attributes.printProperties.media) ? this.attributes.printProperties.media : media;
    }

    /**
     * Returns all available template font families
     *
     * @return {any}
     */
    AvailableFontFamilies()
    {
      return this.availableFonts;
    }
}

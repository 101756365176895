import { ProductTextElement } from './product-text-element';
import { EmailElementInterface } from './../../template/element/email-element.type';
import { ProductElement } from './product-element.type';
import { ProductEmailElementInterface } from './product-email-element.type';
import { ProductEmailElementOut } from './product-email-element-out.type';

export class ProductEmailElement extends ProductTextElement implements ProductEmailElementInterface {

    constructor(
      public defaultElement: EmailElementInterface,
      public id: number,
      public key: string,
      public width: number,
      public height: number,
      public content: string,
      public font: string,
      public attributes: any
    ) {
        super(defaultElement, id, key, width, height, content, 'email', attributes);
    }

    /**
     * Get product element data.
     * This data is used for saving this object to the API.
     *
     * @return {ProductEmailElementOut} Returns the product element data for the API
     */
    getData(): ProductEmailElementOut {
      let data:ProductEmailElementOut = <ProductEmailElementOut>{};
      data.id = this.id;
      data.width = this.width;
      data.height = this.height;
      data.attributes = this.attributes;
      data.defaultElement = (this.defaultElement !== undefined) ? this.defaultElement.id : undefined;
      data.content = this.content;
      data.font = this.font;
      data.elementType = this.elementType;

      return data;
    }

    /**
     * Generates a HTML template for the element from the content data
     *
     * @return {string} Returns the HTML
     */
    public getContentHtml(): string {
      return this.content;
    }
}

import { ProductInterface } from './../product.type';
import { Product } from './../product';
import { ProductBuilderInterface } from './product-builder.type';
import { Template } from './../../template/template';
import { Layout } from './../../template/layout';
import { ProductLayoutInterface } from './../product-layout.type';
import { ProductLayout } from './../product-layout';
import { TemplateBuilder } from './../../template/builder/template-builder';
import { ProductLayoutBuilder } from './../builder/product-layout-builder';

export class ProductBuilder implements ProductBuilderInterface {
  public id: string;
  public key: string;
  public template: Template;
  public published: boolean;
  public creationDate: number;
  public modificationDate: number;
  public clientId: string;
  public layouts: Array<ProductLayoutInterface>;

  constructor() {}

  /**
   * Get Id
   *
   * @return {string} Returns the product Id
   */
  get Id() {
    return this.id;
  }

  /**
   * Set Id
   *
   * @param {string} id Product Id
   * @return {ProductBuilder}
   */
  setId(id: string) {
    this.id = id;
    return this;
  }

  /**
   * Get key
   *
   * @return {string} Returns the product key
   */
  get Key() {
    return this.key;
  }

  /**
   * Set key
   *
   * @param {string} key Product key
   * @return {ProductBuilder}
   */
  setKey(key: string) {
    this.key = key;
    return this;
  }

  /**
   * Get Client Id
   *
   * @return {string} Returns the product Client Id
   */
  get ClientId() {
    return this.clientId;
  }

  /**
   * Set Client Id
   *
   * @param {string} id Product Client Id
   * @return {ProductBuilder}
   */
  setClientId(id: string) {
    this.clientId = id;
    return this;
  }

  /**
   * Get template
   *
   * @return {Template} Returns the product template
   */
  get Template() {
    return this.template;
  }

  /**
   * Set template
   *
   * @param {any} template Template
   * @return {ProductBuilder}
   */
  setTemplate(template: any) {
    if (template instanceof Template) {
      this.template = template;
    } else {
      let builder = new TemplateBuilder();
      this.template = builder.populate(template).build();
    }
    return this;
  }

  /**
   * Is Published
   *
   * @return {boolean} Returns Published value
   */
  get Published() {
    return this.published;
  }

  /**
   * Set Published
   *
   * @param {boolean} published Published value
   * @return {ProcuctBuilder}
   */
  setPublished(published: boolean) {
    this.published = published;
    return this;
  }

  /**
   * Get Creation Date
   *
   * @return {number} Returns the Creation date timestamp
   */
  get CreationDate() {
    return this.creationDate;
  }

  /**
   * Set Creation Date
   *
   * @param {number} date CreationDate
   * @return {ProcuctBuilder}
   */
  setCreationDate(date: number) {
    this.creationDate = date;
    return this;
  }

  /**
   * Get modification date
   *
   * @return {number} Returns the modification date timestamp
   */
  get ModificationDate() {
    return this.modificationDate;
  }

  /**
   * Set Modification date
   *
   * @param {number} date Modification date
   * @return {ProcuctBuilder}
   */
  setModificationDate(date: number) {
    this.modificationDate = date;
    return this;
  }

  /**
   * Get layouts
   *
   * @return {Array<ProductLayoutInterface>} Returns the product layouts
   */
  get Layouts() {
    return this.layouts;
  }

  /**
   * Set layouts
   *
   * @param {Array<any>} layouts Layouts
   * @return {ProcuctBuilder}
   */
  setLayouts(layouts: Array<any>) {
    let productLayouts = [];
    for (let layout of layouts) {
      if (layout instanceof ProductLayout) {
        productLayouts.push(layout);
      } else {
        layout.layout = this.getLayoutById(layout.layout);
        let builder = new ProductLayoutBuilder();
        productLayouts.push(builder.populate(layout).build());
      }
    }
    this.layouts = productLayouts;
    return this;
  }

  /**
   * Build product from class data
   *
   * @return {ProductInterface} Returns Product object
   */
  build(): ProductInterface {
    return new Product(
      this.Template,
      this.Id,
      this.Key,
      this.ClientId,
      this.Layouts,
      this.CreationDate,
      this.ModificationDate,
      this.Published,
    );
  }

  /**
   * Populate class data from data input
   *
   * @param {any} data Data object
   * @return {ProductBuilderInterface} Returns self
   */
  populate(data: any): ProductBuilderInterface {
    this.setId(data.id);
    this.setKey(data.key);
    this.setTemplate(data.template);
    this.setPublished(data.published);
    this.setCreationDate(data.creationDate);
    this.setModificationDate(data.modificationDate);
    this.setClientId(data.client);
    this.setLayouts(data.layouts);

    return this;
  }

  /**
   * implement template to a new product object.
   *
   * @param {Template} template Template
   * @return {ProductInterface} Returns the product class object
   */
  implement(template: Template):ProductInterface
  {
    let data:any = <any>{};
    data.template = template;
    data.clientid = template.clientId;
    data.layouts = [];
    // Implement template layouts as well
    let builder:ProductLayoutBuilder;
    for (let layout of template.layouts) {
      builder = new ProductLayoutBuilder();
      data.layouts.push(builder.implement(layout));
    }

    return this.populate(data).build();
  }

  private getLayoutById(layoutId: number): Layout {
    let template = this.Template;
    if (template.layouts.length == 0) {
      return undefined;
    }
    for (let templateLayout of template.layouts) {
      if (templateLayout.id === layoutId) {
        return templateLayout;
      }
    }
  }
}

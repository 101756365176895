export class JsonToHtmlConverter
{
    /**
     * Converts a JSON object with HTML element data to HTML markup
     *
     * @param {any} json Object holding HTML elements and attributes
     * @param {any} colors Optional object holding colors that need to be converted from pantone or cmyk to rgb.
     * @param {number} formScale Optional value that is used to scale font size values for the form view
     * @return {string} Returns HTML markup
     */
    public static convert(json: any, colors?:any, formScale?:number): string {
      let templateHtml = '';
      if (! json || ! json.tag || ! json.children) {
        return templateHtml;
      }
      if (json.tag === 'html' && json.children.length === 1) {
        let body = json.children[0];
        for (let childIndex in body.children) {
          let child = body.children[childIndex];
          let childHtml = this.getChildHtml(child, colors, formScale);
          templateHtml += childHtml;
        }
      }

      return templateHtml;
    }

    /**
     * Returns template data children HTML
     *
     * @param {any} element Child object
     * @param {any} colors Optional object holding colors that need to be converted from pantone or cmyk to rgb.
     * @param {number} formScale Optional value that is used to scale font size values for the form view
     * @return {string} Returns HTML
     */
    private static getChildHtml(element: any, colors?:any, formScale?:number): string {
      let html = '';
      if (! element || element.length === 0) {
        return html;
      }
      let tag = element.tag;
      if (tag === 'br') {
        return '<br>';
      }

      let styles = (element.style !== undefined) ? this.parseStyles(element.style, colors, formScale) : undefined;
      // Create the tag
      html = html + ((tag) ? '<' + tag + (styles !== undefined ? ' style="' + styles + '"' : '') + '>' : '');
      // Add innerHTML if set
      html = html + (element.html !== undefined ? element.html : '');

      if (element.children && element.children.length > 0) {
        for (let childIndex in element.children) {
          let child = element.children[childIndex];
          html += this.getChildHtml(child, colors, formScale);
        }
      }
      html = html + ((tag) ?'</' + tag + '>' : '');

      return html;
    }

    /**
     * Parse style object to a style attribute string
     *
     * @param {any} style Style object
     * @param {any} colors Optional object holding colors that need to be converted from pantone or cmyk to rgb.
     * @param {number} formScale Optional value that is used to scale font size values for the form view
     * @return {string} Returns the attribute string
     */
    private static parseStyles(style: any, colors?: any, formScale?: number): string {
      let styleAttribute = '';
      for (let key in style) {
        if (! style[key] || style[key] === '') {
          continue;
        }
        let value = (colors.length > 0 && (key == 'color' || key == 'backgroundColor')) ? this.getColorInRgb(colors, style[key]) : style[key];
        // Add form scale if used
        value = (formScale && ['fontSize', 'letterSpacing'].includes(key)) ? style[key].replace(parseFloat(style[key]), (parseFloat(style[key]) * formScale)) : style[key];

        styleAttribute += this.camelCase2Dash(key) + ':' + value + ';';
      }

      return styleAttribute;
    }

    /**
     * Convert camel cased text to dashed text
     *
     * @param {string} str CamelCased text
     * @return {string} Returns dashed text
     */
    private static camelCase2Dash(str: string): string {
      let dashed = !str ? undefined : str.replace(/([A-Z])/g, function (g) { return '-' + g[0].toLowerCase() });

      return dashed;
    }

    /**
     * Get selectable colors for the font color. No value == any color
     *
     * @param {string} colorspace Parameter for color space [rgb, cmyk, pantone]
     * @return {any}
     */
    private static getColorInRgb(colors:any, color: string): any {
      let values = [];
      for (let item of colors) {
        if (item['cmyk'] == color) {
          return item['rgb'];
        }
        if (item['pantone'] == color) {
          return item['rgb'];
        }
      }

      return color;
    }
}

import { Layout } from './../template/layout';
import { Permittable } from './../common/permittable.type';
import { ProductElement } from './element/product-element.type';
import { ProductLayoutInterface } from './product-layout.type';
import { ProductLayoutOut } from './product-layout-out.type';
import { ProductLayerInterface } from './product-layer.type';
import { Selectable } from './../common/selectable.type';
import { SelectionType } from './../common/selection-type';
import { FormableType } from './../common/formable-type';
import { Image } from './../common/image.type';
import { ImageHelper } from './../common/image-helper';
import { Abstract } from './abstract';
import { ResizePermission } from './../common/resize-permission';
import { Color } from './../common/color.type';
import { Formable } from './../common/formable.type';
import { ResolutionPermission } from './../common/resolution-permission';
import { RepositionPermission } from './../common/reposition-permission';
import { ResolutionValidator } from './../common/validator/resolution-validator';
import { Validator } from './../common/validator/validator.type';
import { Validatable } from './../common/validatable.type';

export class ProductLayout extends Abstract implements ProductLayoutInterface {

    // Selectable options
    public selectionType: SelectionType = SelectionType.layout;
    public parentTier: Selectable;
    public childSelectables: Array<Selectable>;
    public imageSrc: string;

    constructor(
      public layout: Layout,
      public id: number,
      public key: string,
      public width: number,
      public height: number,
      public backgroundColor: Color,
      public backgroundImage: Image,
      public xPos: number = 0,
      public yPos: number = 0,
      public scaledX: number = 1,
      public scaledY: number = 1,
      public layers: Array<ProductLayerInterface> = [],
      attributes: any,
      public creationDate?: number,
      public modificationDate?: number,
      public published?: boolean
    ) {
      super(layout, key, attributes);
      this.setChildrenParentTier();
    }

    /**
     * Update is called on form changes
     *
     * @param {Image} image New image
     * @return {void}
     */
    update(image: Image) {
      this.backgroundImage = image;
      // Set to default size and position
      this.xPos = 0;
      this.yPos = 0;
      this.scaledX = 1;
      this.scaledY = 1;
      // Call on validators
      this.validate();
    }

    /**
     * Set product layout layers
     *
     * @param {Array<ProductLayerInterface>} layers Product layers in an array
     * @return {void}
     */
    setLayers(layers: Array<ProductLayerInterface>): void {
        this.layers = layers;
        this.setChildrenParentTier();
    }

    /**
     * Get product layout layers
     *
     * @return {Array<ProductLayerInterface>} Product layers in an array
     */
    Layers(): Array<ProductLayerInterface> {
      return this.layers;
    }

    /**
     * Set product layout layer
     *
     * @param {ProductLayerInterface} layer Layer
     * @return {void}
     */
    setLayer(layer: ProductLayerInterface): void {
        this.layers.push(layer);
    }

    /**
     * Returns children of the current validatable
     *
     * @return {Array<Validatable>}
     */
    public Children(): Array<Validatable> {
      return this.Layers();
    }

    /**
     * Returns the plan (template counter-part) of this implementation
     *
     * @return {Permittable}
     */
    public Plan(): Permittable {
      return this.layout;
    }

    /**
     * Get element form identifier from the default element
     *
     * @return {string} Returns the form identifier if set
     */
    public FormId() {
      return undefined;
    }

    /**
     * Find a formable from product structure by its type and key
     *
     * @param {string} key Formable key
     * @param {FormableType} type Formable type
     * @return {Formable} Return the fetched formable
     */
    findFormableByKey(key: string, type: FormableType): Formable {
      for (let layer of this.Layers()) {
        let item = layer.findFormableByKey(key, type);
        if (item !== undefined) {
          return item;
        }
      }

      return undefined;
    }

    /**
     * Set this layout as the parent tier of the child selectables
     *
     * @return {void}
     */
    setChildrenParentTier() {
      this.childSelectables = this.layers;
      for (let child of this.childSelectables) {
        child.parentTier = this;
      }
    }

    /**
     * Get product layout data.
     * This data is used for saving this object to the API.
     *
     * @return {ProductLayoutOut} Returns the product layout data for the API
     */
    getData(): ProductLayoutOut {
      let data:ProductLayoutOut = <any>{};
      data.id = this.id;
      data.key = this.key;
      data.layout = (this.layout !== undefined) ? this.layout.id : undefined;
      data.width = this.width;
      data.height = this.height;
      data.xPos = this.xPos;
      data.yPos = this.yPos;
      data.scaledX = this.scaledX;
      data.scaledY = this.scaledY;
      data.backgroundColor = this.backgroundColor;
      data.backgroundImageId = (this.backgroundImage) ? this.backgroundImage.id : undefined;
      data.attributes = this.attributes;
      if (this.layers.length > 0) {
        data.layers = [];
        for (let layer of this.layers) {
          let layerData = layer.getData();
          data.layers.push(layerData);
        }
      }

      return data;
    }

    /**
     * Set height safely.
     * Respects the restrictions set to the layout
     *
     * @param {number} value New height
     * @return {void}
     */
    public setHeight(value: number): void {
      if (! this.canResize('vertical')) {
        return ;
      }
      this.height = this.parseValidHeight(value);
    }

    /**
     * Set width safely.
     * Respects the restrictions set to the layout.
     *
     * @param {number} value New width
     * @return {void}
     */
    public setWidth(value: number): void {
      if (! this.canResize('horizontal')) {
        return ;
      }
      this.width = this.parseValidWidth(value);
    }

    /**
     * Can current user resize the layout
     *
     * @param {string} direction Optional parameter for resize direction [horizontal, vertical]
     * @return boolean
     */
    canResize(direction?: string): boolean {
      if (! this.layout) {
        return true;
      }
      let permission = this.ResizePermission();
      return permission.canResize(direction);
    }

    /**
     * Returns the possible maximum width for the layout
     * undefined = unlimited
     *
     * @return number|undefined
     */
    getMaxWidth(): number | undefined {
      if (! this.canResize('horizontal')) {
        return this.width;
      }
      let permission = this.ResizePermission();
      return permission.getMaxWidth();
    }

    /**
     * Returns the possible maximum height for the layout
     * undefined = unlimited
     *
     * @return number|undefined
     */
    getMaxHeight(): number | undefined {
      if (! this.canResize('vertical')) {
        return this.height;
      }

      let permission = this.ResizePermission();
      return permission.getMaxHeight();
    }

    /**
     * Returns the possible minimum width for the layout
     * undefined = unlimited
     *
     * @return number|undefined
     */
    getMinWidth(): number | undefined {
      if (! this.canResize('horizontal')) {
        return this.width;
      }

      let permission = this.ResizePermission();
      return permission.getMinWidth();
    }

    /**
     * Returns the possible minimum height for the layout
     * undefined = unlimited
     *
     * @return number|undefined
     */
    getMinHeight(): number | undefined {
      if (! this.canResize('vertical')) {
        return this.height;
      }

      let permission = this.ResizePermission();
      return permission.getMinHeight();
    }

    /**
     * Returns the unit for notch in height
     * undefined = dynamic
     *
     * @return number|undefined
     */
    getNotchHeight(): number | undefined {
      if (! this.canResize('vertical')) {
        return undefined;
      }

      let permission = this.ResizePermission();
      return permission.getNotchHeight();
    }

    /**
     * Returns the unit for notch in width
     * undefined = dynamic
     *
     * @return number|undefined
     */
    getNotchWidth(): number | undefined {
      if (! this.canResize('horizontal')) {
        return undefined;
      }

      let permission = this.ResizePermission();
      return permission.getNotchWidth();
    }

    /**
     * Parse the given value valid for height of the layer
     *
     * @param {number} value Height value
     * @return {number} Returns a valid value
     */
    parseValidHeight(value:number): number {
      if (! value) {
        return this.height;
      }
      if (this.getNotchHeight() && this.getNotchHeight() > 0) {
        value = Math.ceil(value / this.getNotchHeight()) * this.getNotchHeight();
      }
      if (this.getMaxHeight() && value > this.getMaxHeight()) {
        return this.getMaxHeight();
      }
      if (this.getMinHeight() && value < this.getMinHeight()) {
        return this.getMinHeight();
      }

      return value;
    }

    /**
     * Parse the given value valid for width of the layer
     *
     * @param {number} value Width value
     * @return {number} Return a valid value
     */
    parseValidWidth(value:number): number {
      if (! value) {
        return this.width;
      }
      if (this.getNotchWidth() && this.getNotchWidth() > 0) {
        value = Math.ceil(value / this.getNotchWidth()) * this.getNotchWidth();
      }
      if (this.getMaxWidth() && value > this.getMaxWidth()) {
        return this.getMaxWidth();
      }
      if (this.getMinWidth() && value < this.getMinWidth()) {
        return this.getMinWidth();
      }

      return value;
    }

    /**
     * Calculates the content height from layers
     *
     * @return {number} Returns the height of this layout based on layer heights
     */
    calcContentHeight(): number {
      let height = 0;
      for (let layer of this.layers) {
        let layerRelativeHeight = (Number(layer.yCoordinate) + Number(layer.height));
        if (layerRelativeHeight > height) {
          height = layerRelativeHeight;
        }
      }

      return height;
    }

    /**
     * Calculates the content width from layers
     *
     * @return {number} Returns the width of this layout based on layer widths
     */
    calcContentWidth(): number {
      let width = 0;
      for (let layer of this.layers) {
        let layerRelativeWidth = (Number(layer.xCoordinate) + Number(layer.width));
        if (layerRelativeWidth > width) {
          width = layerRelativeWidth;
        }
      }

      return width;
    }

    /**
     * Validate image resolution
     *
     * @param {Image} image The image that is validated
     *
     */
    protected validateImage() {
      let validator = new ResolutionValidator(this);
      if (! validator.run()) {
        for (let message of validator.Messages()) {
          this.addError(message.type, 'bgImage', message.message, message.label);
        }
      }
    }

    /**
     * Get element validators
     *
     * @return {Array<Validator>} Returns an array of validators
     */
    Validators(): Array<Validator> {
       let validators: Array<Validator> = [];
       validators.push(new ResolutionValidator(this));

       return validators;
    }

    /**
     * Returns the form item type
     *
     * @return {FormableType} Returns form item type
     */
    getFormableType(): FormableType {
      return FormableType.layout;
    }

    /**
     * Removes the layout background image and sets position/scale values to default
     *
     * @return {void}
     */
    clearBackgroundImage() {
      this.backgroundImage = undefined;
      this.imageSrc = undefined;
      this.xPos = 0;
      this.yPos = 0;
      this.scaledX = 1;
      this.scaledY = 1;
      // validate layout after bg image clear
      this.validate();
    }

    /**
     * Returns the pictorial element's image
     *
     * @return {Image} Returns the element image if defined
     */
    Image(): Image {
      return this.backgroundImage;
    }
    /**
     * Get percentage of scale on given axis or if not
     * specified on the longer side.
     *
     * @param {string} axis Scale value on axis [x, y]. Optional.
     * @return {number} Returns the scale value
     */
    ScaleValue(axis?: string): number {
      let scaleAxis: string = (! axis) ? ((this.Format() == 'landscape') ? 'x' : 'y')  : axis;

      return (scaleAxis === 'x') ? this.scaledX : this.scaledY;
    }

    /**
     * Return the image resolution on given axis or if not
     * specified on the longer side.
     *
     * @param {string} axis Scale value on axis [x, y]. Optional.
     * @return {number} Return the image resolution
     */
    Resolution(axis?: string): number {
      return ImageHelper.calcResolution(this, axis);
    }

    /**
     * Get image layout format
     *
     * @return {string} Returns the layout format [portrait, landscape, square]
     */
    Format(): string {
      if (this.width > this.height) {
        return "landscape";
      } else if (this.width == this.height) {
        return "square";
      }

      return "portrait";
    }

    /**
     * Image must keep aspect ratio?
     *
     * @return {boolean}
     */
    KeepAspectRatio(): boolean {
      return true;
    }

    /**
     * Image must fit given area?
     *
     * @return {boolean}
     */
    FitArea(): boolean {
      return false;
    }

    /**
     * Returns rules concerning the resolution of the image
     *
     * @return {ResolutionPermission}
     */
    ResolutionPermission(): ResolutionPermission {
      return this.layout.ResolutionPermission();
    }

    /**
     * Returns rules concerning the resizing of the image
     *
     * @return {ResizePermission}
     */
    ResizePermission(): ResizePermission {
      return this.layout.ResizePermission();
    }

    /**
     * Can user scale/resize the background image
     *
     * @return {boolean}
     */
    canScale(): boolean {
      return this.layout.canScale() || false;
    }

    /**
     * Can user reposition/move the background image
     *
     * @return {boolean}
     */
    canReposition(): boolean {
      return this.layout.canReposition() || false;
    }

    /**
     * Returns the image original DPI on given axis or if not
     * specified on the longer side.
     *
     * @param {string} axis DPI on axis [x, y]. Optional.
     * @return {number} Returns the DPI/PPI
     */
    OriginalDpi(axis?: string): number {
      let dpiAxis: string = (! axis) ? ((this.Format() == 'landscape') ? 'x' : 'y')  : axis;
      if (! this.Image()) {
        return undefined;
      }

      return this.Image().resolutions[dpiAxis];
    }
  }

import { Animation } from "./animation";
import { AnimationInterpreter } from "./animation-interpreter";
import { MarginInterpreter } from "./margin-interpreter";
import { FloatInterpreter } from "./float-interpreter";
import { JustifyContentInterpreter } from "./justify-content-interpreter";
import { QrCodeInterpreter } from "./qr-code-interpreter";

export class AttributeInterpreter
  implements
    AnimationInterpreter,
    MarginInterpreter,
    FloatInterpreter,
    JustifyContentInterpreter,
    QrCodeInterpreter
{
  /**
   * Interprete selected animation from the attributes
   *
   * @param {any} attributes Attributes object
   * @return {Animation} Returns the animation
   */
  animation(attributes: any): Animation {
    if (!attributes || attributes.animation === undefined) {
      return undefined;
    }
    let animationString: string =
      attributes.animation.animation === undefined
        ? undefined
        : attributes.animation.animation;
    if (!animationString) {
      return undefined;
    }

    return this.createAnimation(animationString);
  }

  /**
   * Interprete selected animation delay from the attributes
   *
   * @param {any} attributes Attributes object
   * @return {Animation} Returns the animation delay
   */
  delay(attributes: any): number {
    let delay: number;
    if (!attributes || attributes.animation === undefined) {
      return undefined;
    }
    delay =
      attributes.animation.delay === undefined ? 0 : attributes.animation.delay;

    return delay;
  }

  /**
   * Interprete selected animation duration from the attributes
   *
   * @param {any} attributes Attributes object
   * @return {Animation} Returns the animation duration
   */
  duration(attributes: any): number {
    let duration: number;
    if (!attributes || attributes.animation === undefined) {
      return undefined;
    }
    duration =
      attributes.animation.duration === undefined
        ? 0
        : attributes.animation.duration;

    return duration;
  }

  /**
   * Interprete from the attributes is the animation infinite
   *
   * @param {any} attributes Attributes object
   * @return {boolean} Returns true for infinite
   */
  infinite(attributes: any): boolean {
    let infinite: boolean = false;
    if (!attributes || attributes.animation === undefined) {
      return false;
    }
    infinite =
      attributes.animation.infinite === undefined
        ? false
        : attributes.animation.infinite;

    return infinite;
  }

  /**
   * Set animation to the attributes
   *
   * @param {any} attributes Attributes object
   * @param {Animation} animation Selected animation
   * @param {number} delay Animation delay
   * @param {number} duration Animation duration
   * @param {boolean} infinite Is animation infinite
   * @return {any} Returns the updated attributes
   */
  setAnimationAttribute(
    attributes: any,
    animation: Animation,
    delay: number,
    duration: number,
    infinite: boolean
  ) {
    if (!attributes || attributes.animation === undefined) {
      attributes = {
        animation: {
          animation: undefined,
          delay: 0,
          duration: 0,
          infinite: false,
        },
      };
    }
    if (animation === undefined) {
      attributes.animation = undefined;

      return attributes;
    }

    attributes.animation.animation = Animation[animation];
    attributes.animation.delay = delay;
    attributes.animation.duration = duration;
    attributes.animation.infinite = infinite;

    return attributes;
  }

  /**
   * Create animation class object from a string
   *
   * @param {string} animation Name of the animation
   * @return {Animation} Returns the animation representing the animation name
   */
  protected createAnimation(animation: string): Animation {
    return <Animation>Animation[animation];
  }

  /**
   * Returns margin value from element attributes if set
   *
   * @param {any} attributes Attributes object
   * @param {string} position Margin for position
   * @return {number} Returns margin value from element attributes if set else 0
   */
  margin(attributes: any, position: string): number {
    if (!attributes || attributes.margin === undefined) {
      return 0;
    }

    return attributes.margin[position] === undefined
      ? 0
      : attributes.margin[position];
  }

  /**
   * Returns float position value from element attributes if set
   *
   * @param {any} attributes Attributes object
   * @return {string} Returns float position value from element attributes if set else 0
   */
  getFloat(attributes: any): string {
    if (!attributes || attributes.float === undefined) {
      return "none";
    }

    return attributes.float.float === undefined
      ? "none"
      : attributes.float.float;
  }

  /**
   * Returns justify content value from element attributes if set
   *
   * @param {any} attributes Attributes object
   * @return {string} Returns justify content value from element attributes if set else initial
   */
  justifyContent(attributes: any): string {
    if (!attributes || attributes.justifyContent === undefined) {
      return "initial";
    }

    return attributes.justifyContent.align === undefined
      ? "initial"
      : attributes.justifyContent.align;
  }

  /**
   * Returns leading value from element attributes if set
   *
   * @param {any} attributes Attributes object
   * @return {number|undefined} Returns leading value from element attributes
   */
  leading(attributes: any): number | undefined {
    if (!attributes || attributes.leading === undefined) {
      return undefined;
    }

    return attributes.leading.leading === undefined
      ? undefined
      : attributes.leading.leading / 100;
  }

  /**
   * Returns letter spacing value from element attributes if set
   *
   * @param {any} attributes Attributes object
   * @return {number} Returns letter spacing value from element attributes
   */
  letterSpacing(attributes: any): number {
    if (!attributes || attributes.letterSpacing === undefined) {
      return 0;
    }

    return attributes.letterSpacing.letterSpacing;
  }

  /**
   * Returns letter spacing value from element attributes if set
   *
   * @param {any} attributes Attributes object
   * @return {string} Returns letter spacing unit
   */
  letterSpacingUnit(attributes: any): string | undefined {
    if (!attributes || attributes.letterSpacing === undefined) {
      return "px";
    }

    return attributes.letterSpacing.unit === undefined
      ? "px"
      : attributes.letterSpacing.unit;
  }

  rotate(attributes: any): number | undefined {
    if (!attributes || attributes.rotate === undefined) {
      return undefined;
    }

    return attributes.rotate.rotateDegree;
  }

  qrcodeUrl(attributes: any): string | undefined {
    if (!attributes || attributes.qrcodeUrl === undefined) {
      return undefined;
    }
    return attributes.qrcodeUrl.url;
  }

  /**
   * Set QR code url to attributes
   * @param attributes
   * @param url
   *
   */
  setQrcodeUrl(attributes: any, url: string): void {
    if (!attributes.qrcodeUrl) {
      attributes.qrcodeUrl = {};
    }
    attributes.qrcodeUrl.url = url;
    return attributes;
  }
}

import {
  Component,
  Input,
  Output,
  ElementRef,
  EventEmitter,
  ChangeDetectorRef
} from "@angular/core";
import { ElementService } from "./../../../services/element.service";
import { SelectionService } from "./../../../services/selection.service";
import { ProductTextElement } from "./../../../models/product/element/product-text-element";
import { StyleObject } from "./../../../models/common/style-object.type";
import { ResizePermission } from "./../../../models/common/resize-permission";
import { ScalePermission } from "./../../../models/common/scale-permission";
import { Animator } from "./../../../models/common/animator";

@Component({
  selector: "sd-text-element",
  templateUrl: "./text-element.component.html",
  styleUrls: ["./text-element.component.scss"]
})
export class TextElementComponent {
  @Input() element: ProductTextElement;
  @Input() previewing: boolean = false;

  @Output() height: EventEmitter<number> = new EventEmitter<number>();
  @Output() width: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private elementService: ElementService,
    private elRef: ElementRef,
    private changeDetectionRef: ChangeDetectorRef,
    private selectionService: SelectionService
  ) {}

  /**
   * Can current user update the element
   *
   * @return boolean
   */
  isEditable(): boolean {
    return this.element.can("implement");
  }

  /**
   * Is current element animated
   *
   * @return boolean
   */
  isAnimated(): boolean {
    return this.previewing && this.element.isAnimetable();
  }

  /**
   * Is current element selected
   *
   * @return {boolean}
   */
  isSelected(): boolean {
    return this.selectionService.isSelected(this.element);
  }

  isElementEmpty(): boolean {
    return this.element.height == 0;
  }

  /**
   * Get text element content in HTML markup
   *
   * @return {string} Returns the element content HTML
   */
  getContentHtml(): string {
    let contentHtml = this.element.getContentHtml();
    if (contentHtml == "") {
      return contentHtml;
    }

    return "<span>" + contentHtml + "</span>";
  }

  /**
   * Set element height to model from component element's real height
   *
   * @param {number} value Element real height
   * @return {void}
   */
  setHeight(value: number) {
    let curValue = this.element.height;
    if (value === curValue) {
      return;
    }

    this.element.setHeight(value);
    if (curValue !== this.element.height) {
      this.emitResize("height");
    }
  }

  /**
   * Set element width to model from component element's real width
   *
   * @param {number} value Element real width
   * @return {void}
   */
  setWidth(value: number) {
    let curValue = this.element.width;
    if (value === curValue) {
      return;
    }

    this.element.setWidth(value);
    if (curValue !== this.element.width) {
      this.emitResize("width");
    }
  }

  /**
   * Toggle overflow flag based on the event data
   *
   * @param {boolean} event Event data
   * @return {void}
   */
  toggleOverflow(event: boolean) {
    if (this.element.isContentOverflown() != event) {
      // Prevent ExpressionChangedAfterItHasBeenCheckedError error
      setTimeout(() => {
        this.element.toggleOverflow(event);
        this.changeDetectionRef.detectChanges();
      });
    }
  }

  /**
   * Emit detected resize
   *
   * @param {string} dimension Which dimension was resized: [width, height]
   * @return {void}
   */
  emitResize(dimension: string): void {
    if (dimension === "width") {
      this.width.emit(this.element.width);
    } else if (dimension === "height") {
      this.height.emit(this.element.height);
    }
    // Tell view to detect changes
    this.changeDetectionRef.detectChanges();
  }

  /**
   * Returns default font for the text element.
   *
   * @return {any} Returns an object with font name and value
   */
  getDefaultFont(): any {
    return this.element.getDefaultFont();
  }

  /**
   * Returns default font color for the text element.
   *
   * @return {string} Returns the default font color
   */
  getDefaultFontColor(): string {
    return this.element.getDefaultFontColor();
  }

  /**
   * Get font families allowed for the element
   *
   * @return {any} Retunrs the font families in an object
   */
  getAvailableFontFamilies(): any {
    let fontFamilies = {};
    for (let font of this.element.getFonts()) {
      fontFamilies[font.key] = font.name;
    }

    return fontFamilies;
  }

  /**
   * Get font families currenlty in use on the element content
   *
   * @return {any} Retunrs the font families of the current content
   */
  getContentFontFamilies(): any {
    return this.element.getContentFontFamilies();
  }

  /**
   * Return the default letter spacing for the content
   *
   * @return {number}
   */
  getDefaultLetterSpacingValue(): number {
    return this.element.letterSpacing();
  }

  /**
   * Return the letter spacing unit for the content
   *
   * @return {string}
   */
  getDefaultLetterSpacingUnit(): string {
    return this.element.letterSpacingUnit();
  }

  /**
   * Returns the used default letter spacing
   *
   * @return {string} Return default letter spacing
   */
  getDefaultLetterSpacing(): string {
    let value = this.getDefaultLetterSpacingValue();
    let unit = this.getDefaultLetterSpacingUnit();
    if (unit === "em") {
      value = value / 1000;
    }

    return value + unit;
  }

  /**
   * Is the text element scallable
   *
   * @return {boolean} Returns bool based on the scale permission
   */
  isScallable(): boolean {
    let scalePermission: ScalePermission = this.element.defaultElement
      ? <ScalePermission>this.element.defaultElement.Permission("scale")
      : undefined;

    return scalePermission && scalePermission.canChange();
  }

  /**
   * Return element in-line styles for data binding
   *
   * @return {any} Returns the styles in an object
   */
  setStyles(): any {
    let resizePermission: ResizePermission = this.element.defaultElement
      ? <ResizePermission>this.element.defaultElement.Permission("resize")
      : undefined;

    let maxHeight = this.element.getMaxHeight();
    let maxWidth = this.element.getMaxWidth();
    let minHeight = this.element.getMinHeight();
    let minWidth = this.element.getMinWidth();
    //  Scaling
    let scalePermission: ScalePermission = this.element.defaultElement
      ? <ScalePermission>this.element.defaultElement.Permission("scale")
      : undefined;

    let font = this.getDefaultFont();
    let styles: StyleObject = {
      "minWidth.mm": minWidth,
      "minHeight.mm": minHeight,
      "maxWidth.mm": maxWidth,
      "maxHeight.mm": maxHeight,
      "marginTop.mm": this.element.getMargin("top"),
      "marginLeft.mm": this.element.getMargin("left"),
      "marginBottom.mm": this.element.getMargin("bottom"),
      "marginRight.mm": this.element.getMargin("right"),
      overflowWrap: this.element.getOverflowWrap(),
      whiteSpace: this.element.getWhiteSpace(),
      float: this.element.getFloat(),
      "fontSize.px": this.element.getDefaultFontSize(),
      color: this.getDefaultFontColor(),
      textAlign: this.element.getDefaultAlign(),
      fontFamily: font.key,
      letterSpacing: this.getDefaultLetterSpacing()
    };

    if (this.previewing) {
      let animator = new Animator(this.element);
      return { ...styles, ...animator.getAnimationStyles() };
    }

    return styles;
  }

  /**
   * Does the layout have errors
   *
   * @return {boolean}
   */
  hasErrors(): boolean {
    return this.element.hasErrors();
  }

  /**
   * Does the layout have warnings
   *
   * @return {boolean}
   */
  hasWarnings(): boolean {
    return this.element.hasWarnings();
  }
}

import { TextElement } from './text-element';
import { EmailElementInterface } from './email-element.type';
import { Layer } from './../layer';

export class EmailElement extends TextElement implements EmailElementInterface  {

    constructor(
      public id: number,
      public key: string,
      public elementType: string,
      public required: boolean,
      public label: string,
      public width: number,
      public height: number,
      public helpText: string,
      public content: string,
      public placeHolder: string,
      public attributes: any,
      public permissions: any,
      public fonts: any,
      public defaultFontSize: number,
      public defaultAlign: string,
      public paragraphFormats: any,
      public formId?: string,
      public formIndex?: number,
      public formScale?: number
    ) {
        super(id, key, elementType, required, label, width, height, helpText, content, placeHolder, attributes, permissions, fonts, defaultFontSize, defaultAlign, 1, paragraphFormats, formId, formIndex, formScale);
    }
}

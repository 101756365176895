import { Permission } from './permission.type';

export class LetterSpacingPermission implements Permission
{
  permission: boolean;

  constructor(permission:any) {
    this.permission = permission;
  }

  /**
   * Can an action be exuted based on the permission
   *
   * @return {boolean}
   */
  public canChange(): boolean {
    return (this.getRule() !== 'locked');
  }

  /**
   * Get permission value
   *
   * @return {any} Returns the permission value
   */
  public Permission(): any {
    return this.permission;
  }

  /**
   * Returns the possible maximum letter spacing for the resource text
   * undefined = unlimited
   *
   * @return number|undefined
   */
  public getMax(): number | undefined {
    return this.Permission().max;
  }

  /**
   * Returns the possible minimum letter spacing for the resource
   * undefined = 1
   *
   * @return number
   */
  public getMin(): number {
    return (! this.Permission().min) ? 1 : this.Permission().min;
  }

  /**
   * Returns the possible letter spacing unit for the resource
   * undefined = 'em'
   *
   * @return string
   */
  public getUnit(): number {
    return (! this.Permission().unit) ? 'em' : this.Permission().unit;
  }

  /**
   * Returns the permission rule
   *
   * @return {string} Returns the rule [open, consistent, locked]
   */
  public getRule(): string {
    return (this.Permission().rule || 'locked');
  }
}

import { Permittable } from './../common/permittable.type';
import { Permission } from './../common/permission.type';
import { Validatable } from './../common/validatable.type';
import { Selectable } from './../common/selectable.type';
import { Attributable } from './../common/attributable.type';
import { Animation } from './../common/animation';
import { AnimatePermission } from './../common/animate-permission';
import { AttributeInterpreter } from './../common/attribute-interpreter';
import { Validator, ValidatorMessage, ValidatorMessageType } from './../common/validator/validator.type';
import { ValidatorAggregator } from './../common/validator/validator-aggregator';
import { ChildAggregator } from './../common/child-aggregator';

export abstract class Abstract implements Permittable, Validatable, Attributable {

    errors: Array<ValidatorMessage> = [];
    attributeInterpreter: AttributeInterpreter;

    constructor(public permittable: Permittable, public key: string, public attributes: any = []) {
      this.attributeInterpreter = new AttributeInterpreter();
    }

    /**
     * Get all permissions
     *
     * @return {Array<Permission>} Return all permissions
     */
     public Permissions(): Array<Permission> {
       if (! this.Plan()) {
         return [];
       }

       return this.Plan().Permissions();
     }

    /**
     * Can an action be executed on an element based on element permissions
     *
     * @param {string} key Name or Key of the permission
     * @return {boolean}
     */
    public can(key: string) : boolean {
      if (! this.Plan()) {
        return true;
      }

      return this.Plan().can(key);
    }

    /**
     * Return element permissions by key/name
     *
     * @param {string} key Name/Key of the permission
     * @return {Permission} Returns the permission model
     */
    public Permission(key: string) : Permission {
      if (! this.Plan()) {
        return undefined;
      }

      return this.Plan().Permission(key);
    }

    /**
     * Can current user view the element
     *
     * @return boolean
     */
    public canView(): boolean {
      return this.can('view');
    }

    /**
     * Can current user implement the element
     *
     * @return boolean
     */
    public canImplement(): boolean {
      return this.can('implement');
    }

    /**
     * Can current user delete the element
     *
     * @return boolean
     */
    public canDelete(): boolean {
      return this.can('delete');
    }

    /**
     * Get all attributes
     *
     * @return {Array<any>} Return all attributes
     */
     Attributes(): Array<any> {
       return this.attributes;
     }

    /**
     * Returns the selected animation
     *
     * @return {Animation} Returns selected animation
     */
    Animation(): Animation {
      return this.attributeInterpreter.animation(this.Attributes());
    }

    /**
     * Returns the available animations
     *
     * @return {Array<Animation>} Returns available animations
     */
    AvailableAnimations(): Array<Animation> {
      if (this.Permission('animate') !== undefined) {
        let permission = <AnimatePermission>this.Permission('animate');

        return permission.Animations();
      }

      return [];
    }

    /**
     * Returns the selected delay in seconds for the animation
     *
     * @return {number} Returns delay in seconds
     */
    Delay(): number {
      return this.attributeInterpreter.delay(this.Attributes());
    }

    /**
     * Returns the animation duration in seconds
     *
     * @return {number} Returns duration in seconds
     */
    Duration(): number {
      return this.attributeInterpreter.duration(this.Attributes());
    }

    /**
     * Is the animation infinite
     *
     * @return {boolean}
     */
    isInfinite(): boolean {
      return this.attributeInterpreter.infinite(this.Attributes());
    }

    /**
     * Is formable animatable
     *
     * @return {boolean}
     */
    isAnimetable(): boolean {
      return this.can('animate');
    }

    /**
     * Update animation values from the form
     *
     * @param {Animation} animation Selected animation
     * @param {number} delay Animation delay
     * @param {number} duration Animation duration
     * @param {boolean} infinite Is animation infinite
     * @return {void}
     */
    updateAnimation(animation: Animation, delay: number, duration: number, infinite: boolean) {
      if (! this.isAnimetable()) {
        return ;
      }
      this.attributes = this.attributeInterpreter.setAnimationAttribute(this.Attributes(), animation, delay, duration, infinite);
    }

    /**
     * Clear errors and warnings from the element
     *
     * @return {void}
     */
    public clearErrors() {
      this.errors = [];
    }

    /**
     * Validate product layout attributes and values
     *
     * @return {void}
     */
    public validate() {
      this.clearErrors();
      let aggregator = new ValidatorAggregator(this.Validators());
      let iterator = aggregator.createIterator();
      while (iterator.hasNext()) {
        let validator = iterator.next();
        if (! validator.run()) {
          for (let message of validator.Messages()) {
            this.addError(message.type, message.key, message.message, message.label);
          }
        }
      }
      // Pass validation down the line
      this.validateChildren();
    }

    /**
     * Recursivelly call validate on element children as well
     *
     * @return {void}
     */
    public validateChildren() {
      let iterator = this.ChildIterator();
      while (iterator.hasNext()) {
        let child = iterator.next();
        child.validate();
      }
    }

    /**
     * Find children by form identifier
     *
     * @return {Selectable[]} Returns a list of product children
     */
    public findByFormId(formId: string): Selectable[] {
      if (! formId) {
        return [];
      }
      let iterator = this.SelectableIterator();
      let children = [];
      while (iterator.hasNext()) {
        let child = iterator.next();
        if (child.FormId() == formId) {
          children.push(child);
        }
        let grandChildren = child.findByFormId(formId);

        children = [ ...children, ...grandChildren];
      }

      return children;
    }

    /**
     * Returns the form item index
     *
     * @return {number} Returns form item index
     */
    public FormIndex(): number {
      return 0;
    }

    /**
     * Returns the child iterator
     *
     * @return {ChildIterator}
     */
    protected ChildIterator() {
      let aggregator = new ChildAggregator(this.Children());

      return aggregator.createIterator();
    }

    /**
     * Returns the child iterator
     *
     * @return {SelectableIterator}
     */
    protected SelectableIterator() {
      let aggregator = new ChildAggregator(this.Children());

      return aggregator.createSelectableIterator();
    }

    /**
     * Get element validators
     *
     * @return {Array<Validator>} Returns an array of validators
     */
    Validators(): Array<Validator> {
       let validators: Array<Validator> = [];

       return validators;
    }

    /**
     * Set an error to element errors
     *
     * @param {ValidatorMessageType} type Type of the error [error, warning]
     * @param {string} key Error Key
     * @param {string} message Error message
     * @param {string} label Element label
     */
    public addError(type: ValidatorMessageType, key: string, message: string, label: string) {
       // try to find an error with the same key
       let error = this.getValidationMessage(type, key);
       // If not found, add a new error
       if (! error) {
          this.errors.push(<ValidatorMessage>{type: type, key: key, message: message, label: label});
          return ;
       }
       error.message = message;
    }

    /**
     * Set an error to element errors
     *
     * @param {string} key Error Key
     * @param {string} message Error message
     * @param {string} label Element label
     */
    public setError(key: string, message: string, label: string) {
       this.addError(ValidatorMessageType.error, key, message, label);
    }

    /**
     * Get an error by key from element errors
     *
     * @param {ValidatorMessageType} type Type of the error message [error, warning]
     * @param {string} key Key of the error
     * @return {any} Returns the error
     */
    public getError(key: string): ValidatorMessage {
      return this.getValidationMessage(ValidatorMessageType.error, key);
    }

    /**
     * Set a warning to element warnings
     *
     * @param {string} key Warning Key
     * @param {string} message Warning message
     * @param {string} label Element label
     */
    public setWarning(key: string, message: string, label: string) {
      this.addError(ValidatorMessageType.warning, key, message, label);
    }

    /**
     * Get a warning by key from element error messages
     *
     * @param {string} key Key of the error
     * @return {any} Returns the error
     */
    public getWarning(key: string): ValidatorMessage {
      return this.getValidationMessage(ValidatorMessageType.warning, key);
    }

    /**
     * Get all validation messages by type
     *
     * @return {Array<ValidatorMessage>} Returns the errors
     */
    public getValidationMessages(type?: ValidatorMessageType): Array<ValidatorMessage> {
      let messages: Array<ValidatorMessage> = [];
      for (let message of this.errors) {
        if (! type || type === message.type) {
          messages.push(message);
        }
      }

      return messages;
    }

    /**
     * Get all validation messages by type
     *
     * @param {ValidatorMessageType} type Validator message type [error, warning]
     * @param {string} key Error message key
     * @return {ValidatorMessage} Returns the error message if found
     *
     */
    public getValidationMessage(type: ValidatorMessageType, key: string): ValidatorMessage {
      for (let message of this.errors) {
        if (type === message.type && key === message.key) {
          return message;
        }
      }

      return undefined;
    }

    /**
     * Get all element errors
     *
     * @param {boolean} recursive Is errors searched recursivelly
     * @return {Array<ValidatorMessage>} Returns the error
     */
    public getErrors(recursive:boolean = false): Array<ValidatorMessage> {
      let messages = this.getValidationMessages(ValidatorMessageType.error);
      if (recursive) {
        messages = [ ...messages, ...this.getChildErrors()];
      }

      return messages;
    }

    /**
     * Get all element warnings
     *
     * @param {boolean} recursive Is warnings searched recursivelly
     * @return {Array<ValidatorMessage>} Returns the warning
     */
    public getWarnings(recursive:boolean = false): Array<ValidatorMessage> {
      let messages = this.getValidationMessages(ValidatorMessageType.warning);
      if (recursive) {
        messages = [ ...messages, ...this.getChildWarnings()];
      }

      return messages;
    }

    /**
     * Does the element have errors
     *
     * @param {boolean} recursive Is errors searched recursivelly
     * @return {boolean}
     */
    public hasErrors(recursive?:boolean): boolean {
      let hasErrors = (this.getErrors().length > 0);
      if (recursive === true) {
          return (hasErrors || this.hasChildErrors());
      }

      return hasErrors;
    }

    /**
     * Does the element have warnings
     *
     * @param {boolean} recursive Is warnings searched recursivelly
     * @return {boolean}
     */
    public hasWarnings(recursive?: boolean): boolean {
      let hasWarnings = (this.getWarnings().length > 0);
      if (recursive === true) {
        return (hasWarnings || this.hasChildWarnings());
      }

      return hasWarnings;
    }

    /**
     * Does element childs have errors.
     * This should be overriden by elements that have child elements.
     *
     * @return {boolean}
     */
    public hasChildErrors(): boolean {
      for (let child of this.Children()) {
        if (child.hasErrors(true)) {
          return true;
        }
      }

      return false;
    }

    /**
     * Get element child errors.
     *
     @return {Array<ValidatorMessage>} Returns the error
     */
    public getChildErrors(): Array<ValidatorMessage> {
      let messages = [];
      for (let child of this.Children()) {
        messages = [ ...messages, ...child.getErrors(), ...child.getChildErrors()];
      }

      return messages;
    }

    /**
     * Does element childs have warnings.
     * This should be overriden by elements that have child elements.
     *
     * @return {boolean}
     */
    public hasChildWarnings(): boolean {
      for (let child of this.Children()) {
        if (child.hasWarnings(true)) {
          return true;
        }
      }

      return false;
    }

    /**
     * Get element child warnings.
     *
     @return {Array<ValidatorMessage>} Returns the warnings
     */
    public getChildWarnings(): Array<ValidatorMessage> {
      let messages = [];
      for (let child of this.Children()) {
        messages = [ ...messages, ...child.getWarnings(), ...child.getChildWarnings()];
      }

      return messages;
    }

    /**
     * Get element human readable name
     *
     * @return {string}
     */
    public ElementName(): string {
      return this.key;
    }

    /**
     * Returns children of the current validatable
     *
     * @return {Array<Validatable>}
     */
    public abstract Children(): Array<Validatable>;

    /**
     * Returns the plan (template counter-part) of this implementation
     *
     * @return {Permittable}
     */
    public abstract Plan(): Permittable;
  }

import { Layer } from './layer';
import { Abstract } from './abstract';
import { Color } from './../common/color.type';
import { Image } from './../common/image.type';
import { ImageHelper } from './../common/image-helper';
import { Pictorial } from './../common/pictorial';
import { ResolutionPermission } from './../common/resolution-permission';
import { RepositionPermission } from './../common/reposition-permission';
import { ResizePermission } from './../common/resize-permission';

export class Layout extends Abstract implements Pictorial {

    constructor(
      public id: number,
      public key: string,
      public pageNumber: number,
      public width: number,
      public height: number,
      public renderBgImage: boolean,
      public bgColor: Color,
      public backgroundImage: Image,
      public xPos: number,
      public yPos: number,
      public scaled: number,
      public permissions: any,
      public layers: Array<Layer> = [],
      public attributes: any
    ) {
      super(permissions);
    }

    /**
     * Set template layers
     *
     * @param {Array<Layer>} layers Layers in an array
     * @return void
     */
    setLayers(layers: Array<Layer>) {
        this.layers = layers;
    }

    /**
     * Returns the pictorial element's image
     *
     * @return {Image} Returns the element image if defined
     */
    Image(): Image {
      return this.backgroundImage;
    }
    /**
     * Get percentage of scale on given axis or if not
     * specified on the longer side.
     *
     * @param {string} axis Scale value on axis [x, y]. Optional.
     * @return {number} Returns the scale value
     */
    ScaleValue(axis?: string): number {
      return this.scaled;
    }

    /**
     * Return the image resolution on given axis or if not
     * specified on the longer side.
     *
     * @param {string} axis Scale value on axis [x, y]. Optional.
     * @return {number} Return the image resolution
     */
    Resolution(axis?: string): number {
      return ImageHelper.calcResolution(this, axis);
    }

    /**
     * Get image layout format
     *
     * @return {string} Returns the layout format [portrait, landscape, square]
     */
    Format(): string {
      if (this.width > this.height) {
        return "landscape";
      } else if (this.width == this.height) {
        return "square";
      }

      return "portrait";
    }

    /**
     * Returns rules concerning the resolution of the image
     *
     * @return {ResolutionPermission}
     */
    ResolutionPermission(): ResolutionPermission {
      let resolutionPermission: ResolutionPermission = <ResolutionPermission>this.Permission('resolution');

      return resolutionPermission;
    }

    /**
     * Returns rules concerning the resizing of the image
     *
     * @return {ResizePermission}
     */
    ResizePermission(): ResizePermission {
      let permission: ResizePermission = <ResizePermission>this.Permission('resize');

      return permission;
    }

    /**
     * Returns rules concerning the repositioning of the image
     *
     * @return {RepositionPermission}
     */
    RepositionPermission(): RepositionPermission {
      let repositionPermission: RepositionPermission = <RepositionPermission>this.Permission('reposition');

      return repositionPermission;
    }

    /**
     * Can user resize the background image
     *
     * @return {boolean}
     */
    canScale(): boolean {
      return this.RepositionPermission().canRescale();
    }

    /**
     * Can user reposition/move the background image
     *
     * @return {boolean}
     */
    canReposition(): boolean {
      return this.RepositionPermission().canReposition();
    }

    /**
     * Returns the image original DPI on given axis or if not
     * specified on the longer side.
     *
     * @param {string} axis DPI on axis [x, y]. Optional.
     * @return {number} Returns the DPI/PPI
     */
    OriginalDpi(axis?: string): number {
      let dpiAxis: string = (! axis) ? ((this.Format() == 'landscape') ? 'x' : 'y')  : axis;
      if (! this.Image()) {
        return undefined;
      }

      return this.Image().resolutions[dpiAxis];
    }

    /**
     * Get element human readable name
     *
     * @return {string}
     */
    public ElementName(): string {
      return this.key;
    }
}

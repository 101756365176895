import { Injectable, EventEmitter } from '@angular/core';
import { ProductElement } from './../models/product/element/product-element.type';

@Injectable()
export class ElementService {
  elementSelected$: EventEmitter<ProductElement>;
  private selectedElement: ProductElement;
  private currentEvent: any;

  constructor() {
      this.elementSelected$ = new EventEmitter();
  }

  getSelectedElement(): ProductElement {
      return this.selectedElement;
  }

  getCurrentEvent(): any {
      return this.currentEvent;
  }

  select(event: any, element: ProductElement): void {
      this.currentEvent = event;
      this.elementSelected$.emit(element);
      this.selectedElement = element;
  }

  /**
   * Resolves the element offset from top in pixels
   *
   * @return {number}
   */
  getElementsPageTop(zElement:HTMLElement)
  {
      let topOffset  = zElement.offsetTop;
      while (zElement = <HTMLElement>zElement.offsetParent)
      {
          topOffset  += zElement.offsetTop;
      }

      return topOffset;
  }
}

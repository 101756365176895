import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { TranslateModule } from "@ngx-translate/core";
import {
  MatExpansionModule,
  MatSnackBarModule,
  MatCardModule,
  MatDialogModule,
  MatInputModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatSliderModule,
  MatSidenavModule,
  MatSortModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTabsModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatDividerModule,
  MatTableModule
} from "@angular/material";
import { FileUploadModule } from "ng2-file-upload";
import { SliderModule } from "primeng/slider";
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import "hammerjs";
import { AngularDraggableModule } from "angular2-draggable";
import { FroalaComponent } from "./froala.component";
import { httpInterceptorProviders } from "../smartdesigner-app/http-interceptors/index";
import { AuthService } from "../smartdesigner-app/services/auth.service";
import { ApiService } from "../smartdesigner-app/services/api.service";
import { CryptoService } from "./../services/crypto.service";
import { SmartdesignerService } from "../smartdesigner-app/services/smartdesigner.service";
import { ConfigurationService } from "../smartdesigner-app/services/configuration.service";
import { FormService } from "../smartdesigner-app/services/form.service";
import { ElementService } from "../smartdesigner-app/services/element.service";
import { LayerService } from "../smartdesigner-app/services/layer.service";
import { ProductService } from "../smartdesigner-app/services/product.service";
import { SelectionService } from "../smartdesigner-app/services/selection.service";
import { ImageService } from "../smartdesigner-app/services/image.service";
import { ZoomService } from "../smartdesigner-app/services/zoom.service";
import { ImageToolService } from "../smartdesigner-app/services/image-tool.service";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSliderModule,
    MatSidenavModule,
    MatSortModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDividerModule,
    MatTableModule,
    FileUploadModule,
    SliderModule,
    AngularDraggableModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    ScrollToModule.forRoot()
  ],
  exports: [TranslateModule],
  declarations: [FroalaComponent],
  providers: [
    httpInterceptorProviders,
    CryptoService,
    SmartdesignerService,
    AuthService,
    ApiService,
    ElementService,
    LayerService,
    ConfigurationService,
    ProductService,
    SelectionService,
    ImageService,
    FormService,
    ZoomService,
    ImageToolService
  ],
  entryComponents: [],
  bootstrap: [FroalaComponent]
})
export class FroalaModule {}

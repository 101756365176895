import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { TranslateModule } from "@ngx-translate/core";
import {
  MatExpansionModule,
  MatSnackBarModule,
  MatCardModule,
  MatDialogModule,
  MatInputModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatSliderModule,
  MatSidenavModule,
  MatSortModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTabsModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatDividerModule,
  MatTableModule
} from "@angular/material";
import { FileUploadModule } from "ng2-file-upload";
import { SliderModule } from "primeng/slider";
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import "hammerjs";
import { AngularDraggableModule } from "angular2-draggable";
import { SmartdesignerAppComponent } from "./smartdesigner-app.component";
import { httpInterceptorProviders } from "./http-interceptors/index";
import { AuthService } from "./services/auth.service";
import { ApiService } from "./services/api.service";
import { CryptoService } from "./../services/crypto.service";
import { SmartdesignerService } from "./services/smartdesigner.service";
import { ConfigurationService } from "./services/configuration.service";
import { FormService } from "./services/form.service";
import { ElementService } from "./services/element.service";
import { LayerService } from "./services/layer.service";
import { ProductService } from "./services/product.service";
import { SelectionService } from "./services/selection.service";
import { ImageService } from "./services/image.service";
import { ZoomService } from "./services/zoom.service";
import { ImageToolService } from "./services/image-tool.service";
import { StageComponent } from "./components/stage/stage.component";
import { PageButtonComponent } from "./components/stage/page-button/page-button.component";
import { PageComponent } from "./components/page/page.component";
import { LayerComponent } from "./components/layer/layer.component";
import { GroupComponent } from "./components/elements/group/group.component";
import { TextElementComponent } from "./components/elements/text-element/text-element.component";
import { EmailElementComponent } from "./components/elements/email-element/email-element.component";
import { ImageElementComponent } from "./components/elements/image-element/image-element.component";
import { ClickStopPropagationDirective } from "./directives/click-stop-propagation.directive";
import { SaveProductDirective } from "./directives/save-product.directive";
import {
  SaveCloseComponent,
  SdDialogErrorsDialog,
  SdDialogWarningsDialog,
  SdDialogInstructionDialog
} from "./components/forms/save-close/save-close.component";
import { SdDialogComponent } from "./components/common/dialog/sd-dialog.component";
import { ImageToolComponent } from "./components/forms/image-tool/image-tool.component";
import { ImageToolFormComponent } from "./components/forms/image-tool/form/image-tool-form.component";
import { BackgroundFormComponent } from "./components/forms/background-form/background-form.component";
import { ElementFormComponent } from "./components/forms/element-form.component";
import { TextFormComponent } from "./components/forms/text-form/text-form.component";
import { QrcodeFormComponent } from './components/forms/qrcode-form/qrcode-form.component';
import { ImageFormComponent } from "./components/forms/image-form/image-form.component";
import { AnimationFormComponent } from "./components/forms/animation-form/animation-form.component";
import { SizeDetectorDirective } from "./directives/size-detector.directive";
import { SafeHtmlPipe } from "./pipes/safe-html-pipe";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSliderModule,
    MatSidenavModule,
    MatSortModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDividerModule,
    MatTableModule,
    FileUploadModule,
    SliderModule,
    AngularDraggableModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    ScrollToModule.forRoot()
  ],
  exports: [TranslateModule],
  declarations: [
    SmartdesignerAppComponent,
    StageComponent,
    PageButtonComponent,
    PageComponent,
    LayerComponent,
    GroupComponent,
    TextElementComponent,
    EmailElementComponent,
    ImageElementComponent,
    ClickStopPropagationDirective,
    SaveProductDirective,
    SaveCloseComponent,
    SdDialogErrorsDialog,
    SdDialogWarningsDialog,
    SdDialogInstructionDialog,
    ImageToolComponent,
    TextFormComponent,
    QrcodeFormComponent,
    ImageFormComponent,
    BackgroundFormComponent,
    ElementFormComponent,
    ImageToolFormComponent,
    AnimationFormComponent,
    SizeDetectorDirective,
    SdDialogComponent,
    SafeHtmlPipe
  ],
  providers: [
    httpInterceptorProviders,
    CryptoService,
    SmartdesignerService,
    AuthService,
    ApiService,
    ElementService,
    LayerService,
    ConfigurationService,
    ProductService,
    SelectionService,
    ImageService,
    FormService,
    ZoomService,
    ImageToolService
  ],
  entryComponents: [
    SdDialogComponent,
    ImageToolFormComponent,
    SdDialogErrorsDialog,
    SdDialogWarningsDialog,
    SdDialogInstructionDialog
  ],
  bootstrap: [SmartdesignerAppComponent]
})
export class SmartdesignerModule {}

import { AbstractValidator } from './abstract';
import { ResolutionPermission } from './../resolution-permission';
import { Pictorial } from './../pictorial';

export class ResolutionValidator extends AbstractValidator {

  pictorial: Pictorial;

  /**
   * Setting needed values in the constructor for the validator
   *
   * @param {Pictorial} resolution Image resolution
   * @param {ResolutionPermission} resolutionPermission Rule for the resolution
   */
  constructor(pictorial: Pictorial) {
    super();

    this.pictorial = pictorial;
  }

  /**
   * Run validation in the class
   *
   * @return {boolean} Returns boolean based on validness
   */
  run(): boolean {
    if (! this.pictorial.ResolutionPermission() || ! this.pictorial.Resolution()) {
      return true;
    }

    let unvalid: boolean = false;
    let resolutionMargin: number = 5;
    if (this.pictorial.ResolutionPermission().getLow() >= this.pictorial.Resolution()) {
      this.setError('resolution', 'VALIDATION.ERROR.LOW_RES', this.pictorial.ElementName());
      unvalid = true;
    } else if (this.pictorial.ResolutionPermission().getHigh() - resolutionMargin > this.pictorial.Resolution()) {
      this.setWarning('resolution', 'VALIDATION.WARNING.RESOLUTION', this.pictorial.ElementName());
      unvalid = true;
    }

    return (! unvalid);
  }
}

import { Layer } from './../layer';
import { LayerBuilderInterface } from './layer-builder.type';
import { Element } from './../element/element.type';
import { ElementBuilder } from './../builder/element-builder';
import { Color } from './../../common/color.type';

export class LayerBuilder implements LayerBuilderInterface {
  private id: number;
  private key: string;
  private width: number;
  private height: number;
  private xCoordinate: number;
  private yCoordinate: number;
  private zCoordinate: number;
  private helpText: string;
  private published: boolean;
  private creationDate: number;
  private modificationDate: number;
  private backgroundColor: Color;
  private permissions: any;
  private elements: Array<Element>;
  private attributes: any;

  constructor() {}

  /**
   * Get Id
   *
   * @return {number} Returns the layer Id
   */
  get Id() {
    return this.id;
  }

  /**
   * Set Id
   *
   * @param {number} id Layer Id
   * @return {LayerBuilder}
   */
  setId(id: number) {
    this.id = id;
    return this;
  }

  /**
   * Get key
   *
   * @return {string} Returns the layer key
   */
  get Key() {
    return this.key;
  }

  /**
   * Set key
   *
   * @param {string} key Layer key
   * @return {LayerBuilder}
   */
  setKey(key: string) {
    this.key = key;
    return this;
  }

  /**
   * Get width
   *
   * @return {number} Returns the layer width
   */
  get Width() {
    return this.width;
  }

  /**
   * Set width
   *
   * @param {number} width Layer width
   * @return {LayerBuilder}
   */
  setWidth(width: number) {
    this.width = width;
    return this;
  }

  /**
   * Get height
   *
   * @return {number} Returns the layer height
   */
  get Height() {
    return this.height;
  }

  /**
   * Set height
   *
   * @param {number} height Height
   * @return {LayerBuilder}
   */
  setHeight(height: number) {
    this.height = height;
    return this;
  }

  /**
   * Get X coordinate
   *
   * @return {number} Returns the layer X coordinate
   */
  get XCoordinate() {
    return this.xCoordinate;
  }

  /**
   * Set X coordinate
   *
   * @param {number} coordinate Layer X coordinate
   * @return {LayerBuilder}
   */
  setXCoordinate(coordinate: number) {
    this.xCoordinate = coordinate;
    return this;
  }

  /**
   * Get Y coordinate
   *
   * @return {number} Returns the layer Y coordinate
   */
  get YCoordinate() {
    return this.yCoordinate;
  }

  /**
   * Set Y coordinate
   *
   * @param {number} coordinate Layer Y coordinate
   * @return {LayerBuilder}
   */
  setYCoordinate(coordinate: number) {
    this.yCoordinate = coordinate;
    return this;
  }

  /**
   * Get Z coordinate
   *
   * @return {number} Returns the layer Z coordinate
   */
  get ZCoordinate() {
    return this.zCoordinate;
  }

  /**
   * Set Z coordinate
   *
   * @param {number} coordinate Layer Z coordinate
   * @return {LayerBuilder}
   */
  setZCoordinate(coordinate: number) {
    this.zCoordinate = coordinate;
    return this;
  }

  /**
   * Is Published
   *
   * @return {boolean} Returns Published value
   */
  get Published() {
    return this.published;
  }

  /**
   * Set Published
   *
   * @param {boolean} published Published value
   * @return {LayerBuilder}
   */
  setPublished(published: boolean) {
    this.published = published;
    return this;
  }

  /**
   * Get Creation Date
   *
   * @return {number} Returns the Creation date timestamp
   */
  get CreationDate() {
    return this.creationDate;
  }

  /**
   * Set Creation Date
   *
   * @param {number} date CreationDate
   * @return {LayerBuilder}
   */
  setCreationDate(date: number) {
    this.creationDate = date;
    return this;
  }

  /**
   * Get modification date
   *
   * @return {number} Returns the modification date timestamp
   */
  get ModificationDate() {
    return this.modificationDate;
  }

  /**
   * Set Modification date
   *
   * @param {number} date Modification date
   * @return {LayerBuilder}
   */
  setModificationDate(date: number) {
    this.modificationDate = date;
    return this;
  }

  /**
   * Get elements
   *
   * @return {Array<Element>} Returns the layer elements
   */
  get Elements() {
    return this.elements;
  }

  /**
   * Set elements
   *
   * @param {Array<any>} elements Elements
   * @return {LayerBuilder}
   */
  setElements(elements: Array<any>) {
    let layerElements = [];
    for (let element of elements) {
      if ('classInstance' in element) {
        layerElements.push(element);
      } else {
        layerElements.push(ElementBuilder.build(element));
      }
    }
    this.elements = layerElements;
    return this;
  }

  /**
   * Get background color
   *
   * @return {Color} Returns the layout background color
   */
  get BackgroundColor() {
    return this.backgroundColor;
  }

  /**
   * Set background color
   *
   * @param {Color} color Layout background color
   * @return {LayerBuilder}
   */
  setBackgroundColor(color: Color) {
    this.backgroundColor = color;
    return this;
  }

  /**
   * Get help text
   *
   * @return {string} Returns the layer help text
   */
  get HelpText() {
    return this.helpText;
  }

  /**
   * Set help text
   *
   * @param {string} text Layer help text
   * @return {LayerBuilder}
   */
  setHelpText(text: string) {
    this.helpText = text;
    return this;
  }

  /**
   * Get Permissions
   *
   * @return {any} Returns the Permissions
   */
  get Permissions() {
    return this.permissions;
  }

  /**
   * Set Permissions
   *
   * @param {any} permissions Permissions
   * @return {LayerBuilder}
   */
  setPermissions(permissions: any) {
    this.permissions = permissions;
    return this;
  }

  /**
   * Get attributes
   *
   * @return {any} Returns the attributes
   */
  get Attributes() {
    return this.attributes;
  }

  /**
   * Set attributes
   *
   * @param {any} attributes Attributes
   * @return {LayoutBuilder}
   */
  setAttributes(attributes: any) {
    this.attributes = attributes;

    return this;
  }

  /**
   * Build layer from class data
   *
   * @return {Layer} Returns layer object
   */
  build(): Layer {
    return new Layer(
      this.Id,
      this.Key,
      this.Width,
      this.Height,
      this.XCoordinate,
      this.YCoordinate,
      this.ZCoordinate,
      this.HelpText,
      this.BackgroundColor,
      this.Permissions,
      this.Elements,
      this.Attributes
    );
  }

  /**
   * Populate class data from data input
   *
   * @param {any} data Data object
   * @return {LayerBuilderInterface} Returns self
   */
  populate(data: any): LayerBuilderInterface {
    this.setId(data.id);
    this.setKey(data.key);
    this.setWidth(Number(data.width));
    this.setHeight(Number(data.height));
    this.setXCoordinate(Number(data.xCoordinate));
    this.setYCoordinate(Number(data.yCoordinate));
    this.setZCoordinate(Number(data.zCoordinate));
    this.setHelpText(data.helpText);
    this.setBackgroundColor(data.backgroundColor);
    this.setPermissions(data.permissions);
    this.setElements(data.elements);
    this.setAttributes(data.attributes);

    return this;
  }
}

import { StatePermissionInterface } from './state-permission.type';
import { PermissionState } from './permission-state';

export class StatePermission implements StatePermissionInterface
{
  permission: PermissionState;

  constructor(permission:any) {
    switch (permission) {
      case 'open':
        this.permission = PermissionState.open;
        break;
      case 'consistent':
        this.permission = PermissionState.consistent;
        break;
      case 'locked':
      default:
          this.permission = PermissionState.locked;
          break;
    }
  }

  /**
   * Can an action be exuted based on the permission
   *
   * @return {boolean}
   */
  public canChange(): boolean {
    if (this.Permission() === PermissionState.locked) {
      return false;
    }

    return true;
  }

  /**
   * Get permission
   *
   * @return {PermissionState} Returns the permission
   */
  public Permission(): PermissionState {
    return this.permission;
  }
}

import { BaseProductElement } from './base-product-element';
import { ImageElementInterface } from './../../template/element/image-element.type';
import { ProductLayerInterface } from './../product-layer.type';

import { ProductElement } from './product-element.type';
import { ProductImageElementInterface } from './product-image-element.type';
import { ProductImageElementOut } from './product-image-element-out.type';
import { Element } from './../../template/element/element.type';
import { Image } from './../../common/image.type';
import { ImageHelper } from './../../common/image-helper';
import { ResolutionPermission } from './../../common/resolution-permission';
import { RepositionPermission } from './../../common/reposition-permission';
import { ResolutionValidator } from './../../common/validator/resolution-validator';
import { Validator } from './../../common/validator/validator.type';

import { ContentAdapter } from './content-adapter';

export class ProductImageElement extends BaseProductElement implements ProductImageElementInterface {

    public fitMode: boolean = false;
    public imageSrc: string;

    constructor(
      public defaultElement: ImageElementInterface,
      public id: number,
      public key: string,
      public width: number,
      public height: number,
      public image: Image,
      public elementType: string = 'image',
      public attributes: any,
      public xPos: number,
      public yPos: number,
      public scaledX: number,
      public scaledY: number
    ) {
        super(defaultElement, id, key, elementType, width, height, attributes);
    }

    /**
     * Update element content object from JSON
     *
     * @param {any} data HTML converted to JSON with Himalaya
     */
    update(data: any) {
      this.image = data.image;
      this.xPos = data.xPos;
      this.yPos = data.yPos;
      this.scaledX = data.scaledX;
      this.scaledY = data.scaledY;
      this.width = this.parseValidWidth(data.width);
      this.height = this.parseValidHeight(data.height);
      // Validate structure and content
      this.validate();
    }

    /**
     * Is element content empty?
     *
     * @return {boolean}
     */
    isContentEmpty(): boolean {
      return (! this.image || ! this.image.id);
    }

    /**
     * Get element validators
     *
     * @return {Array<Validator>} Returns an array of validators
     */
    Validators(): Array<Validator> {
       let validators = super.Validators();
       if (this.canImplement()) {
         validators.push(new ResolutionValidator(this));
       }

       return validators;
    }

    /**
     * Get product element data.
     * This data is used for saving this object to the API.
     *
     * @return {ProductImageElementOut} Returns the product element data for the API
     */
    getData(): ProductImageElementOut {
      let data:ProductImageElementOut = <any>{};
      data.id = this.id;
      data.width = this.width;
      data.height = this.height;
      data.attributes = this.attributes;
      data.defaultElement = (this.defaultElement !== undefined) ? this.defaultElement.id : undefined;
      data.imageId = (this.image) ? this.image.id : undefined;
      data.elementType = this.elementType;
      data.xPos = this.xPos;
      data.yPos = this.yPos;
      data.scaledX = this.scaledX;
      data.scaledY = this.scaledY;

      return data;
    }

    /**
     * Image must keep aspect ratio?
     *
     * @return {boolean}
     */
    KeepAspectRatio(): boolean {
      return (this.defaultElement) ? this.defaultElement.keepAspectRatio : false;
    }

    /**
     * Image must fit given area?
     *
     * @return {boolean}
     */
    FitArea(): boolean {
      return (this.defaultElement) ? this.defaultElement.fitArea : false;
    }

    /**
     * Removes the element image and sets position/scale values to default
     *
     * @return {void}
     */
    clearImage() {
      this.image = undefined;
      this.imageSrc = undefined;
      this.xPos = 0;
      this.yPos = 0;
      this.scaledX = 1;
      this.scaledY = 1;
    }

    /**
     * Returns the pictorial element's image
     *
     * @return {Image} Returns the element image if defined
     */
    Image(): Image {
      return this.image;
    }
    /**
     * Get percentage of scale on given axis or if not
     * specified on the longer side.
     *
     * @param {string} axis Scale value on axis [x, y]. Optional.
     * @return {number} Returns the scale value
     */
    ScaleValue(axis?: string): number {
      if (! this.FitArea()) {
        return 1;
      }
      let scaleAxis: string = (! axis) ? ((this.Format() == 'landscape') ? 'x' : 'y')  : axis;

      return (scaleAxis === 'x') ? this.scaledX : this.scaledY;
    }

    /**
     * Return the image resolution on given axis or if not
     * specified on the longer side.
     *
     * @param {string} axis Scale value on axis [x, y]. Optional.
     * @return {number} Return the image resolution
     */
    Resolution(axis?: string): number {
      return ImageHelper.calcResolution(this, axis);
    }

    /**
     * Get image layout format
     *
     * @return {string} Returns the layout format [portrait, landscape, square]
     */
    Format(): string {
      if (this.width > this.height) {
        return "landscape";
      } else if (this.width == this.height) {
        return "square";
      }

      return "portrait";
    }

    /**
     * Returns rules concerning the resolution of the image
     *
     * @return {ResolutionPermission}
     */
    ResolutionPermission(): ResolutionPermission {
      return this.defaultElement.ResolutionPermission();
    }

    /**
     * Can user resize the image
     *
     * @return {boolean}
     */
    canScale(): boolean {
      return this.defaultElement.canScale() || false;
    }

    /**
     * Can user reposition/move the image
     *
     * @return {boolean}
     */
    canReposition(): boolean {
      return this.defaultElement.canReposition() || false;
    }

    isQrCode(): boolean {
      return this.defaultElement.isQrCode();
    }

    /**
     * Returns the image original DPI on given axis or if not
     * specified on the longer side.
     *
     * @param {string} axis DPI on axis [x, y]. Optional.
     * @return {number} Returns the DPI/PPI
     */
    OriginalDpi(axis?: string): number {
      let dpiAxis: string = (! axis) ? ((this.Format() == 'landscape') ? 'x' : 'y')  : axis;
      if (! this.Image()) {
        return undefined;
      }

      return this.Image().resolutions[dpiAxis];
    }

    /**
     * Get element human readable name
     *
     * @return {string}
     */
    public ElementName(): string {
      return super.ElementName();
    }
}

import { Permission } from './permission.type';

export class FontColorPermission implements Permission
{
  permission: any;

  constructor(permission:any) {
    this.permission = permission;
  }

  /**
   * Can an action be exuted based on the permission
   *
   * @return {boolean}
   */
  public canChange(): boolean {
    if (this.Permission().rule === 'locked') {
      return false;
    }

    return true;
  }

  /**
   * Get permission value
   *
   * @return {any} Returns the permission value
   */
  public Permission(): any {
    return this.permission;
  }

  /**
   * Is the font color restricted to pre-defined colors
   *
   * @return {boolean} Returns true if any selectable colors are set
   */
  public isRestricted(): boolean {
    return (this.Colors().length > 0);
  }

  /**
   * Get selectable colors for the font color. No value == any color
   *
   * @param {string} colorspace Parameter for color space [rgb, cmyk, pantone]
   * @return {any}
   */
  public getColors(colorspace: string): any {
    let colors = this.Colors();
    let values = [];
    for (let item of colors) {
      if (item[colorspace]) {
        if (colorspace === 'rgb') {

          let rgb = item[colorspace];

          values.push(this.rgbToHex(rgb));
        } else {
          values.push(item[colorspace]);
        }
      }
    }

    return values;
  }

  /**
   * Returns the possible selectanle colors for the resource background
   *
   * @return {any}
   */
  public Colors(): any {
    return this.Permission().colors;
  }

  /**
   * Converting RGB color string to HEX
   *
   * @param {string} color Color string
   * @return {string} Returns the HEX if possible
   */
  protected rgbToHex(color: string) {
    color = ""+ color;
    if (! color || color.indexOf("rgb") < 0) {
        return color;
    }

    if (color.charAt(0) == "#") {
        return color;
    }

    var nums = /(.*?)rgb\((\d+),\s*(\d+),\s*(\d+)\)/i.exec(color),
        r = parseInt(nums[2], 10).toString(16),
        g = parseInt(nums[3], 10).toString(16),
        b = parseInt(nums[4], 10).toString(16);
    const rgbToHexFunc = (r, g, b) => '#' + [r, g, b].map(x => {
      const hex = x.toString(16)
      return hex.length === 1 ? '0' + hex : hex
    }).join('');

    return rgbToHexFunc(r, g, b);
  }
}

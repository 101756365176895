import { Element } from './element/element.type';
import { Abstract } from './abstract';
import { ResizePermission } from './../common/resize-permission';
import { Color } from './../common/color.type';

export class Layer extends Abstract {

    constructor(
      public id: number,
      public key: string,
      public width: number,
      public height: number,
      public xCoordinate: number,
      public yCoordinate: number,
      public zCoordinate: number,
      public helpText: string,
      public bgColor: Color,
      public permissions: any,
      public elements: Array<Element> = [],
      public attributes: any
    ) {
      super(permissions);
    }

    /**
     * Set layer elements
     *
     * @param {Array<Element>} elements Layer elements in an array
     * @return void
     */
    setElements(elements: Array<Element>): void {
        this.elements = elements;
    }

    /**
     * Can current user implement the layer
     *
     * @return boolean
     */
    canImplement(): boolean {
      return this.permissions.implement || false;
    }

    /**
     * Can current user view the layer
     *
     * @return boolean
     */
    canView(): boolean {
      return this.permissions.view || false;
    }

    /**
     * Can current user delete the layer
     *
     * @return boolean
     */
    canDelete(): boolean {
      return this.permissions.delete || false;
    }
}

import { Element } from './../element/element.type';
import { EmailElement } from './../element/email-element';
import { TextElementInterface } from './../element/text-element.type';
import { TextElement } from './../element/text-element';
import { EmailElementInterface } from './../element/email-element.type';
import { GroupElementInterface } from './../element/group-element.type';
import { GroupElement } from './../element/group-element';
import { ImageElementInterface } from './../element/image-element.type';
import { ImageElement } from './../element/image-element';

export class ElementBuilder {
  /**
   * Build product element from class data
   *
   * @param {any} data Element data
   * @return {Element} Returns Element object
   */
  static build(data: any): Element {
    // Resolve correct build -method
    let dataType = data.elementType;
    let buildMethod =  'build' + dataType[0].toUpperCase() + dataType.slice(1);
    if (typeof (this as any)[buildMethod] === 'function') {
      return this[buildMethod](data);
    }
  }

  /**
   * Build element from class data
   *
   * @param {any} data
   * @return {TextElementInterface} Returns text element object
   */
  static buildText(data: any): TextElementInterface {
    return new TextElement(
      data.id,
      data.key,
      data.elementType,
      data.required,
      data.label,
      data.width,
      data.height,
      data.helpText,
      data.default,
      data.placeholder,
      data.attributes,
      data.permissions,
      data.fonts,
      data.defaultFontSize,
      data.defaultAlign,
      data.maxRows,
      data.paragraphFormats,
      data.formId,
      data.formIndex,
      data.formScale
    );
  }

  /**
   * Build element from class data
   *
   * @return {GroupElementInterface} Returns group element object
   */
  static buildGroup(data: any): GroupElementInterface {
    let groupElements = [];
    for (let element of data.elements) {
      groupElements.push(this.build(element));
    }

    return new GroupElement(
      data.id,
      data.key,
      data.elementType,
      data.label,
      data.width,
      data.height,
      data.attributes,
      data.helpText,
      data.backgroundColor,
      data.permissions,
      groupElements
    );
  }

  /**
   * Build element from class data
   *
   * @param {any} data
   * @return {EmailElementInterface} Returns email element object
   */
  static buildEmail(data: any): EmailElementInterface {
    return new EmailElement(
      data.id,
      data.key,
      data.elementType,
      data.required,
      data.label,
      data.width,
      data.height,
      data.helpText,
      data.default,
      data.placeholder,
      data.attributes,
      data.permissions,
      data.fonts,
      data.defaultFontSize,
      data.defaultAlign,
      data.paragraphFormats,
      data.formId,
      data.formIndex,
      data.formScale
    );
  }

  /**
   * Build element from class data
   *
   * @param {any} data
   * @return {ImageElementInterface} Returns image element object
   */
  static buildImage(data: any): ImageElementInterface {
    return new ImageElement(
      data.id,
      data.key,
      data.elementType,
      data.required,
      data.label,
      data.width,
      data.height,
      data.helpText,
      data.image,
      data.attributes,
      data.permissions,
      data.keepAspectRatio,
      data.fitArea,
      data.xPos,
      data.yPos,
      data.scaled,
      data.formId,
      data.formIndex
    );
  }
}

import { Validator, ValidatorMessage, ValidatorMessageType } from './validator.type';
import { ResolutionPermission } from './../resolution-permission';

export abstract class AbstractValidator implements Validator {

  protected messages: Array<ValidatorMessage> = [];

  /**
   * Run validation in the class
   *
   * @return {boolean} Returns boolean based on validness
   */
  public abstract run(): boolean;

  /**
   * Set an error to validator errors
   *
   * @param {ValidatorMessageType} type Validator message type
   * @param {string} key Validator message key
   * @param {string} description Validator message
   * @param {string} label Element label
   * @return {void}
   */
  setMessage(type: ValidatorMessageType, key: string, description: string, label: string): void {
    // try to find a message with the same key and type
    let message = this.Message(type, key);
    // If not found, add a new message
    if (! message) {
       this.messages.push(<ValidatorMessage>{type: type, key: key, message: description, label: label});
       return ;
    }
    message.message = description;
  }

  /**
   * Set a warning to validator messages
   *
   * @param {string} key Warning Key
   * @param {string} message Warning message
   * @param {string} label Element label
   */
  setWarning(key: string, message: string, label: string): void {
    this.setMessage(ValidatorMessageType.warning, key, message, label);
  }

  /**
   * Set an error to validator messages
   *
   * @param {string} key Error Key
   * @param {string} message Error message
   * @param {string} label Element label
   */
  setError(key: string, message: string, label: string): void {
    this.setMessage(ValidatorMessageType.error, key, message, label);
  }

  /**
   * Returns validator warnings
   *
   * @return {Array<ValidatorMessage>}
   */
  Warnings(): Array<ValidatorMessage> {
    let messages = [];
    for (let message of this.Messages(ValidatorMessageType.warning)) {
      messages.push(message.message);
    }

    return messages;
  }

  /**
   * Returns validator errors
   *
   * @return {Array<ValidatorMessage>}
   */
  Errors(): Array<ValidatorMessage> {
    let messages = [];
    for (let message of this.Messages(ValidatorMessageType.error)) {
      messages.push(message.message);
    }

    return messages;
  }

  /**
   * Returns all validator messages, warnings and errors
   *
   * @param {ValidatorMessageType} type
   * @return {Array<ValidatorMessage>} Returns all validator messages
   */
  Messages(type?: ValidatorMessageType): Array<ValidatorMessage> {
    let messages: Array<ValidatorMessage> = [];
    for (let message of this.messages) {
      if (! type || type === message.type) {
        messages.push(message);
      }
    }

    return messages;
  }

  /**
   * Get an error by key from element errors
   *
   * @param {string} key Key of the error
   * @return {any} Returns the error
   */
  protected Message(type: ValidatorMessageType, key: string): ValidatorMessage {
    for (let message of this.Messages(type)) {
      if (message.key === key) {
        return message;
      }
    }

    return undefined;
  }
}

import { Permission } from './permission.type';

export class ResizePermission implements Permission
{
  permission: boolean;

  constructor(permission:any) {
    this.permission = permission;
  }

  /**
   * Can an action be exuted based on the permission
   *
   * @return {boolean}
   */
  public canChange(): boolean {
    return this.canResize();
  }

  /**
   * Get permission value
   *
   * @return {any} Returns the permission value
   */
  public Permission(): any {
    return this.permission;
  }

  /**
   * Can the resource be resized on direction
   *
   * @param {string} direction Optional parameter for resize direction [horizontal, vertical]
   * @return boolean
   */
  public canResize(direction?: string): boolean {
    let horizontal = this.Permission().horizontally || false;
    if (direction === 'horizontal') {
      return horizontal;
    }
    let vertical = this.Permission().vertically || false;
    if (direction === 'vertical') {
      return vertical;
    }

    return (horizontal || vertical);
  }

  /**
   * Returns the possible maximum width for the resource
   * undefined = unlimited
   *
   * @return number|undefined
   */
  public getMaxWidth(): number | undefined {
    return this.Permission().maxWidth;
  }

  /**
   * Returns the possible maximum height for the resource
   * undefined = unlimited
   *
   * @return number|undefined
   */
  public getMaxHeight(): number | undefined {
    return this.Permission().maxHeight;
  }

  /**
   * Returns the possible minimum width for the resource
   * undefined = 0
   *
   * @return number
   */
  public getMinWidth(): number {
    return (! this.Permission().minWidth) ? 0 : this.Permission().minWidth;
  }

  /**
   * Returns the possible minimum height for the resource
   * undefined = 0
   *
   * @return number|undefined
   */
  public getMinHeight(): number {
    return (! this.Permission().minHeight) ? 0 : this.Permission().minHeight;
  }

  /**
   * Returns the one notch unit for height
   * undefined = dynamic
   *
   * @return number|undefined
   */
  public getNotchHeight(): number | undefined {
    return this.Permission().notchHeight;
  }

  /**
   * Returns the one notch unit for width
   * undefined = dynamic
   *
   * @return number|undefined
   */
  public getNotchWidth(): number | undefined {
    return this.Permission().notchWidth;
  }
}

import { Aggregator } from './../aggregator';
import { Iterator } from './../iterator';
import { Validator } from './validator.type';
import { ValidatorIterator } from './validator-iterator';

export class ValidatorAggregator implements Aggregator {
  private validators: Array<Validator> = [];

  constructor(validators: Array<Validator>) {
    this.validators = validators;
  }

  /**
   * Create an iterator
   *
   * @return {Iterator} Returns the created iterator
   */
  public createIterator(): ValidatorIterator {
    return new ValidatorIterator(this.validators);
  }
}

import { BaseElement } from './base-element';
import { ImageElementInterface } from './image-element.type';
import { Image } from './../../common/image.type';
import { ImageHelper } from './../../common/image-helper';
import { ResolutionPermission } from './../../common/resolution-permission';
import { RepositionPermission } from './../../common/reposition-permission';
import {QrcodePermission} from '../../common/qrcode-permission';

export class ImageElement extends BaseElement implements ImageElementInterface {

    constructor(
      public id: number,
      public key: string,
      public elementType: string,
      public required: boolean,
      public label: string,
      public width: number,
      public height: number,
      public helpText: string,
      public image: Image,
      public attributes: any,
      public permissions: any,
      public keepAspectRatio: boolean,
      public fitArea: boolean,
      public xPos: number,
      public yPos: number,
      public scaled: number,
      public formId?: string,
      public formIndex?: number
    ) {
        super(id, key, elementType, required, label, width, height, helpText, attributes, permissions, formId, formIndex);
    }

    /**
     * Returns the pictorial element's image
     *
     * @return {Image} Returns the element image if defined
     */
    Image(): Image {
      return this.image;
    }
    /**
     * Get percentage of scale on given axis or if not
     * specified on the longer side.
     *
     * @param {string} axis Scale value on axis [x, y]. Optional.
     * @return {number} Returns the scale value
     */
    ScaleValue(axis?: string): number {
      return this.scaled;
    }

    /**
     * Return the image resolution on given axis or if not
     * specified on the longer side.
     *
     * @param {string} axis Scale value on axis [x, y]. Optional.
     * @return {number} Return the image resolution
     */
    Resolution(axis?: string): number {
      return ImageHelper.calcResolution(this);
    }

    /**
     * Get image layout format
     *
     * @return {string} Returns the layout format [portrait, landscape, square]
     */
    Format(): string {
      if (this.width > this.height) {
        return "landscape";
      } else if (this.width == this.height) {
        return "square";
      }

      return "portrait";
    }

    /**
     * Returns rules concerning the resolution of the image
     *
     * @return {ResolutionPermission}
     */
    ResolutionPermission(): ResolutionPermission {
      let resolutionPermission: ResolutionPermission = <ResolutionPermission>this.Permission('resolution');

      return resolutionPermission;
    }

    /**
     * Returns rules concerning the repositioning of the image
     *
     * @return {RepositionPermission}
     */
    RepositionPermission(): RepositionPermission {
      let repositionPermission: RepositionPermission = <RepositionPermission>this.Permission('reposition');

      return repositionPermission;
    }

    /**
     * Can user resize the image
     *
     * @return {boolean}
     */
    canScale(): boolean {
      return this.RepositionPermission().canRescale();
    }

    /**
     * Can user reposition/move the image
     *
     * @return {boolean}
     */
    canReposition(): boolean {
      return this.RepositionPermission().canReposition();
    }

    isQrCode(): boolean {
      const permission: QrcodePermission = <QrcodePermission>this.Permission('qrcode');
      return permission.canChange();
    }

    /**
     * Returns the image original DPI on given axis or if not
     * specified on the longer side.
     *
     * @param {string} axis DPI on axis [x, y]. Optional.
     * @return {number} Returns the DPI/PPI
     */
    OriginalDpi(axis?: string): number {
      let dpiAxis: string = (! axis) ? ((this.Format() == 'landscape') ? 'x' : 'y')  : axis;
      if (! this.Image()) {
        return undefined;
      }

      return this.Image().resolutions[dpiAxis];
    }

    /**
     * Get Image element calculated width
     *
     * @return {number} Returns the calculated width
     */
    CalcWidth(): number {
      let scaledValue = (! this.image) ? 0 : this.image.dimensions.width * this.scaled;

      // Calculate height if not pre-defined
      let calcValue = (! this.width) ? scaledValue : this.width;
      calcValue = (calcValue > this.getMaxWidth()) ? this.getMaxWidth() : calcValue;
      calcValue = (calcValue < this.getMinWidth()) ? this.getMinWidth() : calcValue;

      return calcValue;
    }

    /**
     * Get Image element calculated height
     *
     * @return {number} Returns the calculated height
     */
    CalcHeight(): number {
      let scaledValue = (! this.image) ? 0 : this.image.dimensions.height * this.scaled;

      // Calculate height if not pre-defined
      let calcValue = (! this.height) ? scaledValue : this.height;
      calcValue = (calcValue > this.getMaxHeight()) ? this.getMaxHeight() : calcValue;
      calcValue = (calcValue < this.getMinHeight()) ? this.getMinHeight() : calcValue;

      return calcValue;
    }

    /**
     * Get element human readable name
     *
     * @return {string}
     */
    public ElementName(): string {
      return this.label;
    }
}

import {
  Component,
  Inject,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import { LayerService } from "./../../services/layer.service";
import { SelectionService } from "./../../services/selection.service";
import { ZoomService } from "./../../services/zoom.service";
import { Selectable } from "./../../models/common/selectable.type";
import { Formable } from "./../../models/common/formable.type";
import { FormableType } from "./../../models/common/formable-type";
import { Layer } from "./../../models/template/layer";
import { ProductLayer } from "./../../models/product/product-layer";
import { ProductElement } from "./../../models/product/element/product-element.type";
import { ProductLayerInterface } from "./../../models/product/product-layer.type";
import { StyleObject } from "./../../models/common/style-object.type";
import { Unit } from "./../../../shared/unit";
import { ResizePermission } from "./../../models/common/resize-permission";
import { Animator } from "./../../models/common/animator";

@Component({
  selector: "sd-layer",
  templateUrl: "./layer.component.html",
  styleUrls: ["./layer.component.scss"],
})
export class LayerComponent {
  @Input() layer: ProductLayer;
  @Input() previewing: boolean = false;
  @ViewChild("container") container;

  @Output() height: EventEmitter<number> = new EventEmitter<number>();
  @Output() width: EventEmitter<number> = new EventEmitter<number>();
  @Output() x: EventEmitter<number> = new EventEmitter<number>();
  @Output() y: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private layerService: LayerService,
    private selectionService: SelectionService,
    private zoomService: ZoomService,
    private elRef: ElementRef,
    private changeDetectionRef: ChangeDetectorRef
  ) {}

  /**
   * Selection handler. Called on click to edit icon.
   *
   * @return {void}
   */
  onSelection(event: any, selectable: Selectable) {
    if (this.previewing) {
      return;
    }
    this.selectionService.select(event, selectable);
  }

  /**
   * Return all elements that are readable
   *
   * @return {ProductElement[]}
   */
  Elements(): ProductElement[] {
    let elements = [];
    for (let element of this.layer.elements) {
      if (element.can("read")) {
        elements.push(element);
      }
    }

    return elements;
  }

  /**
   * Can current user update the layer
   *
   * @return boolean
   */
  isEditable(): boolean {
    return this.layer.can("implement");
  }

  /**
   * Is current element animated
   *
   * @return boolean
   */
  isAnimated(): boolean {
    return this.previewing && this.layer.isAnimetable();
  }

  /**
   * Should form be showed on layer.
   * Form is showed on layer if layer is selected and has no group elements as selected.
   *
   * @return {boolean}
   */
  showForm(): boolean {
    return (
      this.isEditable() &&
      this.selectionService.getSelectableLayer() === this.layer
    );
  }

  /**
   * Is current layer selected at the moment
   *
   * @return {boolean}
   */
  isSelected(): boolean {
    return this.selectionService.isSelected(this.layer);
  }

  /**
   * Set layer height to model from content's real height
   *
   * @param {number} value Element real height
   * @param {ProductElement} element Element which height have changed
   * @return {void}
   */
  setHeight(value: number, element: ProductElement) {
    setTimeout(() => {
      let size = this.getSize();
      let curValue = this.layer.height;
      if (size.height === curValue) {
        return;
      }
      this.layer.setHeight(size.height);
      if (curValue !== this.layer.height) {
        this.emitResize("height");
      }
    }, 0);
  }

  /**
   * Set layer width to model from content's real width
   *
   * @param {number} value Element real width
   * @param {ProductElement} element Element which width have changed
   * @return {void}
   */
  setWidth(value: number, element: ProductElement) {
    setTimeout(() => {
      let size = this.getSize();
      let curValue = this.layer.width;
      if (size.width === curValue) {
        return;
      }
      this.layer.setWidth(size.width);
      if (curValue !== this.layer.width) {
        this.emitResize("width");
      }
    }, 0);
  }

  /**
   * Emit detected resize
   *
   * @param {string} dimension Which dimension was resized: [width, height]
   * @return {void}
   */
  emitResize(dimension: string): void {
    if (dimension === "width") {
      this.width.emit(this.layer.width);
    } else if (dimension === "height") {
      this.height.emit(this.layer.height);
    }
    // Tell view to detect changes
    this.changeDetectionRef.detectChanges();
  }

  /**
   * Return element in-line styles for data binding
   *
   * @return {any} Returns the styles in an object
   */
  setStyles(): any {
    let resizePermission: ResizePermission = this.layer.layer
      ? <ResizePermission>this.layer.layer.Permission("resize")
      : undefined;
    let maxHeight =
      resizePermission && resizePermission.canResize("vertical")
        ? resizePermission.getMaxHeight()
        : this.layer.height;
    let maxWidth =
      resizePermission && resizePermission.canResize("horizontal")
        ? resizePermission.getMaxWidth()
        : this.layer.width;
    let minHeight =
      resizePermission && resizePermission.canResize("vertical")
        ? resizePermission.getMinHeight()
        : this.layer.height;
    let minWidth =
      resizePermission && resizePermission.canResize("horizontal")
        ? resizePermission.getMinWidth()
        : this.layer.width;

    let styles: StyleObject = {
      "maxWidth.mm": maxWidth,
      "maxHeight.mm": maxHeight,
      "minWidth.mm": minWidth,
      "minHeight.mm": minHeight,
      /*'height.mm': this.layer.height,
      'width.mm': this.layer.width,*/
      "background-color": this.layer.bgColor.rgb,
      "left.mm": this.layer.xCoordinate,
      "top.mm": this.layer.yCoordinate,
      zIndex: this.layer.zCoordinate,
    };
    if (this.layer.attributes.rotate) {
      styles["transform-origin"] = "0px";
      styles["transform"] =
        "rotate(" + this.layer.attributes.rotate.rotateDegree + "deg)";
    }

    if (this.previewing) {
      let animator = new Animator(this.layer);
      return { ...styles, ...animator.getAnimationStyles() };
    }

    return styles;
  }

  /**
   * Return element in-line styles for element container data binding
   *
   * @return {any} Returns the styles in an object
   */
  setContainerStyles(): any {
    let display = this.layer.justifyContent() == "initial" ? "block" : "flex";

    let styles: StyleObject = {
      display: display,
    };
    if (display == "flex") {
      styles["flex-wrap"] = "wrap";
    }
    if (this.layer.justifyContent() == "center-vertical") {
      styles["align-items"] = "center";
      styles["justify-content"] = "center";
      styles["height"] = this.layer.height + "mm";
    } else if (this.layer.justifyContent() == "flex-end-items") {
      styles["height"] = this.layer.height + "mm";
      styles["align-content"] = "flex-end";
    } else {
      styles["justify-content"] = this.layer.justifyContent();
    }
    return styles;
  }

  /**
   * Get layer computed size
   *
   * @return {any} Returns the layers dimensions
   */
  getSize(): any {
    let el = this.container.nativeElement;
    let height = el.scrollHeight;
    let width = el.scrollWidth;
    for (let child of el.getElementsByTagName("*")) {
      height = child.clientHeight > height ? child.clientHeight : height;
      width = child.clientWidth > width ? child.clientWidth : width;
    }
    let heightPx = Number(Unit.pxToMm(height)).toFixed(1);
    let widthPx = Number(Unit.pxToMm(width)).toFixed(1);
    return { height: heightPx, width: widthPx };
  }

  /**
   * Returns a tool tip for the layer
   *
   * @return {string} Returns tooltip text
   */
  getToolTip(): string {
    return (
      "Layer: " +
      this.layer.key +
      "{ w: " +
      Number(this.layer.width).toFixed(2) +
      " h: " +
      Number(this.layer.height).toFixed(2) +
      "}"
    );
  }
}

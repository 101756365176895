import { Layer } from './../layer';
import { Element } from './element.type';
import { Abstract } from './../abstract';
import { ResizePermission } from './../../common/resize-permission';

export class BaseElement extends Abstract implements Element  {

    public classInstance: boolean = true;
    public context: string = 'template';

    constructor(
      public id: number,
      public key: string,
      public elementType: string,
      public required: boolean = false,
      public label: string,
      public width: number,
      public height: number,
      public helpText: string,
      public attributes: any,
      public permissions: any,
      public formId?: string,
      public formIndex?: number
    ) {
      super(permissions);
    }

    /**
     * Is the element content required?
     *
     * @return {boolean}
     */
    isRequired(): boolean {
      return this.required;
    }

    /**
     * Can current user resize the element
     *
     * @param {string} direction Optional parameter for resize direction [horizontal, vertical]
     * @return boolean
     */
    canResize(direction?: string): boolean {
      let permission = this.getResizePermission();
      if (! permission) {
        return false;
      }

      return permission.canResize(direction);
    }

    /**
     * Returns the possible maximum width for the element
     * undefined = unlimited
     *
     * @return number|undefined
     */
    getMaxWidth(): number | undefined {
      if (! this.canResize('horizontal')) {
        return this.width;
      }

      let permission = this.getResizePermission();
      return permission.getMaxWidth();
    }

    /**
     * Returns the possible maximum height for the element
     * undefined = unlimited
     *
     * @return number|undefined
     */
    getMaxHeight(): number | undefined {
      if (! this.canResize('vertical')) {
        return this.height;
      }

      let permission = this.getResizePermission();
      return permission.getMaxHeight();
    }

    /**
     * Returns the possible minimum width for the element
     * undefined = 0
     *
     * @return number
     */
    getMinWidth(): number {
      if (! this.canResize('horizontal')) {
        return this.width;
      }

      let permission = this.getResizePermission();
      return permission.getMinWidth();
    }

    /**
     * Returns the possible minimum height for the element
     * undefined = 0
     *
     * @return number
     */
    getMinHeight(): number {
      if (! this.canResize('vertical')) {
        return this.height;
      }

      let permission = this.getResizePermission();
      return permission.getMinHeight();
    }

    /**
     * Returns the form item index
     *
     * @return {number} Returns form item index
     */
    public FormIndex(): number {
      return (this.formIndex == undefined) ? super.FormIndex() : this.formIndex;
    }

    /**
     * Returns the element resize permission if available
     *
     * @return {ResizePermission}
     */
    protected getResizePermission(): ResizePermission {
      return <ResizePermission>this.Permission('resize');
    }
}

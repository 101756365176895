import { Permission } from './permission.type';
import { StatePermission } from './state-permission';
import { BoolPermission } from './bool-permission';
import { ResizePermission } from './resize-permission';
import { ResolutionPermission } from './resolution-permission';
import { ScalePermission } from './scale-permission';
import { FontSizePermission } from './font-size-permission';
import { FontColorPermission } from './font-color-permission';
import { FontBackgroundColorPermission } from './font-background-color-permission';
import { BgColorPermission } from './bg-color-permission';
import { ClientApiPermission } from './client-api-permission';
import { AnimatePermission } from './animate-permission';
import { LineHeightPermission } from './line-height-permission';
import { LetterSpacingPermission } from './letter-spacing-permission';
import { RepositionPermission } from './reposition-permission';
import { QrcodePermission } from './qrcode-permission';
import { ExtraDataPermission } from './extra-data-permissions';
import { FormColorPermission } from './form-color-permission';

export class PermissionFactory
{
    /**
     * Creates a permission object from SD api data
     *
     * @param key
     * @param {any} permission Permission data
     * @return {Permission} Returns the permission class object
     */
    public static createPermission(key: string, permission: any): Permission {
      if (['fontBold', 'fontItalic', 'fontUnderline', 'strike', 'fontFamily', 'align', 'paragraphFormat'].indexOf(key) > -1) {
        return new StatePermission(permission);
      } else if (['implement', 'update', 'read', 'delete', 'lists', 'backgroundImage'].indexOf(key) > -1) {
        return new BoolPermission(permission);
      } else if (['resize'].indexOf(key) > -1) {
        return new ResizePermission(permission);
      } else if (['backgroundColor'].indexOf(key) > -1) {
        return new BgColorPermission(permission);
      } else if (['fontSize'].indexOf(key) > -1) {
        return new FontSizePermission(permission);
      } else if (['fontColor'].indexOf(key) > -1) {
        return new FontColorPermission(permission);
      } else if (['fontBgColor'].indexOf(key) > -1) {
        return new FontBackgroundColorPermission(permission);
      } else if (['scale'].indexOf(key) > -1) {
        return new ScalePermission(permission);
      } else if (['resolution'].indexOf(key) > -1) {
        return new ResolutionPermission(permission);
      } else if (['clientApi'].indexOf(key) > -1) {
        return new ClientApiPermission(permission);
      } else if (['animate'].indexOf(key) > -1) {
        return new AnimatePermission(permission);
      } else if (['lineHeight'].indexOf(key) > -1) {
        return new LineHeightPermission(permission);
      } else if (['letterSpacing'].indexOf(key) > -1) {
        return new LetterSpacingPermission(permission);
      } else if (['reposition'].indexOf(key) > -1) {
        return new RepositionPermission(permission);
      } else if (['qrcode'].indexOf(key) > -1) {
        return new QrcodePermission(permission);
      } else if (['extraData'].indexOf(key) > -1) {
        return new ExtraDataPermission(permission);
      } else if (['formColor'].indexOf(key) > -1){
        return new FormColorPermission(permission);
      }

      return new BoolPermission(false);
    }
}

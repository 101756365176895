import { Permission } from './permission.type';
import { Animation } from './animation';

export class AnimatePermission implements Permission
{
  permission: boolean;

  constructor(permission:any) {
    this.permission = permission;
  }

  /**
   * Can an action be exuted based on the permission
   *
   * @return {boolean}
   */
  public canChange(): boolean {
    return (this.Permission().update || false);
  }

  /**
   * Get permission value
   *
   * @return {any} Returns the permission value
   */
  public Permission(): any {
    return this.permission;
  }

  /**
   * Returns the class names of the allowed animations
   *
   * @return {Array<Animation>}
   */
  public Animations(): Array<Animation> {
    let animations: Array<Animation> = [];
    for (let animation of this.Permission().animations) {
      let animationId = <string>animation;
      let item = <Animation>Animation[animationId];
      animations.push(item);
    }

    return animations;
  }
}

import { Component, OnInit, Input, Output, ViewChild, ElementRef, NgZone, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { MatInputModule } from '@angular/material';
import { MatSort, MatTableDataSource } from '@angular/material';
import { ProductImageElement } from './../../../../models/product/element/product-image-element';
import { ProductService } from './../../../../services/product.service';
import { ImageService } from './../../../../services/image.service';
import { ConfigurationService } from './../../../../services/configuration.service';
import { NotificationService } from './../../../../../services/notification.service';
import { environment } from './../../../../../../environments/environment';
import { Image } from './../../../../models/common/image.type';
import { ClientImage } from './../../../../models/common/client-image.type';
import { ClientCategory } from './../../../../models/common/client-category.type';
import { ResizePermission } from './../../../../models/common/resize-permission';
import { ResolutionPermission } from './../../../../models/common/resolution-permission';
import { ValidatorMessage } from './../../../../models/common/validator/validator.type';
import { Unit } from './../../../../../shared/unit';
import { ProductElementBuilder } from './../../../../models/product/builder/product-element-builder';

@Component({
  selector: 'sd-image-tool-form',
  templateUrl: './image-tool-form.component.html',
  styleUrls: ['./image-tool-form.component.scss']
})
export class ImageToolFormComponent implements OnInit, OnDestroy {
  @Input() id: number;
  @Input() x: number = 0;
  @Input() y: number = 0;
  @Input() xPos: number = 0;
  @Input() yPos: number = 0;
  @Input() scaledX: number = 1;
  @Input() scaledY: number = 1;
  @Input() scaleMax: number = 2;
  @Input() scaleMin: number = 0.01;
  @Input() width: number = 0;
  @Input() height: number = 0;
  @Input() widthMax: number = 0;
  @Input() heightMax: number = 0;
  @Input() widthMin: number = 0;
  @Input() heightMin: number = 0;
  @Input() fitArea: boolean = false;
  @Input() image: Image;
  @Input() imageSrc: string;
  @Input() imageLabel: string;
  @Input() resizable: boolean = false;
  @Input() scalable: boolean = false;
  @Input() movable: boolean = false;
  @Input() showClientImageSelection: boolean = false;
  @Input() onlyClientImageSelection: boolean = false;
  @Input() errors: Array<ValidatorMessage> = [];
  @Input() warnings: Array<ValidatorMessage> = [];
  @Input() help: string;
  @Input() disabled: boolean = false;
  @Input() bleed: number = 0;

  @Input() uploadUri: string;
  @Input() parameters: any;
  @Input() clientParameters: any;

  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['thumbnail', 'name'];
  dataSource = new MatTableDataSource([]);

  close: Subject<boolean> = new Subject<boolean>();
  position: Subject<any> = new Subject<any>();
  scale: Subject<any> = new Subject<any>();
  clear: Subject<any> = new Subject<any>();
  uploadSuccess: Subject<any> = new Subject<any>();
  uploadError: Subject<any> = new Subject<any>();
  clientImageSelection: Subject<ClientImage> = new Subject<ClientImage>();

  public uploader: FileUploader;
  public hasBaseDropZoneOver:boolean = false;
  private imageLoading: boolean = false;
  private clientImagesLoading: boolean = false;
  private loadTimer: any;
  private imageChanged: boolean = false;

  public options: Object;

  private dragStartX: number;
  private dragStartY: number;
  private cropX: number;
  private cropY: number;

  clientImageSubscription: Subscription;
  private clientImages: Array<ClientImage>;
  private clientCategories: Array<ClientCategory>;
  openCategory:ClientCategory;

  constructor(
    private elRef: ElementRef,
    private zone: NgZone,
    private productService: ProductService,
    private imageService: ImageService,
    private notificationService: NotificationService,
    private configurationService: ConfigurationService,
    private changeDetectionRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initialize();
  }

  /**
   * Unsubscribe all listeners before destroyal
   */
  ngOnDestroy() {
    if (this.clientImageSubscription) {
      this.clientImageSubscription.unsubscribe();
    }
  }

  /**
   * Reload the form
   */
  public reload() {
    if (this.clientImageSubscription) {
      this.clientImageSubscription.unsubscribe();
    }
    if (this.uploader) {
      // Destroy  uploader with old data before creating a new one.
      this.uploader.destroy();
      this.uploader = undefined;
    }
    this.initialize();
  }

  /**
   * Initialize the form
   */
  protected initialize() {
    this.uploader = new FileUploader({
      autoUpload: true,
      url: this.uploadUri,
      authToken: this.configurationService.getAuthorizationToken(this.uploadUri),
      additionalParameter: this.parameters
    });
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);

    this.clientImageSubscription = this.imageService.clientImagesChange.subscribe((categories) => {
      this.clientCategories = categories;
      /*if (this.clientCategories.length == 1) {
        this.openCategory = this.clientCategories[0];
      }*/
      this.clientImagesLoading = false;
    });

    this.setDefaults();
  }

  /**
   * Set default values to properties
   */
  setDefaults() {
    this.cropX = this.xPos * this.CropScalePercentage();
    this.cropY = this.yPos * this.CropScalePercentage();

    if (! this.image || ! this.image.id) {
      this.loadClientImages();
    }
  }

  /**
   * On panel open event handler
   *
   * @param {ClientCategory} category Opened category
   */
  onPanelOpen(category:ClientCategory) {
    this.openCategory = category;
  }

  /**
   * On panel expand event handler
   *
   * @param {ClientCategory} category Opened category
   */
  onPanelExpand(category:ClientCategory) {
      this.dataSource = new MatTableDataSource(category.images);
      this.dataSource.sort = this.sort;
      this.changeDetectionRef.detectChanges();
  }

  /**
   * Returns the value for background-image property with selected image
   *
   * @return {string} background image property
   */
  BackgroundImageProperty(): string {
    if (this.imageSrc) {
      return 'url(' + this.imageSrc + ')';
    }

    return undefined;
  }

  /**
   * Returns the value for background-position property with selected image
   *
   * @return {string} background position property
   */
  BackgroundPositionProperty(): string {
    let xPos = this.cropX + 'mm';
    let yPos = this.cropY + 'mm';

    return xPos + ' ' + yPos;
  }

  /**
   * Returns the value for background-size property
   *
   * @return {string} background size property
   */
  BackgroundSizeProperty(): string {
    let width = this.ImageWidth() + 'mm';
    let height = this.ImageHeight() + 'mm';

    return width + ' ' + height;
  }

  /**
   * Returns the image y-position in cropper
   *
   * @return {number} Y-position
   */
  ImageTop(unit:string = 'mm'): number {
    return (unit == 'mm') ? this.cropY : Unit.mmToPx(this.cropY);
  }

  /**
   * Returns the image x-position in cropper
   *
   * @return {number} X-position
   */
  ImageLeft(unit:string = 'mm'): number {
    return (unit == 'mm') ? this.cropX : Unit.mmToPx(this.cropX);
  }

  /**
   * Returns image scaled width
   *
   * @return {number} Image size
   */
  ImageWidth(): number {
    return (! this.image) ? 0 : (this.scaledX * this.image.dimensions.width) * this.CropScalePercentage();
  }

  /**
   * Returns image scaled height
   *
   * @return {number} Image size
   */
  ImageHeight(): number {
    return (! this.image) ? 0 : (this.scaledY * this.image.dimensions.height) * this.CropScalePercentage();
  }

  /**
   * Percentage that is used to scale the image in the cropper
   * that is always fits to the crop area and is always shown in scale
   *
   * @return {void}
   */
   CropScalePercentage(){
      let elementSize = Unit.mmToPx(this.height);
      let cropAreaSize = this.FormHeight();

      let percentage = (cropAreaSize / elementSize);
      return (percentage > 1) ? 1 : percentage;
   }

  /**
   * Calculates the crop area width
   * area size x (area size x form area)
   *
   * @return {number} Crop area width
   */
  CropAreaWidth(): number {
    return (this.width * this.CropScalePercentage());
  }

  /**
   * Calculates the crop area height
   * are size x (area size x form area)
   *
   * @return {number} Crop area height
   */
  CropAreaHeight(): number {
    return (this.height * this.CropScalePercentage());
  }

  /**
   * Returns bleed width
   *
   * @return {number} Width of template bleed area
   */
  BleedWidth(): number {
      return (this.bleed * this.CropScalePercentage());
  }

  /**
   * Returns the form height
   *
   * @return {number} Form height
   */
  FormHeight(): number {
    let size = this.getFormSize();

    return size.height;
  }

  /**
   * Returns the form width
   *
   * @return {number} Form width
   */
  FormWidth(): number {
    let size = this.getFormSize();

    return size.width;
  }

  /**
   * Returns the image scale value
   *
   * @return {number}
   */
  Scaled(): number {
    return this.scaledX;
  }

  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }

  HelpText(): string {
    if (! this.help) {
      return '';
    }

    return this.help;
  }

  /**
   * On successful upload -handler
   * Updates the element image
   *
   * @param {FileItem} item File uploaded
   * @param {string} response API response object
   * @param {number} status HTTP status code
   * @param {ParsedResponseHeaders} headers HTTP response headers
   * @return {void}
   */
  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) {
    let json = JSON.parse(response);
    this.uploadSuccess.next(json);

    // Wait that image source is loaded by image component
    this.imageLoading = true;
    this.imageChanged = true;
    this.setDefaults();

    this.loadTimer = setTimeout(() => this.checkSource(), 100);
  }

  /**
   * On failed upload -handler
   * Pushes the error to the notification service
   *
   * @param {FileItem} item File uploaded
   * @param {string} response API response object
   * @param {number} status HTTP status code
   * @param {ParsedResponseHeaders} headers HTTP response headers
   * @return {void}
   */
  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) {
    let json = JSON.parse(response);
    this.uploadError.next(json);
  }

  /**
   * Returns the image position for the draggable directive
   *
   * @return Returns the x & y position
   */
  getImagePosition(): any
  {
    return {'x': this.ImageLeft('px'), 'y': this.ImageTop('px')};
  }

  /**
   * On drag start -handler
   *
   * @param {any} event Event params
   * @return {void}
   */
  onDragBegin(event:any) {
    this.dragStartX = event.getBoundingClientRect().x;
    this.dragStartY = event.getBoundingClientRect().y;
  }

  /**
   * On drag stop -handler
   *
   * @param {any} event Event params
   * @return {void}
   */
  onDragEnd(event:any, startX: number, startY: number) {
    let endX = event.getBoundingClientRect().x;
    let endY = event.getBoundingClientRect().y;
    let dragDiffX = (endX - startX);
    let dragDiffY = (endY - startY);

    if (dragDiffX < -2 || dragDiffX > -2 || dragDiffY < -2 || dragDiffY > -2) {
      this.moveImagePosition(dragDiffX, dragDiffY);
    }

    this.dragStartY = undefined;
    this.dragStartY = undefined;
  }

  /**
   * Move image position on drag area
   *
   * @param {number} dragDiffX Difference on X axis
   * @param {number} dragDiffY Difference on Y axis
   * @return {void}
   */
  moveImagePosition(dragDiffX: number, dragDiffY) {
    let xChange = dragDiffX / this.CropScalePercentage();
    this.xPos = this.xPos + Unit.pxToMm(xChange);
    this.cropX = this.cropX + Unit.pxToMm(dragDiffX);

    let yChange = dragDiffY / this.CropScalePercentage();

    this.yPos = this.yPos + Unit.pxToMm(yChange);
    this.cropY = this.cropY + Unit.pxToMm(dragDiffY);

    // Emit position change if changed
    this.position.next({x: this.xPos, y: this.yPos});
  }

  /**
   * Center image on area
   *
   * @param {any} event Click event data
   * @return {void}
   */
  centerImage(event: any) {
    if (this.disabled) {
      return ;
    }
    let centerX = (this.CropAreaWidth() - this.ImageWidth()) / 2;
    let centerY = (this.CropAreaHeight() - this.ImageHeight()) / 2;
    this.cropX = centerX;
    this.xPos = centerX / this.CropScalePercentage();
    this.cropY = centerY;
    this.yPos = centerY / this.CropScalePercentage();

    this.position.next({x: this.xPos, y: this.yPos});
  }

  /**
   * Handler that is invoked on tab change.
   * If select from service tab is selected,
   * needs to load the images from the service.
   *
   * @param {any} event Event data
   * @return {void}
   */
  onSelectedTabChange(event: any) {
    // Tab index 0 for sercvice images
    if (this.showClientImageSelection && event.index === 0) {
      this.loadClientImages();
    }
  }

  /**
   * On form close handler
   *
   * @param {any} event Click event data
   * @return {void}
   */
  onClose(event: any) {
    this.clientImageSubscription.unsubscribe();

    this.close.next(true);
  }

  /**
   * Handler for selecting an image from client service API
   * as the element image
   *
   * @param {any} event The click event object
   * @param {ClientImage} clientImage Selected image from client service API
   * @return {void}
   */
  selectImage(event: any, clientImage: ClientImage) {
    this.clientImagesLoading = true;
    this.clientImageSelection.next(clientImage);
  }

  /**
   * Checks if element image source is set or not
   *
   * @return {boolean}
   */
  checkSource(): boolean {
    if (! this.imageSrc) {
      this.loadTimer = setTimeout(() => this.checkSource(), 10);

      return false;
    }
    this.imageLoading = false;
    clearTimeout(this.loadTimer);

    return true;
  }

  /**
   * Is uploader uploading at the moment
   *
   * @return {boolean}
   */
  isUploading(): boolean {
    return this.imageLoading || (this.uploader && this.uploader.isUploading);
  }

  /**
   * Start loading client images from the client API
   *
   * @return {void}
   */
  loadClientImages() {
    if (! this.showClientImageSelection) {
      return ;
    }

    this.clientImagesLoading = true;
    this.imageService.getClientImages(this.configurationService.getConfiguration(), this.clientParameters);
  }

  /**
   * Clear image handler
   *
   * @param {Event} event
   */
  clearImage(event: Event) {
    if (this.disabled) {
      return ;
    }
    this.clear.next({});

    this.imageLoading = false;
    // Start loading client images
    this.loadClientImages();
  }

  /**
   * Scale handler that is called on scale change
   *
   * @param {any} event Slider event
   */
  scaleHandler(event: any) {
    this.scaledX = event.value;
    this.scaledY = event.value;

    this.scale.next(event);
    if (this.resizable) {
      this.width = this.scaledX * this.image.dimensions.width;
      this.height = this.scaledY * this.image.dimensions.height;

      this.width = (this.width > this.widthMax) ? this.widthMax : this.width;
      this.width = (this.width < this.widthMin) ? this.widthMin : this.width;

      this.height = (this.height > this.heightMax) ? this.heightMax : this.height;
      this.height = (this.height < this.heightMin) ? this.heightMin : this.height;

    }
  }

  /**
   * Handler for events where scale is moved one step backward/forward
   *
   * @param {number} step Step direction
   * @return {void}
   */
  onScaleStep(step: number) {
    let value = this.getStep() * step;

    this.scaleHandler({'value': (this.scaledX + value)});
  }

  validateUpload(file: File) : boolean {
    return true;
  }

  /**
   * Can user resize the image area by scaling the image
   *
   * @return {boolean}
   */
  canResize(): boolean {
    if (! this.canScale()) {
      return false;
    }

    return (! this.resizable && this.fitArea) ? false : true;
  }

  /**
   * Can user scale the image
   *
   * @return {boolean}
   */
  canScale(): boolean {
    return ! this.disabled && this.scalable;
  }

  /**
   * Can user reposition the image (move)
   *
   * @return {boolean}
   */
  canReposition(): boolean {
    return ! this.disabled && this.movable;
  }

  /**
   * Returns the maximum scale percentage for the image
   *
   * @return {boolean}
   */
  getMaxScale(): number | undefined {
    return this.scaleMax;
  }

  /**
   * Returns the minimum scale percentage for the image
   *
   * @return {boolean}
   */
  getMinScale(): number {
    return this.scaleMin;
  }

  /**
   * Returns a reasonable scale step based on min and max values
   *
   * @return {number} Returns step value for a slider
   */
  getStep(): number {
    let max = this.getMaxScale();

    return max / 100;
  }

  /**
   * Get form computed size
   *
   * @return {any} Returns the form dimensions
   */
  getFormSize(): any {
    return { height: 250, width: 330 };
  }

  /**
   * On mouse down event handler.
   * This is needed that dragging dont break.
   *
   * @param {any} event Mouse down event
   * @return {void}
   */
  onMouseDown(event: any) {
    if (event.preventDefault) {
      event.preventDefault();
    }
  }

  /**
   * Is there any errors that should be shown
   *
   * @return {boolean}
   */
  hasErrors(): boolean {
    return (this.errors.length > 0);
  }

  /**
   * Get all errors that should be shown
   *
   * @return {Array<any>}
   */
  getErrors(): Array<any> {
    return this.errors;
  }

  /**
   * Is there any warnings that should be shown
   *
   * @return {boolean}
   */
  hasWarnings(): boolean {
    return (this.warnings.length > 0);
  }

  /**
   * Get all warnings that should be shown
   *
   * @return {Array<any>}
   */
  getWarnings(): Array<any> {
    return this.warnings;
  }
}

import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, EMPTY, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { NotificationService } from './../../services/notification.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private notificationService: NotificationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (! error.error.code) {
          const message = (error.error instanceof ErrorEvent) ?
            error.error.message :
            `server returned code ${error.status} with body "${error.message}"`;
          this.notificationService.snackError(message);
        } else {
          const body = error.error;
          let errorObj = {
            'title': '',
            'code': '',
            'messages': [],
          }
          errorObj.title = body.message;
          errorObj.code = body.code;
          for (let exception of body.errors) {
            errorObj.messages.push({'code': exception.code, 'title': exception.field, 'message': exception.message});
          }

          this.notificationService.error(errorObj);
        }
        if (error.status === 404) {
          return throwError(error);
        } else {
          return EMPTY;
        }
      }));
  }
}

import { Injectable } from '@angular/core'
import { Subject } from 'rxjs';
import { ProductImageElementInterface } from './../models/product/element/product-image-element.type';
import { ElementFormComponent } from './../components/forms/element-form.component';
import { ProductElement } from './../models/product/element/product-element.type';
import { ProductInterface } from './../models/product/product.type';
import { ProductLayoutInterface } from './../models/product/product-layout.type';
import { Formable } from './../models/common/formable.type';
import { Permittable } from './../models/common/permittable.type';
import { Selectable } from './../models/common/selectable.type';

@Injectable()
export class FormService {

  private formables: Array<Formable>;
  private groups: string[] = [];
  private selected: Formable;
  private forms: any;

  formablesChanged: Subject<Array<Formable>> = new Subject<Array<Formable>>();

  constructor() {}

  /**
   * Set formables for editing
   *
   * @param {Array<Formable>} formables Set formables for editing
   * @param {Formable} selected The form element that contains is currently selected
   */
  public setFormElements(formables: Array<Formable>, selected?: Formable, ) {
      this.formables = formables;
      this.selected = selected;
  }

  /**
   * Set form groups
   *
   * @param {any} forms Form group data
   */
  public setFormGroups(forms: any) {
    this.forms = forms;
  }

  /**
   * Get form groups
   *
   * @return {any} Form group data
   */
  public FormGroups() {
    return this.forms;
  }

  /**
   * Return all form elements
   *
   * @return {Formable[]} Return form elements
   */
  public FormElements():Formable[] {
    return this.formables;
  }

  /**
   * Clear form elements
   *
   * @return {void}
   */
  public clearForm() {
    this.selected = undefined;
  }

  /**
   * Reload form element data if form is open
   * and there has been a change in the data in the background
   *
   * @param {ProductInterface} product The current product object
   * @return {void}
   */
  public reload(product: ProductInterface) {
    if (!this.formables || this.formables.length === 0) {
      return ;
    }

    this.formables = this.resolveFormElements(product.getSelectedLayout());
    this.formablesChanged.next(this.formables);
  }

  /**
   * Returns elements that should be editable in the form
   *
   * @param {Selectable} selectable The selectable
   * @param {boolean} addCurrent Should current selectable be added to the formables. Default true
   * @param {boolean} sort Should result be sorted. Default true
   * @return {Formable[]} Returns elements to the form
   */
  public resolveFormElements(selectable: Selectable, addCurrent: boolean = true, sort: boolean = true): Formable[] {
    if (! selectable) {
      return [];
    }
    let children = selectable.childSelectables;
    let formables = [];
    for (let item of children) {
      let permittable = <Permittable><any>item;
      if (typeof (permittable.can) === "undefined" || ! permittable.can('implement')) {
        continue;
      }
      let formable = <Formable><any>item;
      if (typeof (formable.getFormableType) !== "undefined" && formable.getFormableType()) {
          formables.push(formable);
      }
      let childItems = this.resolveFormElements(item, false, false);
      formables = [ ...formables, ...childItems];
    }
    // Set formables to the service property
    this.formables = formables;
    // Add current as one of the formables as well
    if (addCurrent) {
      let curPermittable = <Permittable><any>selectable;
      if (typeof (curPermittable.can) === "undefined" || ! curPermittable.can('implement')) {
        if (sort) {
          // Sort by form index
          this.sortFormables();
        }
        return this.formables;
      }
      let curFormable = <Formable><any>selectable;
      if (typeof (curFormable.getFormableType) !== "undefined" && curFormable.getFormableType()) {
          this.formables.push(curFormable);
      }
    }
    if (sort) {
      // Sort by form index
      this.sortFormables();
    }
    return this.formables;
  }

  /**
   * Custom sorting of formables by form index
   *
   * @return {void}
   */
  private sortFormables()
  {
    this.formables.sort(function(ele1: Formable, ele2: Formable) {
      return ele1.FormIndex() - ele2.FormIndex();
    });
  }
}

import { Router, ActivatedRoute } from "@angular/router";
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  HostListener
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material";
import { Observable, Subscription } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { ConfirmDeactivateGuard } from "./guards/confirm-deactivate.guard";
import { SmartdesignerService } from "./services/smartdesigner.service";
import { ConfigurationService } from "./services/configuration.service";
import { ProductService } from "./services/product.service";
import { FormService } from "./services/form.service";
import { ZoomService } from "./services/zoom.service";
import { ImageToolService } from "./services/image-tool.service";
import { SelectionService } from "./services/selection.service";
import { NotificationService } from "./../services/notification.service";
import { Configuration } from "./models/configuration";
import { Template } from "./models/template/template";
import { SdDialogComponent } from "./components/common/dialog/sd-dialog.component";

import { Formable } from "./models/common/formable.type";
import { Selectable } from "./models/common/selectable.type";
import { environment } from "./../../environments/environment";

declare var WebFont: any;

@Component({
  selector: "smartdesigner-app",
  templateUrl: "./smartdesigner-app.component.html",
  styleUrls: ["./smartdesigner-app.component.scss"]
})
export class SmartdesignerAppComponent
  implements OnInit, OnDestroy, ConfirmDeactivateGuard {
  configuration: Configuration;
  template: Template;
  loading: boolean = false;
  alive: boolean = true;
  previewing: boolean = false;
  saving: boolean = false;
  fontsLoaded: boolean = false;
  formOpened: boolean = true;

  theme: string = "sd-theme";

  notificationSubscription: Subscription;
  unsavedSubscription: Subscription;
  savingSubscription: Subscription;
  productChangeSubscription: Subscription;
  templateSubscription: Subscription;
  productSubscription: Subscription;
  configurationSubscription: Subscription;
  selectionSubscription: Subscription;

  @ViewChild("tools", {
    read: ViewContainerRef
  })
  viewContainerRef: ViewContainerRef;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private smartDesignerService: SmartdesignerService,
    private configurationService: ConfigurationService,
    private notificationService: NotificationService,
    private formService: FormService,
    private productService: ProductService,
    private zoomService: ZoomService,
    private selectionService: SelectionService,
    private imageToolService: ImageToolService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    let hash = this.activatedRoute.snapshot.params["hash"];
    this.configurationService.configurationChange
      .pipe(takeWhile(() => this.alive))
      .subscribe(configuration => {
        this.configuration = configuration;
        this.theme = this.configuration.theme;
        if (this.configuration.id) {
          this.productService.requestProduct(this.configuration);
        }
      });
    // Subscription to template loading
    this.templateSubscription = this.smartDesignerService.templateChange
      .pipe(takeWhile(() => this.alive && !this.template))
      .subscribe(template => {
        this.setTemplate(template);
        if (template.language) {
          this.translate.use(template.language);
        }
      });
    // Subscription to product loading
    this.productSubscription = this.productService.productChange
      .pipe(takeWhile(() => this.alive && !this.template))
      .subscribe(product => {
        this.setTemplate(product.template);
        if (product.template.language) {
          this.translate.use(product.template.language);
        }
      });
    // Subscription to Notifications
    this.notificationSubscription = this.notificationService.notificationChange
      .pipe(takeWhile(() => this.alive))
      .subscribe(data => {
        this.openDialog(data);
      });
    // Subscription to product unsaved Event
    this.unsavedSubscription = this.productService.unsaved
      .pipe(takeWhile(() => this.alive))
      .subscribe(data => {
        this.productService.saveProduct(this.configuration);
        if (this.configuration.isTemplate()) {
          this.configuration.type = "product";
          this.configuration.id = data.id;
          this.configurationService.setConfiguration(this.configuration);
        }
      });
    this.savingSubscription = this.productService.saving
      .pipe(takeWhile(() => this.alive))
      .subscribe(isSaving => {
        if (isSaving && this.configuration.isTemplate()) {
          this.saving = isSaving;
        } else {
          this.saving = false;
        }
      });
    // Subscription to product changes
    this.productChangeSubscription = this.productService.productChange
      .pipe(takeWhile(() => this.alive))
      .subscribe(data => {
        this.formService.reload(data);
        if (!this.zoomService.isZoomInitialized()) {
          this.calculateZoomLevels();
        }
        // First changes don't have an ID for the product,
        // so when product ID is present, set it to the configuration object as well
        if (
          data.id &&
          this.configuration.type == "product" &&
          !this.configuration.id
        ) {
          this.configuration.id = data.id;
          this.configurationService.setConfiguration(this.configuration);
        }
      });
    // Subscription for current selection
    this.selectionSubscription = this.selectionService.selectionChange
      .pipe(takeWhile(() => this.alive))
      .subscribe(data => {
        if (data) {
          this.handleSelection(data);
        }
      });
    // Fetch the configuration
    this.configurationService.fetchConfiguration(hash);
  }

  ngOnDestroy() {
    this.notificationSubscription.unsubscribe();
    this.templateSubscription.unsubscribe();
    this.unsavedSubscription.unsubscribe();
    this.productChangeSubscription.unsubscribe();
    this.configurationSubscription.unsubscribe();
    this.selectionSubscription.unsubscribe();
  }

  /**
   * Can user deactivate current route
   *
   * @return {Observable<boolean>|boolean}
   */
  canDeactivate(): Observable<boolean> | boolean {
    return !this.configuration || this.loading ? true : false;
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener("window:beforeunload", ["$event"])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue =
        "This message is displayed to the user in IE and Edge when they navigate without using Angular routing (type another URL/close the browser/etc)";
    }
  }

  /**
   * On stage click handler
   *
   * @param {any} event Click event data
   * @return {void}
   */
  onStageClick(event: any) {
    this.selectionService.clearSelection();
    this.imageToolService.onClose(event);
  }

  /**
   * Returns true for loading until template is set
   *
   * @return {boolean} Returns true/false based on template load
   */
  isLoading(): boolean {
    return !this.template;
  }

  /**
   * Show loader on the page
   *
   * @return {void}
   */
  showLoading(event: boolean = true) {
    this.loading = event;
    // Processing for closure has started so this component can be switched off
    if (event === true) {
      this.alive = false;
    }
  }

  /**
   * Set current template
   *
   * @param {Template} template Template model
   * @return {void}
   */
  setTemplate(template: Template) {
    this.template = template;
    if (!this.fontsLoaded) {
      this.loadFonts(this.template);
    }
  }

  /**
   * Returns the name of the current template
   *
   * @return {string} Returns the template name
   */
  getTemplateName(): string {
    if (!this.template) {
      return "loading...";
    }

    return this.template.name;
  }

  /**
   * Returns the format of the current template
   *
   * @return {string} Returns the template format
   */
  getTemplateFormat(): string {
    if (!this.template) {
      return "";
    }

    return this.template.format;
  }

  /**
   * Get all form elements
   *
   * @return {Formable[]}
   */
  public FormElements(): Formable[] {
    return this.formService.FormElements();
  }

  /**
   * Get all form groups
   *
   * @return {any}
   */
  public FormGroups() {
    return this.template.forms;
  }

  /**
   * Returns the path to the application logo
   * This can be returned from the API and if not set
   * the default SD logo is used instead.
   *
   * @return {string} Returns the path to the shown logo
   */
  getApplicationLogoUrl(): string {
    let path = "../../assets/smartdesigner-logo.svg";
    if (this.configuration && this.configuration.logo) {
      path = this.configuration.logo;
    }

    return path;
  }

  /**
   * Returns the dimensions of the current layout in string
   *
   * @return {string} Returns the layout dimensions string
   */
  getLayoutDimensions(): string {
    let layout = this.productService.getSelectedLayout();
    if (!layout) {
      return "";
    }
    let width = Number(layout.width).toFixed(2);
    let height = Number(layout.height).toFixed(2);
    const unit = "mm";

    return width + " x " + height + unit;
  }

  openDialog(data: any): void {
    let dialogRef = this.dialog.open(SdDialogComponent, {
      height: "250px",
      data: data,
      panelClass: this.configurationService.ThemeClass()
    });
  }

  /**
   * Handler has the user continued editing after clicking
   * ready or close buttons
   *
   * @param {boolean} event T
   */
  onSavePrevent(event: boolean) {
    this.previewing = false;
  }

  /**
   * Handler for data change event
   *
   * @param {number} event Data changed event data containing a timestamp
   * @return {void}
   */
  handleDataChange(event: number) {
    this.productService.setChangeTime(event);
  }

  /**
   * Toggle form in sidenav (opened / closed)
   *
   * @param {any} event Click event data
   */
  toggleForm(event: any) {
    this.formOpened = !this.formOpened;
    if (!this.formOpened) {
      this.imageToolService.onClose(event);
    }
  }

  /**
   * Open form in sidenav
   */
  openForm() {
    this.formOpened = true;
  }

  /**
   * Selection handler
   *
   * @param {Selectable} selectable Selection
   * @return {void}
   */
  handleSelection(selectable: Selectable) {
    this.openForm();
  }
  /**
   * Preview toggle button handler
   *
   * @param {any} event Event data
   * @return {void}
   */
  onPreview(event: any) {
    // Close all forms opened at the moment
    this.formOpened = !event.source.checked;
    if (!this.formOpened) {
      this.imageToolService.onClose(event);
    }

    this.previewing = event.source.checked;
  }

  /**
   * Does the product have any errors
   *
   * @return {boolean}
   */
  hasErrors(): boolean {
    let product = this.productService.getProduct();

    return product ? product.hasErrors() : false;
  }

  /**
   * Does the product have any warnings
   *
   * @return {boolean}
   */
  hasWarnings(): boolean {
    let product = this.productService.getProduct();

    return product ? product.hasWarnings() : false;
  }

  /**
   * Can user increase the zoom level
   *
   * @param {string} direction Zoom direction
   * @return {boolean}
   */
  canZoom(direction: string): boolean {
    let layout = this.productService.getSelectedLayout();
    if (!layout) {
      return false;
    }

    return this.zoomService.canZoom(direction, layout.width, layout.height);
  }

  /**
   * Handler for zooming in
   *
   * @param {Event} event Click event
   * @return {void}
   */
  onZoomIn(event: Event) {
    this.zoomService.increaseZoom();
  }

  /**
   * Handler for zooming out
   *
   * @param {Event} event Click event
   * @return {void}
   */
  onZoomOut(event: Event) {
    this.zoomService.decreaseZoom();
  }

  /**
   * Returns the zoom tooltip text for the buttons
   *
   * @param {string} direction Zoom direction
   */
  ZoomText(direction: string): string {
    let nextStep =
      direction === "increase"
        ? this.zoomService.ZoomLevel() + 1
        : this.zoomService.ZoomLevel() - 1;
    if (!this.canZoom(direction)) {
      nextStep = this.zoomService.ZoomLevel();
    }

    return Math.ceil(this.zoomService.Zoom(nextStep) * 100) + "%";
  }

  /**
   * Calculates an initial zoom level that
   * product layout fits to the screen perfectly.
   *
   * @return {void}
   */
  calculateZoomLevels() {
    let layout = this.productService.getSelectedLayout();
    if (!layout) {
      return;
    }

    this.zoomService.initializeZoomLevel(layout.width, layout.height);
  }

  /**
   * Load text element fonts from API
   *
   * @param {Template} template The template
   * @return {void}
   */
  loadFonts(template: Template): void {
    if (!template) {
      return;
    }
    let fonts = template.AvailableFontFamilies();
    var vals = Object.keys(fonts).map(function(key) {
      return fonts[key].key;
    });

    WebFont.load({
      custom: {
        families: vals,
        urls: [environment.apiBaseUrl + "templates/" + template.id + "/fonts"]
      }
    });

    this.fontsLoaded = true;
  }

  getFormDefaultBgColor(): string {
    const permission = this.template.getPermissionByKey('formColor');
    if (permission) {
      return permission.color || 'grey';
    }
  }
}

import { Component, Input, Output, OnInit, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ElementService } from './../../../services/element.service';
import { SelectionService } from './../../../services/selection.service';
import { ZoomService } from './../../../services/zoom.service';
import { Selectable } from './../../../models/common/selectable.type';
import { GroupElement } from './../../../models/template/element/group-element';
import { ProductGroupElement } from './../../../models/product/element/product-group-element';
import { ProductElement } from './../../../models/product/element/product-element.type';
import { ProductLayerInterface } from './../../../models/product/product-layer.type';
import { StyleObject } from './../../../models/common/style-object.type';
import { Formable } from './../../../models/common/formable.type';
import { Unit } from './../../../../shared/unit';
import { ResizePermission } from './../../../models/common/resize-permission';
import { Animator } from './../../../models/common/animator';

@Component({
  selector: 'sd-group-element',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {
  @Input() element: ProductGroupElement;
  @Input() zIndex: number;
  @Input() previewing: boolean = false;
  @ViewChild('container') container;

  @Output() height: EventEmitter<number> = new EventEmitter<number>();
  @Output() width: EventEmitter<number> = new EventEmitter<number>();
  @Output() x: EventEmitter<number> = new EventEmitter<number>();
  @Output() y: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private elementService: ElementService,
    private selectionService: SelectionService,
    private zoomService: ZoomService,
    private elRef: ElementRef,
    private changeDetectionRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }

  /**
   * Selection handler that is called on click to edit icon
   *
   * @param {any} event Event data
   * @param {Selectable} selectable Selected selectable or undefined for unselection
   * @return {void}
   */
  onSelection(event: any, selectable: Selectable) {
    this.selectionService.select(event, selectable);
  }

  /**
   * Return all elements that are readable
   *
   * @return {ProductElement[]}
   */
  Elements(): ProductElement[] {
    let elements = [];
    for (let element of this.element.elements) {
      if (element.can('read')) {
        elements.push(element);
      }
    }

    return elements;
  }

  /**
   * Can current user update the group
   *
   * @return boolean
   */
  isEditable(): boolean {
    return this.element.can('implement');
  }

  /**
   * Is current element animated
   *
   * @return boolean
   */
  isAnimated(): boolean {
    return (this.previewing && this.element.isAnimetable());
  }

  /**
   * Is current group element selected
   *
   * @return {boolean}
   */
  isSelected(): boolean {
    return (this.selectionService.getSelectableGroup() === this.element);
  }

  /**
   * Should form be showed on group.
   * Form is showed on group if the group is selected.
   *
   * @return {boolean}
   */
  showForm(): boolean {
    return this.isSelected();
  }

  /**
   * Set group height to model from content's real height
   *
   * @param {number} value Element real height
   * @param {ProductElement} element Element which height have changed
   * @return {void}
   */
  setHeight(value: number, element: ProductElement) {
    setTimeout(()=>{
      let size = this.getSize();
      let curValue = this.element.height;
      if (size.height === curValue) {
        return ;
      }
      this.element.setHeight(size.height);
      if (curValue !== this.element.height) {
        this.emitResize('height');
      }
    }, 0);
  }

  /**
   * Set group width to model from content's real width
   *
   * @param {number} value Element real width
   * @param {ProductElement} element Element which width have changed
   * @return {void}
   */
  setWidth(value: number, element: ProductElement) {
    setTimeout(()=>{
      let size = this.getSize();
      let curValue = this.element.width;
      if (size.width === curValue) {
        return ;
      }
      this.element.setWidth(size.width);
      if (curValue !== this.element.width) {
        this.emitResize('width');
      }
    }, 0);
  }

  /**
   * Emit detected resize
   *
   * @param {string} dimension Which dimension was resized: [width, height]
   * @return {void}
   */
  emitResize(dimension: string): void {
    if (dimension === 'width') {
      this.width.emit(this.element.width);
    } else if (dimension === 'height') {
      this.height.emit(this.element.height);
    }
    // Tell view to detect changes
    this.changeDetectionRef.detectChanges();
  }

  /**
   * Return element in-line styles for data binding
   *
   * @return {any} Returns the styles in an object
   */
  setStyles(): any {
    let resizePermission: ResizePermission = (this.element.defaultElement) ? <ResizePermission>this.element.defaultElement.Permission('resize') : undefined;

    let maxHeight = this.element.getMaxHeight();
    let maxWidth = this.element.getMaxWidth();
    let minHeight = this.element.getMinHeight();
    let minWidth = this.element.getMinWidth();

    let styles: StyleObject = {
      'minWidth.mm': minWidth,
      'minHeight.mm': minHeight,
      'maxWidth.mm': maxWidth,
      'maxHeight.mm': maxHeight,
      'background-color': this.element.defaultElement.bgColor.rgb,
      'marginTop.mm': this.element.getMargin('top'),
      'marginLeft.mm': this.element.getMargin('left'),
      'marginBottom.mm': this.element.getMargin('bottom'),
      'marginRight.mm': this.element.getMargin('right'),
      'float': this.element.getFloat()
    };

    if (this.previewing) {
      let animator = new Animator(this.element);
      return { ...styles, ...animator.getAnimationStyles() }
    }

    return styles;
  }

  /**
   * Return element in-line styles for element container data binding
   *
   * @return {any} Returns the styles in an object
   */
  setContainerStyles(): any {
    let display = (this.element.justifyContent() == 'initial') ? 'block' : 'flex';

	let styles: StyleObject = {
      'display': display
    };
    if (this.element.justifyContent() == "center-vertical") {
      styles["align-items"] = "center";
      styles["justify-content"] = "center";
      styles["height"] = this.element.height + "mm";
    } else {
      styles["justify-content"] = this.element.justifyContent();
    }
    return styles;
  }

  /**
   * Get component container bounding rectange dimensions
   *
   * @return {any} Returns an object with height and width as keys.
   */
  getSize(): any {
    let el = this.container.nativeElement;
    let height = el.scrollHeight;
    let width = el.scrollWidth;
    for (let child of el.getElementsByTagName('*')) {
      height = (child.clientHeight > height) ? child.clientHeight : height;
      width = (child.clientWidth > width) ? child.clientWidth : width;
    }
    let heightPx = Number(Unit.pxToMm(height)).toFixed(1);
    let widthPx = Number(Unit.pxToMm(width)).toFixed(1);
    return { height: heightPx, width: widthPx };
  }
}

import { Element } from './../../template/element/element.type';
import { Permittable } from './../../common/permittable.type';
import { ProductLayerInterface } from './../product-layer.type';
import { ProductElement } from './product-element.type';
import { ProductElementOut } from './product-element-out.type';
import { Selectable } from './../../common/selectable.type';
import { SelectionType } from './../../common/selection-type';
import { Formable } from './../../common/formable.type';
import { FormableType } from './../../common/formable-type';
import { Abstract } from './../abstract';
import { ResizePermission } from './../../common/resize-permission';
import { Validator } from './../../common/validator/validator.type';
import { RequiredValidator } from './../../common/validator/required-validator';
import { ValidatorAggregator } from './../../common/validator/validator-aggregator';
import { Validatable } from './../../common/validatable.type';

export abstract class BaseProductElement extends Abstract implements ProductElement {

    public classInstance: boolean = true;
    public context: string = 'product';

    // Selectable options
    public selectionType: SelectionType = SelectionType.element;
    public parentTier: Selectable;
    public childSelectables: Array<Selectable> = [];

    constructor(
      public defaultElement: Element,
      public id: number,
      public key: string,
      public elementType: string,
      public width: number,
      public height: number,
      attributes: any
    ) {
      super(defaultElement, key, attributes);
    }

    /**
     * Get product element data.
     * This data is used for saving this object to the API.
     *
     * @return {ProductElementOut} Returns the product element data for the API
     */
    getData(): ProductElementOut {
      let data:ProductElementOut = <ProductElementOut>{};
      data.id = this.id;
      data.key = this.key;
      data.width = this.width;
      data.height = this.height;
      data.defaultElement = (this.defaultElement !== undefined) ? this.defaultElement.id : undefined;
      data.elementType = this.elementType;
      data.attributes = this.Attributes();

      return data;
    }

    /**
     * Set element height safely.
     * Respects the restrictions set to the element.
     *
     * @param {number} value New height
     * @return {void}
     */
    public setHeight(value: number): void {
      if (! this.canResize('vertical')) {
        return ;
      }
      this.height = this.parseValidHeight(value);
    }

    /**
     * Set element width safely.
     * Respects the restrictions set to the element.
     *
     * @param {number} value New width
     * @return {void}
     */
    public setWidth(value: number): void {
      if (! this.canResize('horizontal')) {
        return ;
      }

      this.width = this.parseValidWidth(value);
    }

    /**
     * Is the element content required?
     *
     * @return {boolean}
     */
    isRequired(): boolean {
      if (! this.defaultElement) {
        return false;
      }

      return this.defaultElement.isRequired();
    }

    /**
     * Can current user resize the element
     *
     * @param {string} direction Optional parameter for resize direction [horizontal, vertical]
     * @return boolean
     */
    canResize(direction?: string): boolean {
      if (! this.defaultElement) {
        return true;
      }

      return this.defaultElement.canResize(direction);
    }

    /**
     * Returns the possible maximum width for the element
     * undefined = unlimited
     *
     * @return number|undefined
     */
    getMaxWidth(): number | undefined {
      if (! this.canResize('horizontal')) {
        return this.width;
      }
      if (! this.defaultElement)  {
          return undefined;
      }

      return this.defaultElement.getMaxWidth();
    }

    /**
     * Returns the possible maximum height for the element
     * undefined = unlimited
     *
     * @return number|undefined
     */
    getMaxHeight(): number | undefined {
      if (! this.canResize('vertical')) {
        return this.height;
      }
      if (! this.defaultElement)  {
          return undefined;
      }

      return this.defaultElement.getMaxHeight();
    }

    /**
     * Returns the possible minimum width for the element
     * undefined = 0
     *
     * @return number
     */
    getMinWidth(): number {
      if (! this.canResize('horizontal')) {
        return this.width;
      }
      if (! this.defaultElement)  {
          return 0;
      }

      return this.defaultElement.getMinWidth();
    }

    /**
     * Returns the possible minimum height for the element
     * undefined = 0
     *
     * @return number
     */
    getMinHeight(): number {
      if (! this.canResize('vertical')) {
        return this.height;
      }
      if (! this.defaultElement)  {
          return 0;
      }

      return this.defaultElement.getMinHeight();
    }

    /**
     * Returns the unit for notch in height
     * undefined = dynamic
     *
     * @return number|undefined
     */
    getNotchHeight(): number | undefined {
      if (! this.canResize('vertical')) {
        return undefined;
      }
      if (! this.defaultElement)  {
          return undefined;
      }

      let permission = <ResizePermission>this.defaultElement.Permission('resize');
      return permission.getNotchHeight();
    }

    /**
     * Returns the unit for notch in width
     * undefined = dynamic
     *
     * @return number|undefined
     */
    getNotchWidth(): number | undefined {
      if (! this.canResize('horizontal')) {
        return undefined;
      }
      if (! this.defaultElement)  {
          return undefined;
      }

      let permission = <ResizePermission>this.defaultElement.Permission('resize');
      return permission.getNotchWidth();
    }

    /**
     * Parse the given value valid for height of the element
     *
     * @param {number} value Height value
     * @return {number} Returns a valid value
     */
    parseValidHeight(value:number): number {
      if (! value) {
        return this.height;
      }
      if (this.getNotchHeight() && this.getNotchHeight() > 0) {
        value = Math.ceil(value / this.getNotchHeight()) * this.getNotchHeight();
      }
      if (this.getMaxHeight() && value > this.getMaxHeight()) {
        return this.getMaxHeight();
      }
      if (this.getMinHeight() && value < this.getMinHeight()) {
        return this.getMinHeight();
      }

      return value;
    }

    /**
     * Parse the given value valid for width of the element
     *
     * @param {number} value Width value
     * @return {number} Return a valid value
     */
    parseValidWidth(value:number): number {
      if (! value) {
        return this.width;
      }
      if (this.getNotchWidth() && this.getNotchWidth() > 0) {
        value = Math.ceil(value / this.getNotchWidth()) * this.getNotchWidth();
      }
      if (this.getMaxWidth() && value > this.getMaxWidth()) {
        return this.getMaxWidth();
      }
      if (this.getMinWidth() && value < this.getMinWidth()) {
        return this.getMinWidth();
      }

      return value;
    }

    /**
     * Get element form identifier from the default element
     *
     * @return {string} Returns the form identifier if set
     */
    public FormId() {
      if (! this.defaultElement)  {
          return undefined;
      }

      return this.defaultElement.formId;
    }

    /**
     * Returns the form item index
     *
     * @return {number} Returns form item index
     */
    public FormIndex(): number {
      return (this.defaultElement == undefined) ? super.FormIndex() : this.defaultElement.FormIndex();
    }

    /**
     * Returns margin value from element attributes if set
     *
     * @param {string} position Margin for position
     * @return {number} Returns margin value from element attributes if set else 0
     */
    getMargin(position: string): number {
      if (! this.attributes || this.attributes.margin === undefined) {
        return 0;
      }

      return (this.attributes.margin[position] === undefined) ? 0 : this.attributes.margin[position];
    }

    /**
     * Returns float position value from element attributes if set
     *
     * @return {string} Returns float position value from element attributes if set else 0
     */
    getFloat(): string {
      if (! this.attributes || this.attributes.float === undefined) {
        return 'none';
      }

      return (this.attributes.float.float === undefined) ? 'none' : this.attributes.float.float;
    }

    /**
     * Returns selected animation class name value from element attributes if set
     *
     * @return {string} Returns animation class name value
     */
    getAnimation(): string {
      if (! this.attributes || this.attributes.animation === undefined) {
        return undefined;
      }

      return (this.attributes.animation.animation === undefined) ? undefined : this.attributes.animation.animation;
    }

    /**
     * Returns a label for an element implemented from the template elements
     *
     * @return {string} Returns a label
     */
     getLabel(): string {
       if (this.defaultElement == undefined) {
         return '';
       }

       return (this.defaultElement.label) ? this.defaultElement.label : '';
     }

    /**
     * Returns a help text for an element implemented from the template elements
     *
     * @return {string} Returns a help text
     */
     getHelpText(): string {
       if (this.defaultElement == undefined) {
         return '';
       }

       return (this.defaultElement.helpText) ? this.defaultElement.helpText : '';
     }

     /**
      * Returns the form item type
      *
      * @return {FormableType} Returns form item type
      */
     getFormableType(): FormableType {
       // Qr code is an image but needs it's own form type
       if (this.elementType === 'image') {
         const permission = this.permittable.Permission('qrcode');
         if (permission && permission.canUpdate()) {
           return FormableType['qrcode'];
         }
       }
       return FormableType[this.elementType];
     }

     /**
      * Find a formable from product structure by its type and key
      *
      * @param {string} key Formable key
      * @param {FormableType} type Formable type
      * @return {Formable} Return the fetched formable
      */
     findFormableByKey(key: string, type: FormableType): Formable {
         return (this.key == key && this.getFormableType() == type) ? this : undefined;
     }

     /**
      * Get element validators
      *
      * @return {Array<Validator>} Returns an array of validators
      */
     Validators(): Array<Validator> {
        let validators: Array<Validator> = super.Validators();
        if (this.canImplement()) {
          validators.push(new RequiredValidator(this));
        }
        return validators;
     }

     /**
      * Returns children of the current validatable
      *
      * @return {Array<Validatable>}
      */
     public Children(): Array<Validatable> {
       return [];
     }

     /**
      * Returns the plan (template counter-part) of this implementation
      *
      * @return {Permittable}
      */
     public Plan(): Permittable {
       return this.defaultElement;
     }

     /**
      * Is element content empty?
      * Override in elements that have content
      *
      * @return {boolean}
      */
     isContentEmpty(): boolean {
       return false;
     }

     /**
      * Get element human readable name
      *
      * @return {string}
      */
     ElementName(): string {
       if (this.defaultElement == undefined) {
         return super.ElementName();
       }

       return this.defaultElement.label;
     }

    /**
     * Update element content
     *
     * @param {any} data data
     * @return {void}
     */
    abstract update(data: any): void;
}

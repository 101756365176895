import { Permission } from './permission.type';

export class ClientApiPermission implements Permission
{
  permission: any;

  constructor(permission:any) {
    this.permission = permission;
  }

  /**
   * Can an action be exuted based on the permission
   *
   * @return {boolean}
   */
  public canChange(): boolean {
    return (this.Permission().update || false);
  }

  /**
   * Get permission value
   *
   * @return {any} Returns the permission value
   */
  public Permission(): any {
    return this.permission;
  }

  /**
   * Returns the possible filters that should be added to client API requests.
   *
   * @return {any}
   */
  public Filters(): any {
    return this.Permission().filters;
  }

  /**
   * Is only API used for setting images?
   *
   * @return {boolean}
   */
  public onlyApi(): boolean {
    return (this.Permission().onlyApi || false);
  }
}

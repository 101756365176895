import { ProductLayerInterface } from './../product-layer.type';
import { ProductLayer } from './../product-layer';
import { ProductLayerBuilderInterface } from './product-layer-builder.type';
import { Layer } from './../../template/layer';
import { Element } from './../../template/element/element.type';
import { ProductElement } from './../element/product-element.type';
import { ProductElementBuilder } from './../builder/product-element-builder';
import { LayerBuilder } from './../../template/builder/layer-builder';
import { Selectable } from './../../common/selectable.type';
import { Color } from './../../common/color.type';

export class ProductLayerBuilder implements ProductLayerBuilderInterface {
  private id: number;
  private key: string;
  private layer: Layer;
  private width: number;
  private height: number;
  private xCoordinate: number;
  private yCoordinate: number;
  private zCoordinate: number;
  private published: boolean;
  private creationDate: number;
  private modificationDate: number;
  private backgroundColor: Color;
  private elements: Array<ProductElement>;
  private attributes: any;

  constructor() {}

  /**
   * Get Id
   *
   * @return {number} Returns the product layer Id
   */
  get Id() {
    return this.id;
  }

  /**
   * Set Id
   *
   * @param {number} id Product layer Id
   * @return {ProductLayerBuilder}
   */
  setId(id: number) {
    this.id = id;
    return this;
  }

  /**
   * Get key
   *
   * @return {string} Returns the product layer key
   */
  get Key() {
    return this.key;
  }

  /**
   * Set key
   *
   * @param {string} key Product layer key
   * @return {ProductLayoutBuilder}
   */
  setKey(key: string) {
    this.key = key;
    return this;
  }

  /**
   * Get width
   *
   * @return {number} Returns the product layer width
   */
  get Width() {
    return this.width;
  }

  /**
   * Set width
   *
   * @param {number} width Product layer width
   * @return {ProductLayerBuilder}
   */
  setWidth(width: number) {
    this.width = width;
    return this;
  }

  /**
   * Get height
   *
   * @return {number} Returns the product layer height
   */
  get Height() {
    return this.height;
  }

  /**
   * Set height
   *
   * @param {number} height Height
   * @return {ProductLayerBuilder}
   */
  setHeight(height: number) {
    this.height = height;
    return this;
  }

  /**
   * Get X coordinate
   *
   * @return {number} Returns the product layer X coordinate
   */
  get XCoordinate() {
    return this.xCoordinate;
  }

  /**
   * Set X coordinate
   *
   * @param {number} coordinate X coordinate
   * @return {ProductLayerBuilder}
   */
  setXCoordinate(coordinate: number) {
    this.xCoordinate = coordinate;
    return this;
  }

  /**
   * Get Y coordinate
   *
   * @return {number} Returns the product layer Y coordinate
   */
  get YCoordinate() {
    return this.yCoordinate;
  }

  /**
   * Set Y coordinate
   *
   * @param {number} coordinate Y coordinate
   * @return {ProductLayerBuilder}
   */
  setYCoordinate(coordinate: number) {
    this.yCoordinate = coordinate;
    return this;
  }

  /**
   * Get Z coordinate
   *
   * @return {number} Returns the product layer Z coordinate
   */
  get ZCoordinate() {
    return this.zCoordinate;
  }

  /**
   * Set Z coordinate
   *
   * @param {number} coordinate Z coordinate
   * @return {ProductLayerBuilder}
   */
  setZCoordinate(coordinate: number) {
    this.zCoordinate = coordinate;
    return this;
  }

  /**
   * Get default layer
   *
   * @return {Layer} Returns the template layer if implemented from one
   */
  get Layer() {
    return this.layer;
  }

  /**
   * Set default layer
   *
   * @param {Layer} layer Template layer
   * @return {ProductLayerBuilder}
   */
  setLayer(layer: Layer) {
    if (layer instanceof Layer) {
      this.layer = layer;
    } else {
      let builder = new LayerBuilder();
      this.layer = builder.populate(layer).build();
    }
  }

  /**
   * Is Published
   *
   * @return {boolean} Returns Published value
   */
  get Published() {
    return this.published;
  }

  /**
   * Set Published
   *
   * @param {boolean} published Published value
   * @return {ProductLayerBuilder}
   */
  setPublished(published: boolean) {
    this.published = published;
    return this;
  }

  /**
   * Get Creation Date
   *
   * @return {number} Returns the Creation date timestamp
   */
  get CreationDate() {
    return this.creationDate;
  }

  /**
   * Set Creation Date
   *
   * @param {number} date CreationDate
   * @return {ProductLayerBuilder}
   */
  setCreationDate(date: number) {
    this.creationDate = date;
    return this;
  }

  /**
   * Get modification date
   *
   * @return {number} Returns the modification date timestamp
   */
  get ModificationDate() {
    return this.modificationDate;
  }

  /**
   * Set Modification date
   *
   * @param {number} date Modification date
   * @return {ProductLayerBuilder}
   */
  setModificationDate(date: number) {
    this.modificationDate = date;
    return this;
  }

  /**
   * Get elements
   *
   * @return {Array<ProductElement>} Returns the product layer elements
   */
  get Elements() {
    return this.elements;
  }

  /**
   * Set elements
   *
   * @param {Array<any>} elements Layers elements
   * @return {ProductLayerBuilder}
   */
  setElements(elements: Array<any>) {
    let layerElements = [];
    for (let element of elements) {
      if ('classInstance' in element) {
        layerElements.push(element);
      } else {
        element.element = this.getElementById(element.defaultElement);
        layerElements.push(ProductElementBuilder.build(element));
      }
    }
    this.elements = layerElements;
    return this;
  }

  /**
   * Get background color
   *
   * @return {Color} Returns the layer background color
   */
  get BackgroundColor() {
    return this.backgroundColor;
  }

  /**
   * Set background color
   *
   * @param {Color} color Layer background color
   * @return {ProductLayerBuilder}
   */
  setBackgroundColor(color: Color) {
    this.backgroundColor = color;
    return this;
  }

  /**
   * Get attributes
   *
   * @return {any} Returns the attributes
   */
  get Attributes() {
    return this.attributes;
  }

  /**
   * Set attributes
   *
   * @param {any} attributes Attributes
   * @return {ProductLayoutBuilder}
   */
  setAttributes(attributes: any) {
    this.attributes = attributes;

    return this;
  }

  /**
   * Build product layer from class data
   *
   * @return {ProductLayerInterface} Returns Product layer object
   */
  build(): ProductLayerInterface {
    return new ProductLayer(
      this.Layer,
      this.Id,
      this.Key,
      this.Width,
      this.Height,
      this.XCoordinate,
      this.YCoordinate,
      this.ZCoordinate,
      this.BackgroundColor,
      this.Elements,
      this.CreationDate,
      this.ModificationDate,
      this.Published,
      this.Attributes
    );
  }

  /**
   * Populate class data from data input
   *
   * @param {any} data Data object
   * @return {ProductLayerBuilderInterface} Returns self
   */
  populate(data: any): ProductLayerBuilderInterface {
    this.setId(data.id);
    this.setKey(data.key);
    this.setLayer(data.layer);
    this.setWidth(data.width);
    this.setHeight(data.height);
    this.setXCoordinate(data.xCoordinate);
    this.setYCoordinate(data.yCoordinate);
    this.setZCoordinate(data.zCoordinate);
    this.setPublished(data.published);
    this.setCreationDate(data.creationDate);
    this.setModificationDate(data.modificationDate);
    this.setElements(data.elements);
    this.setBackgroundColor(data.backgroundColor);
    this.setAttributes(data.attributes);

    return this;
  }

  /**
   * implement template layer to a new product layer object.
   *
   * @param {Layer} layer Layer
   * @return {ProductLayerInterface} Returns the product layer class object
   */
  implement(layer: Layer):ProductLayerInterface
  {
    let data:any = <any>{};
    data.layer = layer;
    data.key = layer.key;
    data.width = layer.width;
    data.height = layer.height;
    data.xCoordinate = layer.xCoordinate;
    data.yCoordinate = layer.yCoordinate;
    data.zCoordinate = layer.zCoordinate;
    data.backgroundColor = layer.bgColor;
    data.attributes = layer.attributes;
    data.elements = [];
    // Implement template elements as well
    for (let element of layer.elements) {
      data.elements.push(ProductElementBuilder.implement(element));
    }

    return this.populate(data).build();
  }

  /**
   * Get element from layer elements by ID
   *
   * @param {number} id Id of the element
   * @return {Element} Returns the found element
   */
  private getElementById(id: number): Element {
    let layer = this.Layer;
    if (layer.elements.length == 0) {
      return undefined;
    }
    for (let element of layer.elements) {
      if (element.id === id) {
        return element;
      }
    }
  }
}

import { Injectable } from '@angular/core';

import { CryptoService } from './../../services/crypto.service';
import { Configuration } from './../models/configuration';

@Injectable()
export class AuthService {

  private apiKey: string;
  private apiClientId: string;

  /**
   * Set environment globals in the constructor
   *
   * @param {CryptoService} cryptoService Crypting service class
   * @return {void}
   */
  constructor(private cryptoService: CryptoService) { }

  /**
   * Get API request Authorization token.
   * The token is a SHA254 encyrpted
   *
   * @param {string} url URL of the request
   * @param {string} clientApiKey API key for API Requests
   * @param {string} clientId Id for the client
   * @return {string} Returns the headers in an object
   */
  public getAuthToken(url:string, clientApiKey: string, clientId: string): string {
      let authorization: string;
      if (clientId !== null && clientApiKey !== null) {
        let token = this.cryptoService.hmac(url, clientApiKey);
        authorization = this.cryptoService.base64encode(clientId + ':' + token);
      }

      return authorization;
  }
}

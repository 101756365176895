import { Permission } from './permission.type';

export class FontSizePermission implements Permission
{
  permission: boolean;

  constructor(permission:any) {
    this.permission = permission;
  }

  /**
   * Can an action be exuted based on the permission
   *
   * @return {boolean}
   */
  public canChange(): boolean {
    return (this.getRule() !== 'locked');
  }

  /**
   * Get permission value
   *
   * @return {any} Returns the permission value
   */
  public Permission(): any {
    return this.permission;
  }

  /**
   * Returns the possible maximum font size for the resource text
   * undefined = unlimited
   *
   * @return number|undefined
   */
  public getMax(): number | undefined {
    return this.Permission().max;
  }

  /**
   * Returns the possible minimum font size for the resource
   * undefined = 1
   *
   * @return number
   */
  public getMin(): number {
    return (! this.Permission().min) ? 1 : this.Permission().min;
  }

  /**
   * Returns the permission rule
   *
   * @return {string} Returns the rule [open, consistent, locked]
   */
  public getRule(): string {
    return (this.Permission().rule || 'locked');
  }
}

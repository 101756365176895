import { ProductElement } from './../element/product-element.type';
import { ProductTextElementInterface } from './../element/product-text-element.type';
import { ProductTextElement } from './../element/product-text-element';
import { ProductEmailElementInterface } from './../element/product-email-element.type';
import { ProductEmailElement } from './../element/product-email-element';
import { ProductGroupElementInterface } from './../element/product-group-element.type';
import { ProductGroupElement } from './../element/product-group-element';
import { ProductImageElementInterface } from './../element/product-image-element.type';
import { ProductImageElement } from './../element/product-image-element';
import { Element } from './../../template/element/element.type';
import { TextElement } from './../../template/element/text-element';
import { EmailElement } from './../../template/element/email-element';
import { GroupElement } from './../../template/element/group-element';
import { ImageElement } from './../../template/element/image-element';

export class ProductElementBuilder {

  /**
   * Build product element from class data
   *
   * @param {any} data Element data
   * @return {ProductElement} Returns Product element object
   */
  static build(data: any): ProductElement {
    // Resolve correct build -method
    let dataType = data.elementType;
    if (! dataType) {
      throw new Error('Element type not set.');
    }
    let buildMethod =  'build' + dataType[0].toUpperCase() + dataType.slice(1);
    if (typeof (this as any)[buildMethod] === 'function') {
      return this[buildMethod](data);
    }
  }

  /**
   * Build product element from by implementing template element data
   *
   * @param {Element} element Element data
   * @return {ProductElement} Returns Product element object
   */
  static implement(element: Element): ProductElement {
     if (element === undefined) {
       return undefined;
     }
      let data:any = <any>{};
      data.element = element;
      data.key = element.key;
      data.elementType = element.elementType;
      data.width = element.width;
      data.height = element.height;
      data.attributes = element.attributes;

    // Resolve correct implement -method
    let dataType = data.elementType;
    let implementMethod =  'implement' + dataType[0].toUpperCase() + dataType.slice(1);
    if (typeof (this as any)[implementMethod] === 'function') {
      return this[implementMethod](data, element);
    }
  }

  /**
   * Build product text element from class data
   *
   * @param {any} data Element data
   * @return {ProductTextElementInterface} Returns Product text element object
   */
  static buildText(data: any): ProductTextElementInterface
  {
    return new ProductTextElement(
      data.element,
      data.id,
      data.key,
      data.width,
      data.height,
      data.content,
      data.elementType,
      data.attributes
    );
  }

  /**
   * Build product text element from by implementing template element data
   *
   * @param {Element} element Element data
   * @return {ProductTextElementInterface} Returns Product text element object
   */
  static implementText(data: any, element: Element): ProductTextElementInterface
  {
    if (element instanceof TextElement) {
      data.content = element.content;
    }
    return this.buildText(data);
  }

  /**
   * Build product email element from class data
   *
   * @param {any} data Element data
   * @return {ProductEmailElementInterface} Returns Product email element object
   */
  static buildEmail(data: any): ProductEmailElementInterface
  {
    return new ProductEmailElement(
      data.element,
      data.id,
      data.key,
      data.width,
      data.height,
      data.content,
      data.font,
      data.attributes
    );
  }

  /**
   * Build product email element from by implementing template element data
   *
   * @param {Element} element Element data
   * @return {ProductEmailElementInterface} Returns Product email element object
   */
  static implementEmail(data: any, element: Element): ProductEmailElementInterface
  {
    if (element instanceof EmailElement) {
      data.content = element.content;
      data.font = element.fonts[0];
    }
    return this.buildEmail(data);
  }

  /**
   * Build product group element from class data
   *
   * @param {any} data Element data
   * @return {ProductGroupElementInterface} Returns Product group element object
   */
  static buildGroup(data: any): ProductGroupElementInterface
  {
    let groupElements = [];
    for (let element of data.elements) {
      if ('context' in element && element.context === 'product') {
          groupElements.push(element);
      } else {
          element.element = this.getElementById(element.defaultElement, data.element);
          groupElements.push(this.build(element));
      }

    }
    return new ProductGroupElement(
      data.element,
      data.id,
      data.key,
      data.width,
      data.height,
      data.elementType,
      data.attributes,
      data.backgroundColor,
      groupElements
    );
  }

  /**
   * Build product group element from by implementing template element data
   *
   * @param {Element} element Element data
   * @return {ProductGroupElementInterface} Returns Product group element object
   */
  static implementGroup(data: any, element: Element): ProductGroupElementInterface
  {
    if (element instanceof GroupElement) {
      data.backgroundColor = element.bgColor;
      data.elements = element.elements;
    }
    let groupElements = [];
    for (let gElement of data.elements) {
      groupElements.push(this.implement(gElement));
    }
    data.elements = groupElements;

    return this.buildGroup(data);
  }

  /**
   * Build product image element from class data
   *
   * @param {any} data Element data
   * @return {ProductImageElementInterface} Returns Product image element object
   */
  static buildImage(data: any): ProductImageElementInterface
  {
    return new ProductImageElement(
      data.element,
      data.id,
      data.key,
      data.width,
      data.height,
      data.image,
      data.elementType,
      data.attributes,
      data.xPos,
      data.yPos,
      data.scaledX,
      data.scaledY
    );
  }

  /**
   * Build product image element from by implementing template element data
   *
   * @param {Element} element Element data
   * @return {ProductImageElementInterface} Returns Product image element object
   */
  static implementImage(data: any, element: Element): ProductImageElementInterface
  {
    if (element instanceof ImageElement) {
      data.image = element.image;
      data.xPos = element.xPos;
      data.yPos = element.yPos;
      data.scaledX = element.scaled;
      data.scaledY = element.scaled;
      data.width = element.CalcWidth();
      data.height = element.CalcHeight();
    }
    return this.buildImage(data);
  }

  /**
   * Get element from layer elements by ID
   *
   * @param {number} id Id of the element
   * @return {Element} Returns the found element
   */
  protected static getElementById(id: number, group: GroupElement): Element {
    if (group.elements.length == 0) {
      return undefined;
    }
    for (let element of group.elements) {
      if (element.id === id) {
        return element;
      }
    }
  }
}

import { AbstractValidator } from './abstract';
import { ContentOverflow } from './../content-overflow';

export class OverflowValidator extends AbstractValidator {

  overflow: ContentOverflow;

  /**
   * Setting needed values in the constructor for the validator
   *
   * @param {ContentOverflow} overflow ContentOverflow implementing class
   */
  constructor(overflow: ContentOverflow) {
    super();

    this.overflow = overflow;
  }

  /**
   * Run validation in the class
   *
   * @return {boolean} Returns boolean based on validness
   */
  run(): boolean {
    if (! this.overflow) {
      return true;
    }

    if (this.overflow.isContentOverflown()) {
      this.setWarning('content', 'VALIDATION.WARNING.OVERFLOWN', this.overflow.ElementName());
      return false;
    }

    return true;
  }
}

import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean> | Promise<boolean>;
}

@Injectable()
export class ConfirmDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {

  /**
   * Can user deactive current route
   *
   * @param {SmartdesignerAppComponent} target Active component
   * @return {boolean}
   */
  canDeactivate(component: ComponentCanDeactivate) : Observable<boolean>|Promise<boolean>|boolean {
    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate() ?
      true :
      // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
      // when navigating away from your angular app, the browser will show a generic warning message
      // see http://stackoverflow.com/a/42207299/7307355
      window.confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
  }
}

import { Layer } from './../template/layer';
import { Permittable } from './../common/permittable.type';
import { Layout } from './../template/layout';
import { ProductElement } from './element/product-element.type';
import { ProductLayerInterface } from './product-layer.type';
import { ProductLayerOut } from './product-layer-out.type';
import { ProductTextElementInterface } from './element/product-text-element.type';
import { Selectable } from './../common/selectable.type';
import { SelectionType } from './../common/selection-type';
import { Abstract } from './abstract';
import { ResizePermission } from './../common/resize-permission';
import { Color } from './../common/color.type';
import { Formable } from './../common/formable.type';
import { FormableType } from './../common/formable-type';
import { Validatable } from './../common/validatable.type';
import { AttributeInterpreter } from './../common/attribute-interpreter';

export class ProductLayer extends Abstract implements ProductLayerInterface {

  // Selectable options
  public selectionType: SelectionType = SelectionType.layer;
  public parentTier: Selectable;
  public childSelectables: Array<Selectable>;

  constructor(
    public layer: Layer,
    public id: number,
    public key: string,
    public width: number,
    public height: number,
    public xCoordinate: number,
    public yCoordinate: number,
    public zCoordinate: number,
    public bgColor: Color,
    public elements: Array<ProductElement> = [],
    public creationDate?: number,
    public modificationDate?: number,
    public published?: boolean,
    attributes?: any
  ) {
    super(layer, key, attributes);
    this.setChildrenParentTier();
  }

  /**
   * Set product layer elements
   *
   * @param {Array<ProductElement>} elements Elements in an array
   * @return void
   */
  setElements(elements: Array<ProductElement>): void {
    this.elements = elements;
    this.setChildrenParentTier();
  }

  /**
   * Set product layer element
   *
   * @param {ProductElement} element Element
   * @return void
   */
  setElement(element: ProductElement): void {
    this.elements.push(element);
  }

  /**
   * Get product layer elements
   *
   * @return Array<ProductElement> Returns product layer elements
   */
  getElements(): Array<ProductElement> {
    return this.elements;
  }

  /**
   * Returns children of the current validatable
   *
   * @return {Array<Validatable>}
   */
  public Children(): Array<Validatable> {
    return this.getElements();
  }

  /**
   * Returns the plan (template counter-part) of this implementation
   *
   * @return {Permittable}
   */
  public Plan(): Permittable {
    return this.layer;
  }

  /**
   * Get element form identifier from the default element
   *
   * @return {string} Returns the form identifier if set
   */
  public FormId() {
    return undefined;
  }

  /**
   * Find a formable from product structure by its type and key
   *
   * @param {string} key Formable key
   * @param {FormableType} type Formable type
   * @return {Formable} Return the fetched formable
   */
  findFormableByKey(key: string, type: FormableType): Formable {
      for (let element of this.getElements()) {
          if (element.elementType === 'group') {
            let item = element.findFormableByKey(key, type);
            if (item !== undefined) {
              return item;
            }
          } else if (element.key == key && element.getFormableType() == type) {
            return element;
          }
      }

      return undefined;
  }

  setChildrenParentTier() {
    this.childSelectables = this.elements;
    for (let child of this.childSelectables) {
      child.parentTier = this;
    }
  }

  /**
   * Get product layer data.
   * This data is used for saving this object to the API.
   *
   * @return {ProductLayerOut} Returns the product layer data for the API
   */
  getData(): ProductLayerOut {
    let data:ProductLayerOut = <any>{};
    data.id = this.id;
    data.key = this.key;
    data.defaultLayer = (this.layer !== undefined) ? this.layer.id : undefined;
    data.width = this.width;
    data.height = this.height;
    data.xCoordinate = this.xCoordinate;
    data.yCoordinate = this.yCoordinate;
    data.zCoordinate = this.zCoordinate;
    data.backgroundColor = this.bgColor;
    data.attributes = this.attributes;
    if (this.elements.length > 0) {
      data.elements = [];
      for (let element of this.elements) {
        let elementData = element.getData();
        data.elements.push(elementData);
      }
    }

    return data;
  }

  /**
   * Update is called on form changes
   *
   * @param {any} data Data
   * @return {void}
   */
  update(data: any) {
    // Call on validators
    this.validate();
  }

  /**
   * Set element height safely.
   * Respects the restrictions set to the element.
   *
   * @param {number} value New height
   * @return {void}
   */
  public setHeight(value: number): void {
    if (! this.canResize('vertical')) {
      return ;
    }
    this.height = this.parseValidHeight(value);
  }

  /**
   * Set element width safely.
   * Respects the restrictions set to the element.
   *
   * @param {number} value New width
   * @return {void}
   */
  public setWidth(value: number): void {
    if (! this.canResize('horizontal')) {
      return ;
    }
    this.width = this.parseValidWidth(value);
  }

  /**
   * Can current user resize the layer
   *
   * @param {string} direction Optional parameter for resize direction [horizontal, vertical]
   * @return boolean
   */
  canResize(direction?: string): boolean {
    if (this.layer === undefined) {
      return true;
    }
    let permission = <ResizePermission>this.layer.Permission('resize');
    return permission.canResize(direction);
  }

  /**
   * Returns the possible maximum width for the layer
   * undefined = unlimited
   *
   * @return number|undefined
   */
  getMaxWidth(): number | undefined {
    if (! this.canResize('horizontal')) {
      return this.width;
    }

    let permission = <ResizePermission>this.layer.Permission('resize');
    return permission.getMaxWidth();
  }

  /**
   * Returns the possible maximum height for the layer
   * undefined = unlimited
   *
   * @return number|undefined
   */
  getMaxHeight(): number | undefined {
    if (! this.canResize('vertical')) {
      return this.height;
    }

    let permission = <ResizePermission>this.layer.Permission('resize');
    return permission.getMaxHeight();
  }

  /**
   * Returns the possible minimum width for the layer
   * undefined = unlimited
   *
   * @return number|undefined
   */
  getMinWidth(): number | undefined {
    if (! this.canResize('horizontal')) {
      return this.width;
    }

    let permission = <ResizePermission>this.layer.Permission('resize');
    return permission.getMinWidth();
  }

  /**
   * Returns the possible minimum height for the layer
   * undefined = unlimited
   *
   * @return number|undefined
   */
  getMinHeight(): number | undefined {
    if (! this.canResize('vertical')) {
      return this.height;
    }

    let permission = <ResizePermission>this.layer.Permission('resize');
    return permission.getMinHeight();
  }

  /**
   * Returns the unit for notch in height
   * undefined = dynamic
   *
   * @return number|undefined
   */
  getNotchHeight(): number | undefined {
    if (! this.canResize('vertical')) {
      return undefined;
    }

    let permission = <ResizePermission>this.layer.Permission('resize');
    return permission.getNotchHeight();
  }

  /**
   * Returns the unit for notch in width
   * undefined = dynamic
   *
   * @return number|undefined
   */
  getNotchWidth(): number | undefined {
    if (! this.canResize('horizontal')) {
      return undefined;
    }

    let permission = <ResizePermission>this.layer.Permission('resize');
    return permission.getNotchWidth();
  }

  /**
   * Parse the given value valid for height of the layer
   *
   * @param {number} value Height value
   * @return {number} Returns a valid value
   */
  parseValidHeight(value:number): number {
    if (! value) {
      return this.height;
    }
    if (this.getNotchHeight() && this.getNotchHeight() > 0) {
      value = Math.ceil(value / this.getNotchHeight()) * this.getNotchHeight();
    }
    if (this.getMaxHeight() && value > this.getMaxHeight()) {
      return this.getMaxHeight();
    }
    if (this.getMinHeight() && value < this.getMinHeight()) {
      return this.getMinHeight();
    }

    return value;
  }

  /**
   * Parse the given value valid for width of the layer
   *
   * @param {number} value Width value
   * @return {number} Return a valid value
   */
  parseValidWidth(value:number): number {
    if (! value) {
      return this.width;
    }
    if (this.getNotchWidth() && this.getNotchWidth() > 0) {
      value = Math.ceil(value / this.getNotchWidth()) * this.getNotchWidth();
    }
    if (this.getMaxWidth() && value > this.getMaxWidth()) {
      return this.getMaxWidth();
    }
    if (this.getMinWidth() && value < this.getMinWidth()) {
      return this.getMinWidth();
    }

    return value;
  }

  /**
   * Returns justify content value from element attributes if set
   *
   * @return {string} Returns justify content value from element attributes if set else 'initial'
   */
  justifyContent(): string {
    let interpreter = new AttributeInterpreter();

    return interpreter.justifyContent(this.attributes);
  }

  /**
   * Returns the form item type
   *
   * @return {FormableType} Returns form item type
   */
  getFormableType(): FormableType {
    return FormableType.layer;
  }
}
